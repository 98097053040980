import { ReportsService } from '../../services/modules/reports.service';
import { CubeService } from '../../services/modules/cube.service';
import * as reportTemplates from '@/components/editor/reports/templates';
import moment from 'moment';
import { normalizeOfficeStatus, separateByMonth } from '@/components/editor/reports/helpers';
import { useCubeQuery } from '@cubejs-client/vue3';
import cubejs from '@cubejs-client/core';
import result from '@/mock-api/RegionalTitles';

const defaultState = {
  notes: {},
  loadingNotes: false,
};

export default {
  namespaced: true,
  state: defaultState,

  mutations: {
    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },

    setNotes(state, data) {
      state.notes = { ...state.notes, ...data };
    },

    setLoadingNotes(state, loading) {
      state.loadingNotes = loading;
    },

    resetState(state) {
      state = { ...state, ...defaultState };
    },
  },

  actions: {
    async getReportList({ commit, state }, { queryString }) {
      try {
        const res = await ReportsService.getReportList(queryString);
        commit('setState', { reportList: res.data.result });
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getReportListTemplates(_, id) {
      try {
        const res = await ReportsService.getReportListTemplates(id);
        return res.data.result.reportTemplates;
      } catch (e) {
        throw e;
      }
    },

    async getRecurring(_, query) {
      try {
        const res = await ReportsService.getRecurring(query.query.reportId, query.query.template);
        return res.data.data;
      } catch (e) {
        throw e;
      }
    },
    async getAgentReportDataForAllRegions(_, obj) {
      try {
        const res = await ReportsService.getAgentReportDataForAllRegions(
          obj.pageNumber,
          obj.rows,
          obj.regionId,
          obj.status,
          obj.search
        );
        return res.data.data;
      } catch (e) {
        throw e;
      }
    },

    async getMonthlyAgentProduction(_, query) {
      try {
        const res = await ReportsService.getMonthlyAgentProduction(query.query.regionId, query.query.endDate);
        return res.data.data;
      } catch (e) {
        throw e;
      }
    },

    async deleteReport(_, id) {
      try {
        const res = await ReportsService.deleteReport(id);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async saveReport(_, { payload }) {
      try {
        let reportFilter;
        let validFromJsonKey;
        let validToJsonKey;
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (
          payload.reportTemplateID === 16 ||
          payload.reportTemplateID === 13 ||
          payload.reportTemplateID === 24 ||
          payload.reportTemplateID === 11 ||
          payload.reportTemplateID === 20 ||
          payload.reportTemplateID === 18
        ) {
          validFromJsonKey = 'flt_date_start';
          validToJsonKey = 'flt_date_end';
        } else {
          validFromJsonKey = 'flt_monthdate_start';
          validToJsonKey = 'flt_monthdate_end';
        }
        if (payload.reportTemplateKey === 'monthly_agent_production') {
          reportFilter = JSON.stringify({
            filter_placeholder: 'flt',
            flt_region: payload.regionID,
            flt_status: payload.status,
            [validToJsonKey]: payload.validTo,
            show_columns: [],
          });
        } else {
          reportFilter = JSON.stringify({
            filter_placeholder: 'flt',
            flt_region: payload.regionID,
            flt_status: payload.status,
            [validFromJsonKey]: payload.validFrom,
            [validToJsonKey]: payload.validTo,
            show_columns: [],
          });
        }
        let filter = JSON.parse(reportFilter);
        if (payload.currency) {
          Object.defineProperty(filter, 'flt_currency', {
            value: payload.currency,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (payload.showColumns) {
          Object.defineProperty(filter, 'show_columns', {
            value: payload.showColumns,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (payload.periodFlag) {
          Object.defineProperty(filter, 'flt_period_switch', {
            value: payload.periodFlag,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        let jsondFilter = JSON.stringify(filter);
        const saveModel = {
          useDeltaFieldMapping: false,
          title: payload.title,
          personName: userInfo.firstName + ' ' + userInfo.lastName,
          personID: userInfo.personID,
          createdAt: new Date(moment(new Date()).format('YYYY-MM-DD')).getTime(),
          modifiedAt: null,
          reportTemplateID: payload.reportTemplateID,
          reportTemplateKey: payload.reportTemplateKey,
          reportTemplateTitle: payload.reportTemplateTitle,
          reportFilter: jsondFilter,
          isPublic: payload.isPublic ? 1 : 0,
        };

        const res = await ReportsService.saveReport(saveModel);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async updateReport(_, { id, payload }) {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const key = payload.reportTemplateKey;
        let validFromJsonKey;
        let validToJsonKey;
        if (
          payload.reportTemplateID === 16 ||
          payload.reportTemplateID === 13 ||
          payload.reportTemplateID === 20 ||
          payload.reportTemplateID === 18 ||
          payload.reportTemplateID === 24 ||
          payload.reportTemplateID === 11
        ) {
          validFromJsonKey = 'flt_date_start';
          validToJsonKey = 'flt_date_end';
        } else {
          validFromJsonKey = 'flt_monthdate_start';
          validToJsonKey = 'flt_monthdate_end';
        }
        let reportFilter = {
          filter_placeholder: 'flt',
          show_columns: [],
        };
        if (payload.regionID > 0) {
          Object.defineProperty(reportFilter, 'flt_region', {
            value: payload.regionID,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (Array.isArray(payload.regionID)) {
          Object.defineProperty(reportFilter, 'flt_region', {
            value: payload.regionID,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (payload.status) {
          Object.defineProperty(reportFilter, 'flt_status', {
            value: payload.status,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (payload.showColumns) {
          Object.defineProperty(reportFilter, 'show_columns', {
            value: payload.showColumns,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (payload.currency) {
          Object.defineProperty(reportFilter, 'flt_currency', {
            value: payload.currency,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (payload.validFrom) {
          payload.dateFrom = payload.validFrom;
        }
        if (payload.validTo) {
          payload.dateTo = payload.validTo;
        }
        if (payload.dateFrom) {
          Object.defineProperty(reportFilter, validFromJsonKey, {
            value: payload.dateFrom,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (payload.dateTo) {
          Object.defineProperty(reportFilter, validToJsonKey, {
            value: payload.dateTo,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (payload.periodFlag) {
          Object.defineProperty(reportFilter, 'flt_period_switch', {
            value: payload.periodFlag,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        const saveModel = {
          useDeltaFieldMapping: false,
          title: payload.title,
          personName: userInfo.firstName + ' ' + userInfo.lastName,
          personID: userInfo.personID,
          createdAt: payload.createdAt,
          modifiedAt: null,
          reportTemplateID: payload.reportTemplateID,
          reportTemplateKey: payload.reportTemplateKey,
          reportTemplateTitle: payload.reportTemplateTitle,
          reportFilter: JSON.stringify(reportFilter),
          isPublic: payload.isPublic ? 1 : 0,
        };
        const res = await ReportsService.updateReport(id, saveModel);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getReportData(context, { cubeTemplate, template, cubeParamsIdentifier }) {
      try {
        let responseObject: any = {
          totalRows: null,
        };
        let regionID;
        let filters = [];
        let countFilters = [];
        let query;
        let granularity;
        let officeID;
        let officeName;
        let status = cubeTemplate.status;
        let dimensionsObject = cubeTemplate.dimensions;
        let isPeriodicalFlag = cubeTemplate.isPeriodical;
        const currency = cubeTemplate.currency;
        if (cubeTemplate.office) officeID = cubeTemplate.office;
        if (cubeTemplate.officeName) officeName = cubeTemplate.officeName;
        if (cubeTemplate.periodFlag) granularity = cubeTemplate.periodFlag;
        if (template[cubeParamsIdentifier].filters) {
          for (let i = 0; i < template[cubeParamsIdentifier].filters.length; i++) {
            filters.push(template[cubeParamsIdentifier].filters[i]);
            if (template[cubeParamsIdentifier].filters[i].member === 'PerformanceCube.officeid') {
              filters[i].values = [officeID];
            }
            if (template[cubeParamsIdentifier].filters[i].member === 'personCube.primaryofficename') {
              filters[i].values = [officeName];
            }
          }
        }
        if (cubeTemplate.regionID) {
          regionID = cubeTemplate.regionID;
          filters.push({
            member: `${template[cubeParamsIdentifier].mainCubeParameter}.regionid`,
            operator: 'equals',
            values: Array.isArray(regionID) ? regionID : [regionID],
          });
        }

        if (cubeTemplate.regionID) {
          regionID = cubeTemplate.regionID;
          countFilters.push({
            member: `personCube.regionid`,
            operator: 'equals',
            values: Array.isArray(regionID) ? regionID : [regionID],
          });
        }

        if (status) {
          if (template[cubeParamsIdentifier].filterParameter === 'OfficeCube.statusoffice') {
            status = normalizeOfficeStatus(status);
          }
          filters.push({
            member: template[cubeParamsIdentifier].statusKey
              ? template[cubeParamsIdentifier].statusKey
              : `${template[cubeParamsIdentifier].mainCubeParameter}.status`,
            operator: 'equals',
            values: Array.isArray(status) ? status : [status],
          });
        }
        query = {
          measures: template[cubeParamsIdentifier].measures,
          order: template[cubeParamsIdentifier].order,
          dimensions: template[cubeParamsIdentifier].dimensions,
          filters: filters,
        };
        if (template[cubeParamsIdentifier].timeDimensions) {
          const timeDimension = template[cubeParamsIdentifier].timeDimensions;
          //add yearly/monthly as granularity
          Object.defineProperty(query, 'timeDimensions', {
            value: timeDimension,
            writable: true,
            enumerable: true,
            configurable: true,
          });
          if (isPeriodicalFlag) {
            Object.defineProperty(query.timeDimensions[0], 'granularity', {
              value: granularity === 2 ? 'year' : 'month',
              writable: true,
              enumerable: true,
              configurable: true,
            });
          }
        }
        let totalRows = 0;
        if (template.cubeParameters.isLargeDataSet) {
          if (cubeTemplate.export) {
            while (!responseObject.data) {
              try {
                const resultSet = await CubeService.getReportData(query);
                responseObject.data = resultSet;
              } catch (_) {
                //
              }
            }
            return responseObject;
          }
          let countKey;
          if (template[cubeParamsIdentifier].countParameter) {
            countKey = template[cubeParamsIdentifier].countParameter;
          } else {
            countKey = `${template[cubeParamsIdentifier].mainCubeParameter}.count`;
          }

          if (totalRows > 10000) {
            responseObject.totalRows = totalRows;
          }
        }
        if (totalRows >= 10000 && cubeTemplate.limit) {
          Object.defineProperty(query, 'limit', {
            value: cubeTemplate.limit,
            writable: true,
            enumerable: true,
            configurable: true,
          });
          if (cubeTemplate.offset) {
            Object.defineProperty(query, 'offset', {
              value: cubeTemplate.offset,
              writable: true,
              enumerable: true,
              configurable: true,
            });
          }
        }
        if (cubeTemplate.validTo) {
          let timeDimension;
          if (template[cubeParamsIdentifier].singleDate) {
            let currentDate = moment(cubeTemplate.validTo).date(1).startOf('day');
            timeDimension = [
              {
                dimension: template[cubeParamsIdentifier].timeParameter
                  ? template[cubeParamsIdentifier].timeParameter
                  : `${template[cubeParamsIdentifier].mainCubeParameter}.transactiondate`,
                dateRange: [currentDate.format('YYYY-MM-DD'), cubeTemplate.validTo],
                granularity: 'month',
              },
            ];
          } else {
            timeDimension = [
              {
                dimension: template[cubeParamsIdentifier].timeParameter
                  ? template[cubeParamsIdentifier].timeParameter
                  : `${template[cubeParamsIdentifier].mainCubeParameter}.transactiondate`,
                dateRange: [cubeTemplate.validFrom, cubeTemplate.validTo],
              },
            ];
          }

          Object.defineProperty(query, 'timeDimensions', {
            value: timeDimension,
            writable: true,
            enumerable: true,
            configurable: true,
          });
          if (template.pivot) {
            Object.defineProperty(query.timeDimensions[0], 'granularity', {
              value: granularity === 2 ? 'year' : 'month',
              writable: true,
              enumerable: true,
              configurable: true,
            });
          }
        }
        //get data from cubes here
        //const resultSet: any = await cubejsApi.load(query);
        //swopping out the original cubejsApi call to the one via the backend
        let cubeResult = await CubeService.executeReportsQuery(query);

        if (currency) {
          let rate;
          let volume;
          let multiplier;
          let multiplierOption1;
          let multiplierOption2;
          if (currency === 'dollar') {
            rate = 'gciForPeriodRxd';
            volume = 'volumeForPeriodRxd';
            multiplierOption1 = 'raterxd';
            multiplierOption2 = 'avgRateRxd';
          }
          if (currency === 'euro') {
            rate = 'gciForPeriodEur';
            volume = 'volumeForPeriodEur';
            multiplierOption1 = 'rateeur';
            multiplierOption2 = 'avgRateEur';
          }
          if (currency === 'local') {
            rate = 'gciForPeriod';
            volume = 'volumeForPeriod';
            multiplierOption1 = 1;
            multiplierOption2 = 1;
          }
          if (cubeParamsIdentifier === 'titleLevelParameters' && cubeParamsIdentifier !== 'alternativeCubeParameters') {
            cubeResult = cubeResult.map((obj) => {
              if (
                !obj[`${template[cubeParamsIdentifier].mainCubeParameter}.gciForPeriod`] ||
                !obj[`${template[cubeParamsIdentifier].mainCubeParameter}.volumeForPeriod`]
              )
                return { ...obj };
              if (obj[`${template[cubeParamsIdentifier].mainCubeParameter}.${multiplierOption1}`]) {
                multiplier = multiplierOption1;
              } else {
                multiplier = multiplierOption2;
              }
              return {
                ...obj,
                [`${template[cubeParamsIdentifier].mainCubeParameter}.gciForPeriod`]:
                  obj[`${template[cubeParamsIdentifier].mainCubeParameter}.gciForPeriod`] *
                  (obj[`${template[cubeParamsIdentifier].mainCubeParameter}.${multiplier}`] || 1),
                [`${template[cubeParamsIdentifier].mainCubeParameter}.volumeForPeriod`]:
                  obj[`${template[cubeParamsIdentifier].mainCubeParameter}.volumeForPeriod`] *
                  (obj[`${template[cubeParamsIdentifier].mainCubeParameter}.${multiplier}`] || 1),
              };
            });
          }

          if (cubeParamsIdentifier !== 'alternativeCubeParameters' && cubeParamsIdentifier !== 'titleLevelParameters') {
            //resultSet.loadResponse.results[0].data = resultSet.loadResponse.results[0].data.map((obj) => {
            cubeResult = cubeResult.map((obj) => {
              if (
                !obj[`${template[cubeParamsIdentifier].mainCubeParameter}.gciForPeriod`] ||
                !obj[`${template[cubeParamsIdentifier].mainCubeParameter}.volumeForPeriod`]
              )
                return { ...obj };
              return {
                ...obj,
                [`${template[cubeParamsIdentifier].mainCubeParameter}.gciForPeriod`]:
                  obj[`${template[cubeParamsIdentifier].mainCubeParameter}.${rate}`],
                [`${template[cubeParamsIdentifier].mainCubeParameter}.volumeForPeriod`]:
                  obj[`${template[cubeParamsIdentifier].mainCubeParameter}.${volume}`],
              };
            });
          }
        }
        // if (template.pivot) {
        //   const pivotRes = resultSet.tablePivot(template.pivot);
        //   pivotRes.forEach((element) => {});
        //   const sortedPivot = separateByMonth(pivotRes[0]);
        //   console.log(sortedPivot);
        // }

        //responseObject.data = resultSet.loadResponse.results[0].data;
        responseObject.data = cubeResult;
        return responseObject;
      } catch (e) {
        throw e;
      }
    },

    async generateReport(context, { cubeTemplate, template, cubeParamsIdentifier }) {
      try {
        //console.log('generating report, called when generating a new template');
        let res;
        let regionID;
        let query;
        let timeFrame;
        let granularity;
        let officeID;
        let officeName;
        let filters = [];
        let isPeriodicalFlag = cubeTemplate.isPeriodical;
        if (cubeTemplate.period) granularity = cubeTemplate.period;
        if (cubeTemplate.office) officeID = cubeTemplate.office;
        if (cubeTemplate.officeName) officeName = cubeTemplate.officeName;
        if (cubeTemplate.dateFrom) timeFrame = [cubeTemplate.dateFrom, cubeTemplate.dateTo];
        if (cubeTemplate.dateFrom) timeFrame = [cubeTemplate.dateFrom, cubeTemplate.dateTo];
        let status = cubeTemplate.status;
        const currency = cubeTemplate.currency;
        if (template[cubeParamsIdentifier].filters) {
          for (let i = 0; i < template[cubeParamsIdentifier].filters.length; i++) {
            filters.push(template[cubeParamsIdentifier].filters[i]);
            if (template[cubeParamsIdentifier].filters[i].member === 'PerformanceCube.officeid') {
              filters[i].values = [officeID];
            }
            if (template[cubeParamsIdentifier].filters[i].member === 'personCube.primaryofficename') {
              filters[i].values = [officeName];
            }
          }
        }
        if (cubeTemplate.regionID !== -2 && cubeTemplate.regionID !== '-2') {
          if (cubeTemplate.regionID) {
            filters.push({
              member: `${template[cubeParamsIdentifier].mainCubeParameter}.regionid`,
              operator: 'equals',
              values: Array.isArray(cubeTemplate.regionID) ? cubeTemplate.regionID : [cubeTemplate.regionID],
            });
          }
        }

        if (status !== null && status !== undefined) {
          if (template[cubeParamsIdentifier].filterParameter === 'OfficeCube.statusoffice') {
            status = normalizeOfficeStatus(status);
          }
          filters.push({
            member: template[cubeParamsIdentifier].filterParameter,
            operator: 'equals',
            values: Array.isArray(status) ? status : [status],
          });
        }

        query = {
          measures: template[cubeParamsIdentifier].measures,
          order: template[cubeParamsIdentifier].order,
          dimensions: template[cubeParamsIdentifier].dimensions,
          filters: filters,
        };

        if (timeFrame) {
          const timeDimension = [
            {
              dimension: template[cubeParamsIdentifier].timeParameter
                ? template[cubeParamsIdentifier].timeParameter
                : `${template[cubeParamsIdentifier].mainCubeParameter}.transactiondate`,
              dateRange: timeFrame,
              // granularity: granularity,
            },
          ];

          Object.defineProperty(query, 'timeDimensions', {
            value: timeDimension,
            writable: true,
            enumerable: true,
            configurable: true,
          });
          if (isPeriodicalFlag) {
            Object.defineProperty(query.timeDimensions[0], 'granularity', {
              value: granularity,
              writable: true,
              enumerable: true,
              configurable: true,
            });
          }
        }
        //swopping over from using the cube api to the cube service
        //const cubejsApi = cubejs(context.rootState.auth.cubeToken, { apiUrl: API_URL });
        //const resultSet: any = await cubejsApi.load(query);
        let cubeResult = await CubeService.executeReportsQuery(query);
        if (currency) {
          let rate;
          let volume;
          if (currency === 'dollar') {
            rate = 'gciForPeriodRxd';
            volume = 'volumeForPeriodRxd';
          }
          if (currency === 'euro') {
            rate = 'gciForPeriodEur';
            volume = 'volumeForPeriodEur';
          }
          if (currency === 'local') {
            rate = 'gciForPeriod';
            volume = 'volumeForPeriod';
          }
          if (cubeParamsIdentifier !== 'alternativeCubeParameters') {
            cubeResult = cubeResult.map((obj) => {
              if (
                !obj[`${template[cubeParamsIdentifier].mainCubeParameter}.gciForPeriod`] ||
                !obj[`${template[cubeParamsIdentifier].mainCubeParameter}.volumeForPeriod`]
              )
                return { ...obj };
              return {
                ...obj,
                [`${template[cubeParamsIdentifier].mainCubeParameter}.gciForPeriod`]:
                  obj[`${template[cubeParamsIdentifier].mainCubeParameter}.${rate}`],
                [`${template[cubeParamsIdentifier].mainCubeParameter}.volumeForPeriod`]:
                  obj[`${template[cubeParamsIdentifier].mainCubeParameter}.${volume}`],
              };
            });
          }
        }
        return cubeResult;
      } catch (e) {
        console.log('error in generateReport: ', e.message);
        throw e;
      }
    },

    async selectReport({ commit }, ID) {
      try {
        const res = await ReportsService.selectReport(ID);
        return res;
      } catch (e) {
        throw e;
      }
    },
    async selectReportTemplate({ commit }, ID) {
      try {
        const res = await ReportsService.selectReportTemplate(ID);
        return res;
      } catch (e) {
        throw e;
      }
    },
  },
};


function isPeriodicalReport(reportTemplateID) {
  if (
    reportTemplateID === 16 ||
    reportTemplateID === 20 ||
    reportTemplateID === 11 ||
    reportTemplateID === 18 ||
    reportTemplateID === 24
  ) {
    return true;
  } else {
    return false;
  }
}