import { getDefaultState } from './constants';
import { BaseService } from '../../../services/base.service';
import { init, personAwards, awardsHistory, } from './init';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },

		resetPersonAwardsFilters(state) {
			const list = state.personAwards.list;
			state.personAwards = {
				...getDefaultState().personAwards,
				list,
			};
		},
	},

	actions: {
		init(store, params) { return init(store, params); },

		personAwards(store, params) { return personAwards(store, params); },

		resetPersonAwardsFilters(store) { 
			store.commit('resetPersonAwardsFilters');
			return personAwards(store, getDefaultState().personAwards); 
		},

		awardsHistory(store, params) { return awardsHistory(store, params); },
	},
};
  