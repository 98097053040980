import { AnnualDuesPaymentEditorService } from '../../../services/modules/annualDuesPayment.service';
import { init, getPayment} from './init';
import { getDefaultState } from './constants';

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    setPayment(state, data) {
      state.payment = data;
    },
  },
  getters: {
    getPayment: (state) => state.payment,
  },
  actions: {
    async getPayment(store, paymentID) {
      return getPayment(store, paymentID || store.state.payment?.ID);
    },

    init(store, paymentID) {
      return init(store, paymentID);
    },

    async save(store, { payment, }) {
      console.log('saving in store/annualduespaymenteditor/index.ts...');
      console.log(payment);
      //if the paymentID is defined, that means that we are updating an existing payment
      const paymentID = store.state.payment?.ID;
      //get the paymentID, if this is a new payment, the ID will be null/undefined
      try {
        let savedPaymentResponse;
        

        if(paymentID)
          savedPaymentResponse = await AnnualDuesPaymentEditorService.updatePayment(paymentID, payment);
        else
          savedPaymentResponse = await AnnualDuesPaymentEditorService.insertPayment(payment);

        console.log(savedPaymentResponse);
        return { savedPaymentResponse };
      } catch (e) {
        throw e;
      }
    },
  }
};
