import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const agents: any = {
  cubeParameters: {
    dimensions: [
      'personCube.primaryofficeid',
      'personCube.firstname',
      'personCube.lastname',
      'personCube.CurrentTeamStatus',
      'personCube.region',
      'personCube.subregionname',
      'personCube.idllcperson',
      'personCube.idperson',
      'personCube.uniquepersonid',
      'personCube.primarytitle',
      'personCube.gender',
      'personCube.birthdate',
      'personCube.socialsecuritynumber',
      'personCube.highestcareeraward',
      'personCube.highestclubaward',
      'personCube.fulllocalname',
      'personCube.suffixname',
      'personCube.display_title',
      'personCube.externalpersonid',
      'personCube.officeexternalid',
      'personCube.terminationreason',
      'personCube.tenuredays',
      'personCube.mainemailaddress',
      'personCube.mainphonenumber',
      'personCube.awardsrankingsexcluded',
      'personCube.reportingcurrencyname',
      'personCube.marketingname',
      'personCube.title',
      'personCube.remaxpersonid',
      'personCube.officeconstituentid',
      'personCube.officelegacyid',
      'personCube.addate',
      'personCube.totaladdress',
      'OfficeCube.externalOfficeId',
      'OfficeCube.officeid',
      'personCube.remaxcommencedate',
      'personCube.TeamName',
      'personCube.teamstatusdate',
      'personCube.primaryofficename',
      'personCube.regionid',
      'personCube.isactive',
      'personCube.reportingcurrencyiso',
      'personCube.internalpersonid',
      'personCube.primarytitlevalidfrom',
      'personCube.primarytitlevalidto',
      'personCube.specializationname',
      'personCube.officeUniqueid',
    ],
    timeDimensions: [
      {
        dimension: 'personCube.remaxcommencedate',
        dateRange: ['1999-01-01', '2023-06-13'],
      },
    ],
    order: [['personCube.idperson', 'asc']],
    mainCubeParameter: 'personCube',
    statusKey: 'personCube.isactive',
    isLargeDataSet: true,
  },

  columns: [
    TC.region,
    TC.subRegion,
    TC.firstName,
    TC.lastName,
    TC.uniqueID,
    TC.statusAgents,
    TC.primaryOfficeName,
    TC.officeUniqueIDByPerson,
    TC.SatelliteOffice,
    TC.primaryTitle,
    TC.primaryValidFrom,
    TC.primaryValidTo,
    TC.terminationReason,
    TC.remaxCommenceDate,
    TC.addDate,
    TC.tenureDays,
    TC.teamStatusAgents,
    TC.teamName,
    TC.teamID,
    TC.teamLeaderName,
    TC.teamStatusDate,
    TC.specialization,
    TC.genderAgent,
    TC.birthdate,
    TC.email,
    TC.socialSecurityNumber,
    TC.address,
    TC.phone,
    TC.rankingsAwardsExcluded,
    TC.highestCareerAward,
    TC.highestClubAward,
    TC.careerGCIAgents,
    TC.gciYearly,
    TC.reportingCurrencyISO,
    TC.officesOwnedByName,
    TC.officesOwnedByUniqueID,
    TC.fullLocalName,
    TC.displayName,
    TC.academicTitle,
    TC.suffixName,
    TC.displayTitle,
    TC.legacyMemberID,
    TC.idllPerson,
    TC.externalPersonID,
    TC.internalPersonID,
    TC.officeConstituentIDCombinedReport,
    TC.agentsOfficeLegacyID,
    TC.officeExternalID,
  ],
  settingsPanel: panel,
};
