import { PersonService } from '@/services/modules/person.service';
import { RegionService } from '@/services/modules/region.service';

import * as mockData from '../mock-data/person';

export default {
  namespaced: true,
  state: {
    listOfPersons: {},
    persons: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
    },
    rolesDropdown: [],
    regionsDropdown: [],
    editCurrentPerson: {},
    loadingEditCurrentPerson: false,
    personSuffixes: [],
    emailTags: [],
    specialisationsSelect: [],
    designationsDropdown: [],
    licenseNumbers: [],
    personDesignations: [],
    contactInformationEntries: [],
    notes: [],
    history: [],
    offices: [],
    performanceData: [],
    titles: [],
    terminationReasons: [],
    teams: [],
    systemAccessRelations: [],
  },

  getters: {
    getlistOfPersons: (state) => state.listOfPersons,
    getPersonsList: (state) => state.persons.list,
    getHistory: (state) => state.history,
    getCurrentRegion: (state) => state.currentRegion,
    getRegionalPersonList: (state) => state.regionalPersonList,
  },

  mutations: {
    setState(state, partialState) {
      const _state = { 
        ...state, 
        ...(typeof partialState === 'function' ? partialState({ ...JSON.parse(JSON.stringify(state)) }) : partialState) 
      };
      Object.keys(_state).forEach(key => state[key] = _state[key]);
    },

    setPersons(state, data) {
      state.persons = { ...state.persons, ...data, };
    },

    setRolesDropdown(state, data) {
      state.rolesDropdown = data;
    },

    setRegionsDropdown(state, data) {
      state.regionsDropdown = data;
    },

    SET_PERSON_LIST_BY_SEARCH(state, persons) {
      state.listOfPersons = persons;
    },

    SET_PERSON_LIST(state, persons) {
      state.listOfPersons = persons;
    },
    SET_HISTORY(state, history) {
      state.history = history;
    },

    setEditCurrentPerson(state, data) {
      state.editCurrentPerson = data;
    },

    setLoadingEditCurrentPerson(state, loading) {
      state.loadingEditCurrentPerson = loading;
    },
    setPersonSuffixes(state, data) {
      state.personSuffixes = data;
    },

    setEmailTags(state, data) {
      state.emailTags = data;
    },

    setSpecialisationsSelect(state, data) {
      state.specialisationsSelect = data;
    },

    setDesignationsDropdown(state, data) {
      state.designationsDropdown = data;
    },

    setLicenseNumbers(state, data) {
      state.licenseNumbers = data;
    },

    setPersonDesignations(state, data) {
      state.personDesignations = data;
    },

    setContactInformationEntries(state, data) {
      state.contactInformationEntries = data;
    },

    setNotes(state, data) {
      state.notes = data;
    },

    setHistory(state, data) {
      state.history = data;
    },

    setOffices(state, data) {
      state.offices = data;
    },

    setPerformanceData(state, data) {
      state.performanceData = data;
    },

    setTitles(state, data) {
      state.titles = data;
    },

    setTeams(state, data) {
      state.teams = data;
    },

    setTerminationReasons(state, data) {
      state.terminationReasons = data;
    },

    setSystemAccessRelations(state, data) {
      state.systemAccessRelations = data;
    },
  },
  actions: {
    async getAll({ commit, state }, params) {
      try {
        const rowsPerPage = params.rowsPerPage || state.persons.rowsPerPage;
        let currentPage = params.currentPage || state.persons.currentPage;
        if (!state.persons.totalPages || (currentPage < 1)) currentPage = 1;

        const res = await PersonService.getAll({          
          ...params,
          currentPage,
          rowsPerPage,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setPersons', {
          //...state.persons.persons,
          list: res.data.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });

        return res;
      } catch(e) { throw e; }
    },

    async getRolesDropdown({ commit }) {
      try {
        const res = await PersonService.getRolesDropdown();
        commit('setRolesDropdown', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async getRegionsDropdown({ commit }) {
      try {
        const res = await PersonService.getRegionsDropdown();
        commit('setRegionsDropdown', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async deletePerson({ commit }, person_id) {
      try {
        const res = await PersonService.deletePerson(person_id);
        return res;
      } catch(e) { throw e; }
    },

    async getById({ commit }, person_id) {
      try {
        commit('setLoadingEditCurrentPerson', true);
        const res = await PersonService.getPersonByID(person_id);
        commit('setEditCurrentPerson', res.data.result);
        commit('setLoadingEditCurrentPerson', false);
        return res;
      } catch(e) { commit('setLoadingEditCurrentPerson', false); throw e; }
    },

    async getOffices({ commit }, person_id) {
      try {
        const res = await PersonService.getOffices(person_id);
        commit('setOffices', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async getTitles({ commit }, { person_id, ...query }) {
      try {
        const res = await PersonService.getTitles(person_id, query);
        commit('setTitles', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async getTeams({ commit }, { person_id, ...query }) {
      try {
        const res = await PersonService.getTeams(person_id, query);
        commit('setTeams', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async getTerminationReasons({ commit, state, }) {
      try {
        let data = state.terminationReasons;
        if (!data.length) {
          const res = await PersonService.getTerminationReasons();
          data = res.data.result || [];
        }
        commit('setTerminationReasons', data);
        return data;
      } catch(e) { throw e; }
    },

    async terminatePerson({ commit, state, }, payload) {
      const person_id = state.editCurrentPerson.ID;
      try {
        const res = await PersonService.terminatePerson(person_id, payload);

        const personRes = await PersonService.getPersonByID(person_id);
        commit('setEditCurrentPerson', personRes?.data?.result || state.editCurrentPerson);

        const titlesRes = await PersonService.getTitles(person_id);
        commit('setTitles', titlesRes.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async saveTitle({ commit, state, }, payload) {      
      try {
        let res;
        const person_id = state.editCurrentPerson.ID;
        if (!payload.ID) {
          res = await PersonService.createAdminTitle(person_id, payload);
        } else {
          res = await PersonService.updatePersonTitle(payload.ID, payload);
        }
        const personRes = await PersonService.getPersonByID(person_id);
        commit('setEditCurrentPerson', personRes?.data?.result || state.editCurrentPerson);

        const titlesRes = await PersonService.getTitles(person_id);
        commit('setTitles', titlesRes.data.result);
        
        return res;
      } catch(e) { throw e; }
    },

    async savePerson({ commit, state, }, payload) {      
      try {
        let res;
        if (!payload.ID) {
          res = await PersonService.insertPerson(payload);
        } else {
          res = await PersonService.updatePerson(payload.ID, payload);
        }        
        return res;
      } catch(e) { throw e; }
    },

    async getPerformanceData({ commit }, person_id) {
      try {
        const res = await PersonService.getPerformanceData(person_id);
        commit('setPerformanceData', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async getLicenseNumbers({ commit }, person_id) {
      try {
        const res = await PersonService.getPersonLicenseNumbers(person_id);
        commit('setLicenseNumbers', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async getPersonDesignations({ commit }, person_id) {
      try {
        const res = await PersonService.getPersonDesignations(person_id);
        commit('setPersonDesignations', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async getContactInformationEntries({ commit }, person_id) {
      let data = [];
      try {
        const res = await PersonService.getContactInformationEntries(person_id);
        data = res?.data?.result || [];
        commit('setContactInformationEntries', data);
      } catch (e) {
        throw e;
      }
      return data;
    },

    async getHistory({ commit }, person_id) {
      try {
        const res = await PersonService.getHistory(person_id);
        commit('setHistory', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async getNotes({ commit }, person_id) {
      try {
        const res = await PersonService.getNotes(person_id);
        commit('setNotes', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async saveNote({ commit }, params: { person_id: string | number; entity: any }) {
      try {
        let res = null;
        if (params.entity.ID) {
          res = await PersonService.updateNote(params.entity, params.person_id);
        } else {
          res = await PersonService.insertNote(params.entity, params.person_id);
        }
        const listRes = await PersonService.getNotes(params.person_id);
        commit('setNotes', listRes.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async deleteNote({ commit }, { note_id, person_id }) {
      try {
        const res = await PersonService.deleteNote(note_id);
        const listRes = await PersonService.getNotes(person_id);
        commit('setNotes', listRes.data.result);
        return res;
      } catch(e) { throw e; }
    },

    async deleteAndInsertEmailCommunicationTags({ commit }, payload) {
      try {
        const res = await PersonService.deleteAndInsertEmailCommunicationTags(payload);
        return res;
      } catch(e) { throw e; }
    },

    async getListOfPersonsForDropDown({ commit }, params = {}) {
      const res = await PersonService.getListOfPersonsForDropDown(params);
      commit('SET_PERSON_LIST', res.data.result);
      return res;
    },

    async SearchListOfPersons({ commit }, searchTerm) {
      return await PersonService.SearchListOfPersons(searchTerm);
    },

    async getSystemAccessRelations({ commit }, { person_id }) {
      try {
        const res = await PersonService.getSystemAccessRelations(person_id);
        commit('setSystemAccessRelations', res.data.result);
        return res;
      } catch(e) { throw e; }
    },

    getPersonByID({ commit }, id) {
      return PersonService.getPersonByID(id);
    },

    async getPersonSuffixes({ commit }) {
      const data = mockData.personSuffixes;
      commit('setPersonSuffixes', data);
      return { data: { results: data, }};
    },

    async getEmailTags({ commit }) {
      const data = mockData.emailTags;
      commit('setEmailTags', data);
      return { data: { results: data, }};
    },

    async getSpecialisationsSelect({ commit }) {
      const data = mockData.specialisationsSelect;
      commit('setSpecialisationsSelect', data);
      return data;
    },

    async getDesignationsDropdown({ commit }) {
      const data = mockData.designationsDropdown;
      commit('setDesignationsDropdown', data);
      return data;
    },

    getPersonHistory({ commit }, id) {
      return PersonService.getPersonHistory(id).then((res) => commit('SET_HISTORY', res.data.result));
    },

    async loadPersonData({ commit }, person_id) {
      try {
        const res = await PersonService.loadPersonData(person_id);
        // commit('setState', res.data.result);
      } catch(e) { throw e; }
    },
  },
};
