import { getReportData } from './getReportData'
export async function generateReport(reportTemplate) {
  try {
    let returnObject: any = {};
    let cubeQuery: any = {};
    // if (reportTemplate.templateData.status !== undefined && reportTemplate.templateData.status !== 'undefined') {
    //   cubeQuery.status = reportTemplate.templateData.status;
    // }
    // if (reportTemplateData.period) {
    //   cubeQuery.period = reportTemplateData.period;
    // }
    // if (typeof reportTemplate.templateData.dateFrom === 'object') {
    //   if (reportTemplate.templateData.dateFrom) {
    //     cubeQuery.dateFrom =
    //       reportTemplate.templateData.dateFrom.year + '-' + (reportTemplate.templateData.dateFrom.month + 1) + '-' + '01';
    //   }
    // } else {
    //   cubeQuery.dateFrom = reportTemplate.templateData.dateFrom;
    // }
    // if (typeof reportTemplate.templateData.dateTo === 'object') {
    //   if (reportTemplate.templateData.dateTo) {
    //     cubeQuery.dateTo =
    //       reportTemplate.templateData.dateTo.year + '-' + (reportTemplate.templateData.dateTo.month + 1) + '-' + '28';
    //   }
    // } else {
    //   cubeQuery.dateTo = reportTemplate.templateData.dateTo;
    // }
    // cubeQuery.reportTemplateKey = reportTemplate.templateData.templateKey;
    // if (reportTemplate.templateData.regionID) {
    //   cubeQuery.regionID = reportTemplate.templateData.regionID;
    // }
    // if (reportTemplate.templateData.currency) {
    //   cubeQuery.currency = reportTemplate.templateData.currency;
    // }

    returnObject.data = getReportData(reportTemplate.templateData);
    //check if reportTemplateData.templateKey is a periodical report
    // let string = reportTemplate.templateData.title;
    // let pattern = /\bperiodical\b/i;
    // if (pattern.test(string)) {
    //   cubeQuery.periodical = true;
    // }
    // const template = reportTemplateList[reportTemplate.templateData.templateKey];
    // returnObject.reportColumnDefinitions = reportTemplate.templateData.ColumnDefinitions;
    // returnObject.selectedColumns = reportTemplate.templateData.ColumnDefinitions.map((col) => {
    //   return col.name;
    // });

    //here the report is dispatched

    // if (reportTemplateList[reportTemplateData.templateKey].nonCube) {
    //   reportData = await store.dispatch('reports/getMonthlyAgentProduction', {
    //     query: {
    //       regionId: reportTemplateData.regionID,
    //       endDate: reportTemplateData.dateTo,
    //     },
    //   });
    // returnObject.reportData = reportData;
    // return returnObject;
    // } else {

    return returnObject;
  } catch (e) {
    console.log(e);
    /**/
  }
}
