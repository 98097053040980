import { BaseService } from '../base.service';

export class OfficeEditorService extends BaseService {
  static get entity() {
    return 'office';
  }

  static office(officeID, params) {
    return this.get(`office/${officeID}`, params);
  }

  static franchiseAgreement(officeID, params) {
    return this.get(`office/${officeID}/franchiseAgreement`, params);
  }

  static franchiseHistory(officeID, params) {
    return this.get(`office/${officeID}/franchiseHistory`, { sort: '-signedDate', ...params });
  }

  static statistics(officeID, params) {
    return this.get(`office/${officeID}/statistics`, params);
  }

  static officeFranchiseAgreement_devScheduleSnapshot(franchiseAgreementID, params) {
    return this.get(`officeFranchiseAgreement/${franchiseAgreementID}/developmentScheduleSnapshot`, params);
  }

  static franchiseAgreementSegments(officeID, params) {
    return this.get(`office/${officeID}/franchiseAgreementSegments`, params);
  }

  static firstFranchiseAgreementFeeAmount(officeID, params) {
    return this.get(`office/${officeID}/firstFranchiseAgreementFeeAmount`, params);
  }

  static specialization(officeID, params) {
    return this.get(`office/${officeID}/specialization`, params);
  }

  static contactInformationEntry(officeID, params) {
    return this.get(`office/${officeID}/contactInformationEntry`, params);
  }

  static currencyPayment(officeID, params) {
    return this.get(`office/${officeID}/currencyPayment`, params);
  }

  static officeRegionCurrencyLfa(regionID, params) {
    return this.get(`region/${regionID}/currencyLfa`, params);
  }

  static officeRegionCurrencyReporting(regionID, params) {
    return this.get(`region/${regionID}/currencyReporting`, params);
  }

  static officeLevels(params) {
    return this.get(`officeLevels`, params);
  }

  static officeRankingLabels(params) {
    return this.get(`offices/officeRankingLabels`, { sort: 'entryValue', ...params });
  }

  static regionBusinessDevelopers(regionID, params) {
    return this.get(`region/${regionID}/businessDevelopers`, { sort: 'entryValue', ...params });
  }

  static snapshots(officeID, params) {
    return this.get(`office/${officeID}/snapshots`, { sort: '-validFrom', ...params });
  }

  static officeShareSnapshot(snapshotID, params) {
    return this.get(`officeShareSnapshot/${snapshotID}`, params);
  }

  static officeFranchiseAgreement_segmentHistory(ID, params) {
    return this.get(`officeFranchiseAgreement/segmentHistory/${ID}`, params);
  }

  static isFranchiseAgreementCreate(officeID, params) {
    return this.get(`office/${officeID}/isFranchiseAgreementCreate`, params);
  }

  static mainOffices(officeID, params) {
    return this.get(`office/${officeID}/mainOffices`, { sort: 'name', ...params });
  }

  static regionFranchiseAgreement(officeID, params) {
    return this.get(`office/${officeID}/regionFranchiseAgreement`, params);
  }

  static regionalPersonForFranchiseSalesRep(regionID, params) {
    return this.get(`region/${regionID}/regionalPersonForFranchiseSalesRep`, { sort: 'lastName', ...params });
  }

  static developmentScheduleSnapshots(officeID, params) {
    return this.get(`office/${officeID}/developmentScheduleSnapshots`, { sort: '-valid_from', ...params });
  }

  static notes(officeID, params) {
    return this.get(`office/${officeID}/note`, { sort: '-noteDate', ...params });
  }

  static history(officeID, params) {
    return this.get(`office/${officeID}/history`, { sort: '-changeDate', limit: '0,35', ...params });
  }

  static persons(officeID, params) {
    return this.get(`office/${officeID}/persons`, { only_active: '1', ...params });
  }

  static groups(entityID, params) {
    return this.get(`remaxEntityHasGroup/${entityID}/groups`, { sort: 'valid_from', only_active: '0', ...params });
  }

  static developmentSchedule(officeID, params) {
    return this.get(`office/${officeID}/developmentSchedule`, params);
  }

  static getLatestOfficeShareSnapshot(params) {
    return this.post(`officeShareSnapshot/getLatestOfficeShareSnapshot`, params);
  }

  static insertOffice(payload) {
    return this.post(`offices`, payload);
  }

  static updateOffice(officeID, payload) {
    return this.put(`office/${officeID}`, payload);
  }

  static insertNote(entity, office_id) {
    return this.post(`v1/office/${office_id}/note/`, entity);
  }

  static updateNote(entity, office_id) {
    return this.put(`v1/office/${office_id}/note/${entity.ID}`, entity);
  }

  static deleteNote(id) {
    return this.delete(`v1/office/note/${id}`);
  }

  // static insertNote(entity, id) {
  //   return this.post(`v1/region/${id}/note/`, entity);
  // }

  // static updateNote(entity, id) {
  //   return this.put(`v1/region/${id}/note/`, entity);
  // }

  // static deleteNote(id) {
  //   return this._delete(`v1/region/note/${id}`);
  // }

  static addOfficeDevelopmentSchedules(officeID, franchiseAgreementID, params) {

    const body = {
      officeID : officeID,
      franchiseAgreementID : franchiseAgreementID,
      developmentScheduleEntries : params.officeDevelopmentSchedules
    }
    return this.post(`/v1/office/developmentScheduleEntries/add`, body);

  }

  static saveOfficeDevelopmentSchedules(franchiseAgreementID, params) {
    
    return this.put(`officeDevelopmentScheduleSnapshot/${franchiseAgreementID}/officeDevelopmentSchedules`, params);
  }

  static franchiseByHistorySnapshotID(ID, params) {
    return this.get(`office/${ID}/franchiseAgreement/loadByFranchiseID`, params);
  }

  static getOfficeList(regionID, params) {
    return this.get(`reportingRegionOfficeList/${regionID}`, params);
  }
  static getSingleOffice(officeID, params) {
    return this.get(`office/${officeID}`);
  }
}
