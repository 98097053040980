import { regions as regionsList } from './regions';
import { accessRoles as accessRolesList } from './roles';

export const regions = regionsList.map(r => ({
    label: r.name,
    value: r.id,
}));

export const accessRoles = accessRolesList.map(r => ({
    label: r.name,
    value: r.id,
}));

export const statuses = [
    { label: 'Active', value: 'active', },
    { label: 'Inactive', value: 'inactive', },
];

export const remaxTitles = [
    {
        "label": "Administration",
        "value": "administration"
    },
    {
        "label": "Agent",
        "value": "agent"
    },
    {
        "label": "Agent in Training",
        "value": "agent_in_training"
    },
    {
        "label": "Broker/Owner",
        "value": "broker/owner"
    },
    {
        "label": "Business Development",
        "value": "business_development"
    },
    {
        "label": "Finance",
        "value": "finance"
    },
    {
        "label": "Franchise Sales",
        "value": "franchise_sales"
    },
    {
        "label": "Front Desk",
        "value": "front_desk"
    },
    {
        "label": "IT",
        "value": "it"
    },
    {
        "label": "Marketing",
        "value": "marketing"
    },
    {
        "label": "Office Staff",
        "value": "office_staff"
    },
    {
        "label": "Officer",
        "value": "officer"
    },
    {
        "label": "Operations",
        "value": "operations"
    },
    {
        "label": "Principal of Franchisee (Office)",
        "value": "principal_of_franchisee_(office)"
    },
    {
        "label": "Regional Administrator",
        "value": "regional_administrator"
    },
    {
        "label": "Regional Director",
        "value": "regional_director"
    },
    {
        "label": "Regional Principal of Franchisee (Region)",
        "value": "regional_principal_of_franchisee_(region)"
    },
    {
        "label": "Regional Staff",
        "value": "regional_staff"
    },
    {
        "label": "System Administrator",
        "value": "system_administrator"
    },
    {
        "label": "Territory Administrator",
        "value": "territory_administrator"
    },
    {
        "label": "Training",
        "value": "training"
    }
];
