import { promiseAll } from '@/utils/promiseAll';
import { getDefaultState } from './constants';
import { init, changeHistory, person, company } from './init';
import { BaseService } from '../../../services/base.service';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		init(store, contactID) { return init(store, contactID); },

		company(store, params) { return company(store, params); },

		person(store, params) { return person(store, params); },

		history(store, params) { return changeHistory(store, params); },
	},
};
  