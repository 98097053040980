import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const team_performance_aggregated: any = {
  cubeParameters: {
    measures: [
      'PerformanceCube.gciForPeriod',
      'PerformanceCube.transactionForPeriod',
      'PerformanceCube.volumeForPeriod',
      'PerformanceCube.gciForPeriodRxd',
      'PerformanceCube.gciForPeriodEur',
      'PerformanceCube.volumeForPeriodRxd',
      'PerformanceCube.volumeForPeriodEur',
    ],
    order: {
      'OfficeCube.regionname': 'asc',
      'OfficeCube.officeid': 'asc',
      'OfficeCube.officename': 'asc',
    },
    filters: [
      {
        member: 'PerformanceCube.teamid',
        operator: 'gt',
        values: ['0'],
      },
    ],
    dimensions: [
      'PerformanceCube.teamid',
      'PerformanceCube.officeid',
      'TeamCube.teamname',
      'TeamCube.regionid',
      'RegionCube.region',
      'RegionCube.reportingcurrency',
      'OfficeCube.officename',
      'OfficeCube.uniqueofficeid',
      'OfficeCube.officeConstituentId',
      'TeamCube.teamllcid',
      'TeamCube.teammembercount',
      'TeamCube.teamsize',
      'TeamCube.teamStatus',
    ],
    mainCubeParameter: 'PerformanceCube',
  },
  joinDimension: 'teamid',
  alternativeCubeParameters: {
    measures: [],
    dimensions: [
      'TeamChangesCube.isteamleader',
      'TeamChangesCube.personid',
      'personCube.lastname',
      'TeamChangesCube.teamid',
      'personCube.uniquepersonid',
    ],
    mainCubeParameter: 'TeamChangesCube',
    filters: [
      {
        member: 'TeamChangesCube.isteamleader',
        operator: 'equals',
        values: ['1'],
      },
    ],
    timeParameter: 'TeamChangesCube.teamrecdate',
  },

  columns: [
    TC.region,
    TC.subRegion,
    TC.officeName,
    TC.officeUniqueID,
    TC.officeConstituentID,
    TC.teamllcid,
    TC.teamNamePerformanceAggregated,
    TC.teamLeaderID,
    TC.teamLeaderNameJoined,
    TC.teamMemberCount,
    TC.teamSize,
    TC.teamStatusTeamAggregated,
    TC.gciPeriodStandard,
    TC.transactionsPeriodStandard,
    TC.volumePeriodStandard,
  ],
  settingsPanel: panel,
};
