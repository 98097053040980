export const packages = [
    {
        id: 1,
        tier: 'region',
        data: [
            { permissions: ['none', 'read', 'write'],  name: 'Region General', description: 'Listview, General Information and Regional Directors.', },
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Region Contact', description: 'Head Office Data, all Persons in Person Tab.', },
            { permissions: ['none', 'read', 'write'],  name: 'Regional Ownership', description: 'Managing Company/ies', },
            { permissions: ['none', 'read', 'write'],  name: 'Region Persons', description: "Can see / add / exchange Person's Titles", },
            { permissions: ['none', 'read', 'write'],  name: 'Region Fees', description: 'Fees Box in Fees Tab. Fee Reliefs based on Regional Fees.', },
            { permissions: ['none', 'read', 'write'],  name: 'Region RFA', description: 'Region Franchise Agreement in Fees Tab. Fee Reliefs based on RFA Fees.', },
            { permissions: ['none', 'read', 'write'],  name: 'Region Performance Schedules', description: 'Development & Performance Schedules', },
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Region Business Plan', description: 'Business Plan Table', },
            { permissions: ['none', 'read', 'write'],  name: 'Region Notes & History', description: 'Notes tab. History tab.', },
            { permissions: ['none', 'write'],  name: 'Region Create', description: 'Create Region Button in Listview.', },
            { permissions: ['none', 'read', 'write'],  name: 'Region Delete', description: 'Delete Icon in Listview.', },
            { permissions: ['none', 'write'],  name: 'Subregion Tags', description: 'Show and edit subregion tags.', },
        ],
    },
    {
        id: 2,
        tier: 'company',
        data: [
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Company General', description: 'Complete Company Listview. Company General fields with following READ-ONLY fields: Name, Corporate Name, Country, Region, Start Date, Status.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Company Ownership', description: 'Access to Name, Corporate Name, Country, Region, Start Date, Status fields and Company Shareholder Tab as Company Office Tab.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Company Notes', description: 'Company Notes Tab.', },				
            { permissions: ['none', 'read'],  name: 'Company History', description: 'Company History Tab, this tab is only readable.', },				
            { permissions: ['none', 'request', 'write'],  name: 'Company Create', description: 'Create Company Button in Listview.', },				
            { permissions: ['none', 'request', 'write'],  name: 'Company Delete', description: 'Delete Company Icon in Listview.', },
        ],
    },
    {
        id: 3,
        tier: 'office',
        data: [
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office General', description: 'Following Listview Fields: Office Name, Corporate Name, UniqueID, Region, RegionID, City, Agent Count and State. Following Office General Fields: Name, Corporate Name, UniqueID (READ-ONLY), Status, Office Type, Conversion, DUNIS Number VAT Number.', },				
            { permissions: ['none', 'request', 'write'],  name: 'Office Create', description: 'The Office Create Button in the Listview.', },				
            { permissions: ['none', 'read', 'write'],  name: 'Office Region & IDs	Office', description: 'Listview Fields: Office ID. Office General Tab Fields: RE/MAX Office ID, Master Office ID, Region.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Advanced Data', description: 'Office General Tab Field: External Office ID', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Ranking', description: 'Office General Tab: Office Ranking Dropdown', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Business', description: 'Developer	Office General Tab: Office Business Developer Dropdown', },				
            { permissions: ['none', 'read', 'write'],  name: 'Office Address', description: 'Office Contact Information Tab with Address Block.', },				
            { permissions: ['none', 'read', 'write'],  name: 'Office Contact', description: 'Office Contact Information Tab with Contact Information Block.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Persons', description: 'Office Person Tab, Buttons available at "Q" or higher.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Group', description: 'Access to Office Group Tab.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Ownership', description: 'Office Listview Fields: Joined RE/MAX Date and Franchise End Date. Office Ownership Tab. Office Franchise Tab (Franchise Data block). Office Franchise History left column (Ownership History)', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Finance', description: 'Office Franchise Tab: Office Franchise History Right Column: Comparison Values.', },			
            { permissions: ['none', 'read', 'write'],  name: 'Office Notes', description: 'Office Notes Tab.', },				
            { permissions: ['none', 'read'],  name: 'Office History', description: 'Office History Tab, readonly.', },				
            { permissions: ['none', 'request', 'write'],  name: 'Office Delete', description: 'The Office Delete Icon in the Listview.', },				
            { permissions: ['none', 'read', 'write'],  name: 'Office Development', description: 'Schedule', },					
            { permissions: ['none', 'read'],  name: 'Office Team', description: 'Can see Team-Tab in Office. Shows all Teams the Office is in.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Finance Advanced', description: 'Office Franchise Tab: Can read, request and update advanced Franchise Data for Offices like Payment Block. On write Permission: Can renew Office Franchises and Can close Offices', },				
        ],
    },
    {
        id: '4',
        tier: 'person',
        data: [
            { permissions: ['none', 'write'],  name: 'Allow Backdating', description: 'Allow backdating for example for Title Changes', },				
            { permissions: ['none', 'write'],  name: 'Add Historic Titles', description: 'Add historic titles to offices which are currently inactive.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Person General', description: 'Person Listview. Person General Tab with following READ-ONLY Fields: First name, Kast, name, Gender. Person General Tab Normal: Academic Title, Initias/Middle Name, DisplayName, Former Name, Suffix, UniqueID, Specialisation, Languages. Person Contact Information Tab. Person Business Card. Person Awards and Statistics.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Person Naming', description: 'Person General Tab: First Name, Last Name.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Person Advanced Data', description: 'Person General Tab Fields: Gender, Date of Birth, Social Security Number, RE/MAX Commencement Date, Annual Dues Anniversary.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Person Region & Designation', description: 'Person General Fields: Region, Display Title, Current Billing Classification, Designation.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Person IDs', description: 'Person General Fields: RE/MAX ID, Master ID.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Person Titles & Teams', description: 'Person Titles Tab, Person Teams Tab.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Person Notes', description: 'Person Notes Tab.', },				
            { permissions: ['none', 'read'],  name: 'Person System Access', description: 'Showing all system accesses from a person (readonly).', },				
            { permissions: ['none', 'read'],  name: 'Person History', description: 'Person History Tab. (Showing all Person chagnes and is readonly)', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Person Create', description: 'Create Person Button in Person Listview.', },				
            { permissions: ['none', 'request', 'write'],  name: 'Person Delete', description: "Delete Person Icon in Person Listview and Delete of Person's Former Names.", },				
            { permissions: ['none', 'request', 'write'],  name: 'Person Duplicate', description: 'Can edit duplicate profiles', },
        ],
    },
    {
        id: 5,
        tier: 'reports',
        data: [
            { permissions: ['none', 'read'],  name: 'Reports General',	description: 'Basic reports view.', },				
            { permissions: ['none', 'write'],  name: 'Create Reports',	description: 'Creating new Reports from Template', },
        ],
    },
    {
        id: 6,
        tier: 'exchange rates',
        data: [
            {permissions: ['none', 'read', 'request', 'write'],  name: 'Exchange Rates General', description: 'List of Currencies <-> Currencies, Detail Currency Rates' },				
            {permissions: ['none', 'read', 'request', 'write'],  name: 'Exchange Rates Daily Overview', description: 'Can Read Daily Overview of Exchange Rates' },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Exchange Rates International Dollar General', description: 'Year Lists with International Dollar - Rates of Top Regions. Detail Page with Country List and Rates to International Dollar', },				
            {permissions: ['none', 'write'],  name: 'Exchange Rates International Dollar Create', description: 'Add year with country rates	' },			
            {permissions: ['none', 'write'],  name: 'Exchange Rates International Dollar Delete', description: 'Delete whole year with rates for International Dollar' },
        ],
    },
    {
        id: 7,
        tier: 're/max basics',
        data: [
        {permissions: ['none', 'read'],  name: 'Global Search', description: '', },
        {permissions: ['none', 'read'],  name: 'Global Search', description: 'Permission to search globally', },				
        {permissions: ['none', 'write'],  name: 'Can Upload Profile Picture', description: 'User can upload and remove his own profile picture.', },
        ],
    },
    {
        id: 8,
        tier: 'office grouping',
        data: [
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Office Grouping', description: 'Access to all Office Grouping Content. Regions only see Office Groups in their Region. Create, Edit and Delete Office Groups.', }
        ],
    },
    {
        id: 9,
        tier: 'team',
        data: [
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Team General	All Team content.', description: 'Regions only see Teams from their own Region and Offices only see Teams in their own Office.', },				
            { permissions: ['none', 'write'],  name: 'Team & Teammembership Delete', description: 'Can delete Team and Teammembership', },
        ],
    },
    {
        id: 10,
        tier: 'annual dues',
        data: [
            { permissions: ['none', 'write'],  name: 'Annual Dues Open Report', description: 'Gain access to open a Report of a Region.', },				
            { permissions: ['none', 'write'],  name: 'Annual Dues Reopen Report', description: 'Gain access to reopen the latest closed Report of a Region.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Annual Dues General', description: 'Can access page Annual Dues. View old Reports, Edit open Report (Delay, Write-Off, Add, Comment, Approve, Reject, Undo). Export AD Report.', },				
            { permissions: ['none', 'read'],  name: 'Annual Dues History', description: 'Annual History Tab, this tab is only readable.', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Finance Overview', description: '', },	
        ],
    },
    {
        id: 11,
        tier: 'awards',
        data: [
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Awards General	Can read Awards Page. Person Awards. Update: Approve/Delete Person Awards', },				
            { permissions: ['none', 'write'],  name: 'Award Delete	Can delete award', },
        ],
    },
    {
        id: 12,
        tier: 'reporting',
        data: [
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Reporting General', description: 'Can access Page Reporting. See List of Regions, Months, Offices and Detailed Pages for each Office with Reporting Data Input per Person.', },				
            { permissions: ['none', 'read'],  name: 'Reporting History', description: 'Can Read Reporting History Tab', },				
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Reporting Data Export/Import', description: 'Can Export Reporting Data as Excel File and Import Excel Files (fills Input Fields per Office)', },				
            { permissions: ['none', 'read', 'write'],  name: 'Reporting Bulk Export/Import Data', description: 'Can export complete zip file with all Office Excel-Files and can import multiple Office-Excel-Files for Reporting Period', },				
            { permissions: ['none', 'write'],  name: 'Reporting Allow Negative Commissions', description: 'Allow negative commissions for monthly reporting', },
        ],
    },
    {
        id: 13,
        tier: 'notification',
        data: [
            { permissions: ['none', 'read'],  name: 'Notification', description: 'Shows the notification center', },
        ],
    },
    {
        id: 14,
        tier: 'contract log',
        data: [
            { permissions: ['none', 'read', 'write'],  name: 'Contract Log General', description: 'Can access Page Contract Log.', },
        ],
    },
    {
        id: 15,
        tier: 'user permission',
        data: [
            { permissions: ['none', 'read', 'write'],  name: 'System Access General', description: 'Can read System Access Page, edit user permissions and export data.'},
        ],
    },
    {
        id: 16,
        tier: 'fee relief',
        data: [
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Fee Relief General', description: 'Fee Relief Listview and General Information'},				
            { permissions: ['none', 'write'],  name: 'Fee Relief Create', description: 'Can create fee reliefs'},				
            { permissions: ['none', 'write'],  name: 'Fee Relief Delete', description: 'Can delete fee reliefs'},
        ],
    },
    {
        id: 17,
        tier: 'contact management',
        data: [
            { permissions: ['none', 'read', 'request', 'write'],  name: 'Contact Management General', description: 'Contact Management General Information'},				
            { permissions: ['none', 'write'],  name: 'Contact Management Create', description: 'Can create contact managements'},				
            { permissions: ['none', 'write'],  name: 'Contact Management Delete', description: 'Can delete contact managements'},				
            { permissions: ['none', 'read'],  name: 'Contact Management History', description: 'Contact Management History Tab. (Showing all Contact Management changes and is readonly)'},				
            { permissions: ['none', 'write'],  name: 'Contact Management Trigger Sync', description: 'Can trigger Contact Managements Sync via Sync Button'},				
            { permissions: ['none', 'read'],  name: 'Contact Management Log General', description: 'Contact Management Log Listview'},
        ],
    },
    {
        id: 18,
        tier: 'external API',
        data: [
            { permissions: ['none', 'read'],  name: 'Region', description: 'Relevant Permissions for Region section in the External API', },
        ],
    },
    {
        id: 19,
        tier: 'dashboard widgets',
        data: [
            { permissions: ['none', 'read'],  name: 'Dashboard Widgets General', description: 'View and add widgets.', },
        ],
    },
];
