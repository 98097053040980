import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';

export async function init(store, regionID: any) {	
	try {
		const res = await promiseAll([
			BaseService.sessionPermissions(),
			BaseService.get(`region/${regionID}`),
			BaseService.get(`region/${regionID}/currencyPayment`),
			BaseService.get('regions/count'),			
			annualDuesFinances(store, {}, regionID),
		]);

		store.commit('setState', {
			initParams: { regionID, },
			region: res[1]?.data?.result,
			currencyPayment: res[2]?.data,
		});
	} catch(e) { throw e; }
}

export async function annualDuesFinances({ commit, state }, params = {}, regionID?: any) {
	try {
		regionID = regionID || state.region?.ID;
		const { queryParams, reportMonth, reportYear, ..._params } = { ...params, } as any;
		const currentPage = _params.currentPage || 1;
		const rowsPerPage = _params.rowsPerPage || state.annualDuesFinances.rowsPerPage || 35;
		const qParams = { ...state.annualDuesFinances.queryParams, ...queryParams, };

		commit('setState', { annualDuesFinances: { ...state.annualDuesFinances, loading: true, queryParams: qParams, }, });

		const annualDuesFinancesPayload = {
			...state.annualDuesFinances.queryParams,
			...qParams,
			//MAX-1129
			//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
		};

		const res = await BaseService.get(`region/${regionID}/annualDuesFinances`, annualDuesFinancesPayload);
		const totalRows = res?.data?.info?.numRows || 0;

		const list = res?.data?.result || [];

		commit('setState', {
			annualDuesFinances: {
				..._params,
				currentPage,
				rowsPerPage,
				loading: false,
				totalRows,
				totalPages: Math.ceil(totalRows / rowsPerPage),
				list,
				queryParams: qParams,
			},
		});
	} catch(e) { 
		commit('setState', { annualDuesFinances: { ...state.annualDuesFinances, loading: false, }, });
		throw e; 
	}
}
