import { init, syncLog } from './init';
import { getDefaultState } from './constants';
import { SyncLogService } from '@/services/modules/syncLog.service';

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },

    resetSyncLogFilters(state) {
      const list = state.syncLog.list;
      state.syncLog = {
        ...getDefaultState().syncLog,
        list,
      };
    },
  },
  actions: {
    init(store, params) {
      return init(store, params);
    },

    syncLog(store, params) {
      return syncLog(store, params);
    },

    resetSyncLogFilters(store) {
      store.commit('resetSyncLogFilters');
      return syncLog(store, getDefaultState().syncLog);
    },

    async selectContactManagementLogList({ commit, state }, params) {
      try {
        const { queryParams, ..._params } = { ...params } as any;
        const currentPage = _params.currentPage || 1;
        const rowsPerPage = _params.rowsPerPage || 35;
        const qParams = { ...state.syncLog.queryParams, ...queryParams };
        commit('setState', { teams: { ...state.syncLog, loading: true, queryParams: qParams } });
        const res = await SyncLogService.selectContactManagementLogList({ currentPage, rowsPerPage, ...qParams });
        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);

        return res;
      } catch (e) {
        throw e;
      }
    },

    async selectContactManagementSyncLogsByTokenAndType({ commit }, { token, id, type }) {
      try {
        const res = await SyncLogService.selectContactManagementSyncLogsByTokenAndType(token, id, type);
        commit('setState', {
          syncLogView: res,
        });
        return res;
      } catch (e) {
        throw e;
      }
    },

    async contactSyncAccountMail({ commit }) {
      try {
        const res = await SyncLogService.contactSyncAccountMail();
        commit('setState', {
          selected: [],
          syncLogEmailList: res,
        });
        return res;
      } catch (e) {
        throw e;
      }
    },
  },
};
