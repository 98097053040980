export const getDefaultState = () => ({
	initParams: {},

	region: null,

	currencyPayment: null,

	annualDuesFinance: null,

	savingAnnualDuesFinance: false,
});
