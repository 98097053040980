import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const agent_performance_aggregated_combined_optimized: any = {
  cubeParameters: {
    measures: [
      'PerformanceCube.gciForPeriod',
      'PerformanceCube.transactionForPeriod',
      'PerformanceCube.volumeForPeriod',
      'PerformanceCube.gciForPeriodRxd',
      'PerformanceCube.gciForPeriodEur',
      'PerformanceCube.volumeForPeriodRxd',
      'PerformanceCube.teamIDMeasure',
      'PerformanceCube.volumeForPeriodEur',
    ],
    order: [
      ['personCube.idperson', 'asc'],
      ['personCube.lastname', 'asc'],
      ['personCube.firstname', 'asc'],
    ],
    dimensions: [
      'PerformanceCube.regionid',
      'PerformanceCube.personid',
      'RegionCube.region',
      // 'OfficeCube.uniqueofficeid',
      // 'OfficeCube.officeConstituentId',
      // 'OfficeCube.officename',
      'personCube.idperson',
      'personCube.officeUniqueid',
      'personCube.primaryofficename',
      'personCube.lastname',
      'personCube.firstname',
      'personCube.officeUniqueid',
      'personCube.officeconstituentid',
      'personCube.mainofficename',
      'personCube.TeamName',
      'personCube.uniquepersonid',
      'personCube.tenuredays',
      'personCube.primarytitle',
      'personCube.isactive',
      'personCube.reportingcurrencyiso',
      'personCube.idllcperson',
      'personCube.display_title',
      'personCube.titleprimary',
      'personCube.title',
      'personCube.subregionname',
    ],
    mainCubeParameter: 'PerformanceCube',
    filterParameter: 'PerformanceCube.transactiondate',
    timeParameter: 'PerformanceCube.transactiondate',
    countParameter: 'personCube.count',
  },
  joinDimension: 'teamIDMeasure',
  alternativeCubeParameters: {
    measures: [],
    dimensions: ['TeamChangesCube.teamIDMeasure', 'TeamCube.teamllcid', 'TeamCube.teamname'],
    mainCubeParameter: 'TeamChangesCube',
    timeParameter: 'TeamChangesCube.teamrecdate',
  },

  columns: [
    TC.region,
    TC.subRegion,
    TC.mainOfficeName,
    TC.personOfficeUniqueID,
    TC.personOfficeConstituentID,
    TC.teamName,
    TC.teamllcid,
    TC.lastName,
    TC.firstName,
    TC.uniqueID,
    TC.personConstituentID,
    TC.tenureDays,
    TC.primaryTitle,
    TC.status,
    TC.gciPeriodStandard,
    TC.transactionsPeriodStandard,
    TC.volumePeriodStandard,
  ],
  settingsPanel: panel,
};
