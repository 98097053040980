import { RegionService } from '@/services/modules/region.service';
import { RolesService } from '@/services/modules/roles.service';

export default {
  namespaced: true,
  state: {
    currentRegion: {
      directors: [],
    },
    regionsTable: {
      list: [],
      totalRows: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage: 35,
    },
    regionList: [],
    errorList: [],
    regionalPersonList: [],
    developmentSchedule: [],
    performanceSchedule: [],
    feeRelief: [],
    noteList: [],
    history: [],
    emailTags: [],
    franchiseAgreement: {
      franchiseAgreementSegments: [],
    },
    managingCompanies: [],
    regionRoles: [],
    devSnapShots: [],
    currentDevSnap: '',
    currentPerformanceSnap: '',
    performanceSnapShots: [],
    bussinessPlanList: [],
    regions: [],
    loadingData: false,
    loadingFilters: false,
    hasSubRegion: {},
    regionsDropdown: [],
  },
  getters: {
    getRegionList: (state) => state.regionList,
    getRegionalPersonList: (state) => state.regionalPersonList,
    getDevelopmentSchedule: (state) => state.developmentSchedule,
    getHistory: (state) => state.history,
    getPerformanceSchedule: (state) => state.performanceSchedule,
    getFeeRelief: (state) => state.feeRelief,
    getNoteList: (state) => state.noteList,
    getCurrentRegion: (state) => state.currentRegion,
    getErrorList: (state) => state.errorList,
    getEmailTags: (state) => state.emailTags,
    getFranchiseAgreement: (state) => state.franchiseAgreement,
    getManagingCompanies: (state) => state.managingCompanies,
    getRegionRoles: (state) => state.regionRoles,
    getdevSnapShots: (state) => state.devSnapShots,
    getPerformanceSnapShots: (state) => state.performanceSnapShots,
    getBussinessPlanList: (state) => state.bussinessPlanList,
    getRegions: (state) => state.regions,
    getHasSubRegion: (state) => state.hasSubRegion,
    getRegionsDropdown: (state) => state.regionsDropdown
  },
  mutations: {
    setRegionsTable(state, data) {
      state.regionsTable = { ...state.regionsTable, ...data, };
    },
    SET_CURRENT_REGION(state, region) {
      state.currentRegion = region;
    },
    SET_MANAGING_COMPANIES(state, managingCompanies) {
      state.managingCompanies = managingCompanies;
    },
    SET_CURRENT_OFFICE_BY_ID(state, id) {
      state.currentRegion = state.regionList[id];
    },
    SET_REGION_LIST(state, regionList) {
      state.regionList = regionList;
    },
    SET_ERRORS(state, errorList) {
      state.errorList = errorList;
    },
    UPDATE_CURRENT_REGION(state, region) {
      state.currentRegion = Object.assign(state.currentRegion, region);
    },
    SET_REGION_COUNTRY(state, country) {
      state.currentRegion = Object.assign(state.currentRegion, country);
    },
    SET_REGION_DIRECTORS(state, country) {
      state.currentRegion.directors = country;
    },
    SET_FRANCHISE_AGREEMENT(state, franchiseAgreement) {
      state.franchiseAgreement = franchiseAgreement;
    },
    UPDATE_FRANCHISE_AGREEMENT(state, franchiseAgreement) {
      // franchiseAgreement.filter((i) => !state.franchiseAgreement(i));
      state.franchiseAgreement = { ...state.franchiseAgreement, ...franchiseAgreement };
    },
    UPDATE_DEVELOPMENT_SCHEDULE(state, developmentSchedule) {
      const filteredArr = developmentSchedule.filter((i) => !state.developmentSchedule.includes(i));
      state.developmentSchedule = [...state.developmentSchedule, ...filteredArr];
    },
    UPDATE_PERFORMANCE_SCHEDULE(state, performanceSchedule) {
      const filteredArr = performanceSchedule.filter((i) => !state.performanceSchedule.includes(i));
      state.performanceSchedule = [...state.performanceSchedule, ...filteredArr];
    },
    CREATE_EMPTY_PERFORMANCE_SCHEDULE(state) {
      state.performanceSchedule = insertNewRows();
    },
    CREATE_EMPTY_DEVELOPMENT_SCHEDULE(state) {
      state.developmentSchedule = insertNewRows();
    },
    SET_REGIONAL_PERSONS(state, regionalPersonList) {
      state.regionalPersonList = regionalPersonList;
    },
    SET_DEVELOPMENT_SCHEDULE(state, regionalPersonList) {
      state.developmentSchedule = regionalPersonList.developmentSchedules;
    },
    SET_PERFORMANCE_SCHEDULE(state, regionalPersonList) {
      state.performanceSchedule = regionalPersonList.performanceSchedules;
    },
    SET_Fee_RELIEF(state, regionalPersonList) {
      state.feeRelief = regionalPersonList;
    },
    SET_NOTE_LIST(state, regionalPersonList) {
      state.noteList = regionalPersonList;
    },
    SET_HISTORY(state, regionalPersonList) {
      state.history = regionalPersonList;
    },
    SET_REGION_ROLES(state, regionRoles) {
      state.regionRoles = regionRoles;
    },
    SET_currentDevSnap(state, currentDevSnap) {
      state.currentDevSnap = currentDevSnap;
    },
    SET_currentPerformanceSnap(state, currentPerformanceSnap) {
      state.currentPerformanceSnap = currentPerformanceSnap;
    },
    SET_EMAIL_TAGS(state, emailTags) {
      state.emailTags = emailTags;
    },
    SET_HAS_SUBREGION(state, res) {
      state.hasSubRegion = res;
    },
    REMOVE_ONE_BY_ID(state, id) {
      // const arr = state.franchiseAgreement.franchiseAgreementSegments;
      // arr.splice(
      //   arr.findIndex((i) => i.field === 'ID'),
      //   id
      // );
      state.franchiseAgreement.franchiseAgreementSegments.pop();
    },
    SET_PERFORMANCE_SNAPSHOTS(state, snapshots) {
      state.currentPerformanceSnap = snapshots[0];
      state.performanceSnapShots = snapshots;
    },
    SET_DEV_SNAPSHOTS(state, snapshots) {
      state.currentDevSnap = snapshots[0];
      state.devSnapShots = snapshots;
    },
    SET_BUSINESS_PLAN(state, bussinessPlanList) {
      state.bussinessPlanList = bussinessPlanList;
    },
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    setRegionFiltersLoading(state, loadingState) {
      state.loadingFilters = loadingState;
    },
    setRegionsDropdown(state, regions) {
      state.regionsDropdown = regions;
    }
  },
  actions: {
    async getRegionsTable({ commit, state }, params) {
      try {
        const rowsPerPage = params.rowsPerPage || 1000;//table one page maximum
        let currentPage = params.currentPage || 1;

        const res = await RegionService.getRegionsTable({          
          ...params,
          currentPage,
          rowsPerPage,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setRegionsTable', {
          //...state.persons.persons,
          list: res.data.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });

        return res;
      } catch(e) { throw e; }
    },
    getById({ commit }, payload) {
      return RegionService.getById(payload).then((res) => commit('SET_CURRENT_REGION', res.data.result));
    },
    getAllWithSearch({ commit }, searchTerm) {
      return RegionService.getAllWithSearch(searchTerm).then((res) => commit('SET_REGION_LIST', res.data.result));
    },
    getAllWithFilter({ commit }, FilterTerm) {
      return RegionService.getAllWithFilter(FilterTerm).then((res) => commit('SET_REGION_LIST', res.data.result));
    },
    getAll({ commit }) {
      return RegionService.getAll().then((res) => commit('SET_REGION_LIST', res.data.result));
    },
    deleteOne({ commit }, payload) {
      return RegionService.remove(payload);
    },
    create({ commit }, payload) {
      return RegionService.create(payload);
    },
    updateById({ commit, state }, id) {
      return RegionService.update(id, state.currentRegion);
    },
    updateRegionCountry({ commit }, payload) {
      return RegionService.getCountryById(payload).then((res) => commit('SET_REGION_COUNTRY', res.data.result));
    },
    UpdateAnnualDuesAgreement({ commit }, { entity: entity, id: id }) {
      return RegionService.UpdateAnnualDuesAgreement(entity, id);
    },
    getManagingDirectors({ commit }, payload) {
      return RegionService.getManagingDirectors(payload).then((res) => commit('SET_REGION_DIRECTORS', res.data.result));
    },
    getFranchiseAgreement({ commit }, payload) {
      return RegionService.franchiseAgreement(payload).then((res) => commit('SET_FRANCHISE_AGREEMENT', res.data.result));
    },
    getRegionalPersons({ commit }, id) {
      return RegionService.getRegionalPersons(id).then((res) => commit('SET_REGIONAL_PERSONS', res.data.result));
    },
    insertRegionalPersons({ commit }, { entity: entity, id: id }) {
      return RegionService.insertRegionalPersons(entity, id);
    },
    updateRegionalPersons({ commit }, { entity: entity, id: id }) {
      return RegionService.updateRegionalPersons(entity, id);
    },
    insertFeeRelief({ commit }, { entity: entity, id: id }) {
      return RegionService.insertFeeRelief(entity, id);
    },
    getDevelopmentSchedule({ state, commit }) {
      return RegionService.getDevelopmentSchedule(state.currentDevSnap.ID ?? -1).then((res) => commit('SET_DEVELOPMENT_SCHEDULE', res.data.result));
    },
    getPerformanceScheduleList({ commit, state }) {
      return RegionService.getPerformanceScheduleList(state.currentPerformanceSnap.ID ?? -1).then((res) => commit('SET_PERFORMANCE_SCHEDULE', res.data.result));
    },
    getFeeRelief({ commit }, id) {
      return RegionService.getFeeRelief(id).then((res) => commit('SET_Fee_RELIEF', res.data.result));
    },
    getFeeReliefFilter({ commit }, { searchTerm: searchTerm, id: id }) {
      return RegionService.getFeeReliefFilter(searchTerm, id).then((res) => commit('SET_Fee_RELIEF', res.data.result));
    },
    getNoteList({ commit }, id) {
      return RegionService.getNoteList(id).then((res) => commit('SET_NOTE_LIST', res.data.result));
    },
    insertNote({ commit }, { entity: entity, id: id }) {
      return RegionService.insertNote(entity, id);
    },
    updateNote({ commit }, { entity: entity, id: id }) {
      return RegionService.updateNote(entity, id);
    },
    deleteNote({ commit }, id) {
      return RegionService.deleteNote(id);
    },
    getRegionHistory({ commit }, id) {
      return RegionService.getRegionHistory(id).then((res) => commit('SET_HISTORY', res.data.result));
    },
    getEmailCommunicationTagList({ commit }) {
      return RegionService.getEmailCommunicationTagList().then((res) => commit('SET_EMAIL_TAGS', res.data.result));
    },
    getManagingCompanies({ commit }, id) {
      return RegionService.getManagingCompanies(id).then((res) => commit('SET_MANAGING_COMPANIES', res.data.result));
    },
    getRegionRoles({ commit }) {
      return RolesService.getRolesForRegionDropDown().then((res) => commit('SET_REGION_ROLES', res.data.result));
    },
    getDevSnapShotList({ commit }, id) {
      return RegionService.getDevSnapShotList(id).then((res) => commit('SET_DEV_SNAPSHOTS', res.data.result));
    },
    getPerformanceSnapShotList({ commit }, id) {
      return RegionService.getPerformanceSnapShotList(id).then((res) => commit('SET_PERFORMANCE_SNAPSHOTS', res.data.result));
    },
    getBusinessPlan({ commit }, { region_id, year, searchValue }) {
      return RegionService.getBusinessPlan(region_id, year).then((res) => commit('SET_BUSINESS_PLAN', res.data.result));
    },
    insertBusinessPlan({ commit, state }, region_id) {
      return RegionService.insertBusinessPlan(region_id, state.bussinessPlanList).then((res) => commit('SET_BUSINESS_PLAN', res.data.result));
    },
    insertFranchiseAgreement({ state }, id) {
      return RegionService.insertFranchiseAgreement(state.franchiseAgreement, id);
    },
    insertAndDeleteDevelopmentSchedules({ state }, id) {
      return RegionService.insertAndDeleteDevelopmentSchedules(state.developmentSchedule, id, state.currentDevSnap.validFrom);
    },
    insertCompanySnapShot({ state }, { entity, id }) {
      return RegionService.insertCompanySnapShot(entity, id);
    },
    insertAndDeletePerformanceSchedules({ state }, id) {
      return RegionService.insertAndDeletePerformanceSchedules(state.performanceSchedule, id, state.currentPerformanceSnap.validFrom);
    },
    deleteFeeRelief({ commit }, id) {
      return RegionService.deleteFeeRelief(id);
    },
    updateFeeRelief({ commit }, { entity: entity, id: id }) {
      return RegionService.updateFeeRelief(entity, id);
    },
    franchiseAgreementOverride({ commit }, id) {
      return RegionService.franchiseAgreementOverride(id);
    },
    checkIfSubRegion({ commit }, region_id) {
      return RegionService.checkIfSubRegion(region_id).then((res) => commit('SET_HAS_SUBREGION', res.data.regionHasSubregions));
    },

    getRegionsDropdown({ commit }) {
      return RegionService.getRegionsDropdown().then((res) => commit('setRegionsDropdown', res.data.result));
    },


    async getRegions({ commit }) {
      commit('setRegionFiltersLoading', true);
      const res = await RegionService.getRegions();
      let tagArray = [];
      let filters = [];
      //map result with label:, label is needed by LookupFilterComponent
      tagArray = res.data.result.map((r) => ({
        label: r.name,
      }));
      //Sort by label
      filters = tagArray.sort((n1, n2) => {
        if (n1.label > n2.label) {
          return 1;
        }

        if (n1.label < n2.label) {
          return -1;
        }

        return 0;
      });
      const regionsFilters = [
        {
          name: 'Regions',
          options: filters,
        },
        {
          name: 'State',
          options: [
            { label: 'active' }, 
            { label: 'in renewal' }, 
            { label: 'renewal pending' }, 
            { label: 'on hold' }, 
            { label: 'in process' }, 
            { label: 'inactive' }],
        },
      ];
      commit('setRegionFiltersLoading', false);
      return regionsFilters;
    },
  },
};

function insertNewRows() {
  return [
    {
      actualGCI: null,
      actualNumAssociates: '',
      actualNumFranchises: '',
      feeReductionRules: '',
      planGCI: '',
      planNumAssociates: '',
      planNumFranchises: '',
      year: 2009,
    },
    {
      actualGCI: null,
      actualNumAssociates: '',
      actualNumFranchises: '',
      feeReductionRules: '',
      planGCI: '',
      planNumAssociates: '',
      planNumFranchises: '',
      year: 2010,
    },
    {
      actualGCI: null,
      actualNumAssociates: '',
      actualNumFranchises: '',
      feeReductionRules: '',
      planGCI: '',
      planNumAssociates: '',
      planNumFranchises: '',
      year: 2011,
    },
  ];
}
