export const getDefaultState = () => ({
	savingExchangeRate: false, 
	error: false,
	addedCountryCurrencyISO: null,
	exchangeRates: null,

	rowData: null,

	selectCurrencyExchangeRatesRemaxDollarPerYear: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35
	},

	history: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: '-changeDate',
		},
	},

	currencyExchangeRateRxdYear: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35
	},
});
