import { ChangeHistoryService } from '../../services/modules/changeHistory.service';

const defaultState = {
    history: {},
    loadingHistory: false,
};

export default {
      namespaced: true,
      state: defaultState,

      mutations: {
         setHistory(state, data) {
         state.history = { ...state.history, ...data, };
      },

      setLoadingHistory(state, loading) {
         state.loadingHistory = loading;
      },

      resetState(state) {
         state = { ...state, ...defaultState };
      },
   },

   actions: {
      async getHistory({ commit }, { apiEntity, apiParams, apiQuery, }) {
         try {
            commit('setLoadingHistory', true);
            const res = await ChangeHistoryService.getHistory(apiEntity, apiParams || [], apiQuery || {});
            commit('setHistory', { [apiEntity]: res.data.result || [] });
            commit('setLoadingHistory', false);
            return res;
         } catch(e) { commit('setLoadingHistory', false); throw e; }
      }
   },
};
