import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';
import { getDefaultState } from './constants';
import { init, changeHistory, feeRelief, } from './init';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		init(store, feeReliefID) { return init(store, feeReliefID); },

		feeRelief(store, _params) { 
			const { feeReliefID, ...params } = { ..._params } as any;
			return feeRelief(store, feeReliefID || store.state.feeRelief?.ID, params); 
		},

		history(store, params) { return changeHistory(store, params); },

		async save(store, { feeRelief }) {
			try {
				const feeReliefID = store.state.feeRelief?.ID;
				const promises = [];
				if (feeReliefID) {
					promises.push(BaseService.put(`regionAnnualDuesFeeRelief/${feeReliefID}`, feeRelief));
				} else {
					promises.push(BaseService.post('regionAnnualDuesFeeReliefs', feeRelief));
				}
				
				store.commit('setState', { saving: true, });

				const [saveFeeReliefResponse] = await promiseAll(promises);

				if (feeReliefID) await init(store, feeReliefID);

				store.commit('setState', { saving: false, });

				return { saveFeeReliefResponse };
			} catch(e) { 
				store.commit('setState', { saving: false, }); 
				throw e; 
			}
		}
	},
};
  