import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class NotificationService extends BaseService {
  static get entity() {
    return 'notification';
  }

  static async getNotifications() {
    try {
      let response = await this.get(`/notifications?filter%5BisRemoved%5D%5Beq%5D=0&sort=-createdAt%2CisViewed`);
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.status;
      throw new ErrorWrapper(error, message);
    }
  }

  static async removeNotification(id) {
    try {
      let response = await this.put(`/${this.entity}/${id}/remove`);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.status;
      throw new ErrorWrapper(error, message);
    }
  }

  static async viewNotification(id) {
    try {
      let response = await this.put(`/${this.entity}/${id}/view`);
      return new ResponseWrapper(response, response, "notification");
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.status;
      throw new ErrorWrapper(error, message);
    }
  }

  static async viewAllNotifications(params) {
    const currentPage = params.currentPage || 1;
    const rowsPerPage = 35;
    try {
      let response = await this.get(
        `/notifications?${queryString.stringify({
          sort: '-createdAt',
          //MAX-1129
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        })}&${queryString.stringify({})}`
      );
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.status;
      throw new ErrorWrapper(error, message);
    }
  }

  static async viewFilteredNotifications(params) {
    const currentPage = params.currentPage || 1;
    const rowsPerPage = 35;
    const notificationType = params.notificationType || '/';
    const searchValue = params.searchValue || '/';
    try {
      let response = await this.get(
        `/notifications?${queryString.stringify({
          sort: '-createdAt',
          'textFilter[notificationTypeName,shortText,text]': searchValue,
          'filter[notificationTypeID][in]': notificationType,
          //MAX-1129
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        })}&${queryString.stringify({})}`
      );
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.status;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getAllUnreadNotifications() {
    try {
      let response = await this.get(
        `/notifications?filter%5BisRemoved%5D%5Beq%5D=0&filter%5BisViewed%5D%5Beq%5D=0&sort=-createdAt%2CisViewed`
      );
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.status;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getNotificationTypes() {
    try {
      let response = await this.get('notificationTypes');
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.status;
      throw new ErrorWrapper(error, message);
    }
  }
}
