import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';
import { SyncLogService } from '../../../services/modules/syncLog.service';

export async function init(store, params) {
  try {
    await promiseAll([syncLog(store, {}), contactSyncAccountMail(store, params)]);
  } catch (e) {
    throw e;
  }
}

export async function syncLog({ commit, state }, params) {
  try {
    const { queryParams, ..._params } = { ...params } as any;
    const currentPage = _params.currentPage || 1;
    const rowsPerPage = _params.rowsPerPage || 35;
    const qParams = { ...state.syncLog.queryParams, ...queryParams };

    commit('setState', { syncLog: { ...state.syncLog, loading: true, queryParams: qParams } });

    const res = await SyncLogService.selectContactManagementLogList({
      ...qParams,
      // limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
    });
    const totalRows = res?.data?.info?.numRows || 0;
    commit('setState', {
      selected: [],
      syncLog: {
        ..._params,
        currentPage,
        rowsPerPage,
        loading: false,
        totalRows,
        totalPages: Math.ceil(totalRows / rowsPerPage),
        list: res?.data?.result || [],
        queryParams: qParams,
      },
    });
  } catch (e) {
    commit('setState', { syncLog: { ...state.syncLog, loading: false } });
  }
}

export async function contactSyncAccountMail({ commit, state }, params) {
  try {
    const { queryParams, ..._params } = { ...params } as any;
    const currentPage = _params.currentPage || 1;
    const rowsPerPage = _params.rowsPerPage || 35;
    const qParams = { ...state.syncLog.queryParams, ...queryParams };

    commit('setState', { syncLogEmailList: { ...state.syncLogEmailList, queryParams: qParams } });

    const res = await SyncLogService.contactSyncAccountMail({
      ...qParams,
      // limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
    });
    const totalRows = res?.data?.info?.numRows || 0;
    commit('setState', {
      selected: [],
      syncLogEmailList: res?.data?.result || [],
    });
  } catch (e) {
    commit('setState', { syncLog: { ...state.syncLog, loading: false } });
  }
}
