export const getDefaultState = () => ({
  syncLog: {
    list: [],
    email: [],
    loading: false,
    totalRows: 0,
    currentPage: 1,
    totalPages: 1,
    rowsPerPage: 35,
    queryParams: {
      //   sort: '-createdAt',
      'filter[contactSyncAccountID][in]': '',
      'filter[updateType][contains]': '',
      'textFilter[,email,updateType,contactSyncAccountID]': '',
    },
  },
  syncLogEmailList: [],
  syncLogView: {},
});
