import { promiseAll } from '@/utils/promiseAll';
import { getDefaultState } from './constants';
import { init, changeHistory, entities, group, groups, currentEntities, ungrouped } from './init';
import { OfficeGroupEditorService } from '../../../services/modules/officeGroupsEditor.service';

export default {
	namespaced: true,

	state: {
		savingGroup: false, 
	group: null,
	teamLeader: null,
	office: null,
	region: null,
	groups:{
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
	},
	entities: {
		list: [],
		loading: false,
		queryParams: {
			sort: 'name',
			only_active: '1',
		},
	},
	history: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: '-changeDate',
		},
	},
	offices: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: 'ID',
		},
	},
	},

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		init(store, groupID) { return init(store, groupID); },

		async groups({ commit, state }) {
			 try{
				 commit('setState', { groups: { ...state.groups, loading: true}, });
				 const res = await OfficeGroupEditorService.groups(`groups`);
				 commit('setState', {
					 selected: [],
					 groups: {
						 loading: false,
						 list: res?.data?.result || []
					 },
				 });
				 return res;
			 }catch(e) {
				 commit('setState', { groups: { ...state.groups, loading: false, }, });
				 throw e;
			 }
			},

			async offices({ commit, state }, officeID) {
				try{
					commit('setState', { offices: { ...state.offices, loading: true}, });
					const res = await OfficeGroupEditorService.offices(`office/${officeID}`);
					commit('setState', {
						selected: [],
						offices: {
							loading: false,
							list: res?.data?.result || []
						},
					});
					return res;
				}catch(e) {
					commit('setState', { offices: { ...state.offices, loading: false, }, });
					throw e;
				}
			   },

			async getEntities({ commit, state }, groupID) { 
				try{
					commit('setState', { currentEntities: { ...state.currentEntities, loading: true}, });
					const res = await OfficeGroupEditorService.getEntities(`group/${groupID}/entities`);
					commit('setState', {
						selected: [],
						currentEntities: {
							loading: false,
							list: res?.data?.result || []
						},
					});
				}catch(e) {
					commit('setState', { currentEntities: { ...state.currentEntities, loading: false, }, });
					throw e;
				} 
			},   

		group(store, _params) { 
			const { groupID, ...params } = { ..._params } as any;
			return group(store, groupID || store.state.group?.ID, params); 
		},

		entities(store, params) { return entities(store, params); },

		history(store, params) { return changeHistory(store, params); },

		ungrouped(store, params) { return ungrouped(store, params) },

		async save(store, { group }) {
			try {
				const id = store.state.group?.ID;
				const promises = [];
				if (id) {
					promises.push(OfficeGroupEditorService.put(`group/${id}`, group));
				} else {
					promises.push(OfficeGroupEditorService.post('groups', group));
				}
				
				store.commit('setState', { savingGroup: true, });

				const [saveGroupResponse] = await promiseAll(promises);

				if (id) await init(store, id);

				store.commit('setState', { savingGroup: false, });

				return { saveGroupResponse };
			} catch(e) { 
				store.commit('setState', { savingGroup: false, }); 
				throw e; 
			}
		}
	},
};
  