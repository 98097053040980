import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';

export async function init(store, params: any = {}) {	
	const { regionID, financeID } = params;
	try {
		const res = await promiseAll([
			BaseService.sessionPermissions(),
			BaseService.get(`region/${regionID}`),
			BaseService.get(`region/${regionID}/currencyPayment`),
			BaseService.get(`annualDuesFinance/${financeID}`),
			BaseService.get('regions/count'),		
		]);

		store.commit('setState', {
			initParams: params,
			session: res[0]?.data,
			region: res[1]?.data?.result,
			currencyPayment: res[2]?.data,
			annualDuesFinance: res[3]?.data,
		});
	} catch(e) { throw e; }
}
