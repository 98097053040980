import queryString from 'query-string';
import { BaseService } from '../base.service';
import { BusinessPlanService } from './businessPlan.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class RegionEditorService extends BaseService {
  static get entity() {
    return 'region';
  }

  static insertManagingCompanySnapshot(region_id, payload) {
    return this.post(`region/${region_id}/managingCompanySnapshot`, payload);
  }

  static copyFranchiseAgreementToNewSubregion(region_id, payload) {
    return this.post(`region/${region_id}/copyFranchiseAgreementToNewSubregion`, payload);
  }

  static updateManagingCompanySnapshot(id, payload) {
    return this.put(`managingCompanySnapshot/${id}`, payload);
  }

  static deleteManagingCompanySnapshot(id, payload) {
    return this._delete(`managingCompanySnapshot/${id}`, payload);
  }

  static deleteFeeRelief(id, payload) {
    return this._delete(`feeRelief/${id}`, payload);
  }

  static insertFeeRelief(id, payload) {
    //
    return this.post(`region/${id}/feeRelief`, payload);
  }

  static updateFeeRelief(id, payload) {
    //
    return this.put(`feeRelief/${id}`, payload);
  }

  static postFranchiseAgreement(region_id, payload) {
    //
    return this.post(`region/${region_id}/franchiseAgreement`, payload);
  }

  static async insertRegion(payload) {
    return this.post('regions', payload);
  }

  static async updateRegion(region_id, payload) {
    return this.put(`region/${region_id}`, payload);
  }

  static insertNote(entity, region_id) {
    return this.post(`v1/region/${region_id}/note`, entity);
  }

  static updateNote(entity, region_id) {
    return this.put(`v1/region/${region_id}/note/${entity.ID}`, entity);
  }

  static deleteNote(id) {
    return this._delete(`v1/region/note/${id}`);
  }

  static getRegion(region_id, params = {}) {
    return this.get(`region/${region_id}`, params);
  }

  static subregionsEnabled(region_id, params = {}) {
    return this.get(`region/${region_id}/subregionsEnabled`, params);
  }

  static subregionDropdown(region_id, params = {}) {
    return this.get(`region/${region_id}/subregionDropdown`, {
      sort: 'entryValue',
      ...params,
    });
  }

  static currencyReporting(region_id, params = {}) {
    return this.get(`region/${region_id}/currencyReporting`, params);
  }

  static currencyRfa(region_id, params = {}) {
    return this.get(`region/${region_id}/currencyRfa`, params);
  }

  static franchiseAgreement(region_id, params = {}) {
    return this.get(`region/${region_id}/franchiseAgreement`, params);
  }

  static currentDevelopmentScheduleSnapshot(region_id, params = {}) {
    return this.get(`region/${region_id}/currentDevelopmentScheduleSnapshot`, {
      sort: '-validFrom',
      ...params,
    });
  }

  static currentPerformanceScheduleSnapshot(region_id, params = {}) {
    return this.get(`region/${region_id}/currentPerformanceScheduleSnapshot`, {
      sort: '-validFrom',
      ...params,
    });
  }

  static subregionTags(region_id, params = {}) {
    return this.get(`region/${region_id}/subregionTags`, params);
  }

  static managingDirectors(region_id, params = {}) {
    return this.get(`region/${region_id}/managingDirectors`, params);
  }

  static managingCompanySnapshot(region_id, params = {}) {
    return this.get(`region/${region_id}/managingCompanySnapshot`, {
      sort: '-validFrom',
      ...params,
    });
  }

  static regionalPerson(region_id, params = {}) {
    return this.get(`region/${region_id}/regionalPerson`, {
      sort: 'lastName',
      only_active: 1,
      ...params,
    });
  }

  static feeRelief(region_id, params = {}) {
    return this.get(`region/${region_id}/feeRelief`, {
      sort: '-fromDate',
      only_active: 1,
      ...params,
    });
  }

  static currencyPayment(region_id, params = {}) {
    return this.get(`region/${region_id}/currencyPayment`, params);
  }

  static currencyLfa(region_id, params = {}) {
    return this.get(`region/${region_id}/currencyLfa`, params);
  }

  static developmentSchedule(region_id, params = {}) {
    return this.get(`region/${region_id}/developmentSchedule`, {
      sort: '-year',
      limit: 1,
      ...params,
    });
  }

  static performanceSchedule(region_id, params = {}) {
    return this.get(`region/${region_id}/performanceSchedule`, {
      sort: '-year',
      limit: 1,
      ...params,
    });
  }

  static businessPlanYears(region_id, params = {}) {
    return this.get(`region/${region_id}/businessPlanYears`, {
      sort: '-entryValue',
      ...params,
    });
  }

  static getNotes(region_id, params) {
    return this.get(`region/${region_id}/note`, {
      sort: '-noteDate',
      limit: '0,35',
      ...params,
    });
  }

  static history(region_id, params) {
    return this.get(`region/${region_id}/history`, {
      sort: '-changeDate',
      limit: '0,35',
      ...params,
    });
  }

  static businessPlan(region_id, params = {}) {
    return this.get(`region/${region_id}/businessPlan`, {
      sort: 'month',
      ...params,
    });
  }

  static async businessPlanV2(region_id, year){
    //this is where the call to the new backend module is made
    let data = {};
    let response = await this.request().get(`v1/region/businessPlan/getBusinessPlan?regionID=${region_id}&year=${year}`);
  
    //if data is returned without issues from service, calculate the data;
    if(response.data?.success){
      //console.log('got some data, calculating the business plan data')
      data = await BusinessPlanService.calculateBusinessPlan(response.data.data);
    }

    return data;

  }

  static async updateBusinessPlanV2(businessPlanV2, regionID){
    const payload = BusinessPlanService.formatValuesPreSave(businessPlanV2.originalData, businessPlanV2.sectionData, businessPlanV2.kpiData);
    //console.log('this is the payload:')?regionID=${id}
    //console.log(payload);
    return await this.request().put(`v1/region/businessPlan/updateBusinessPlan?regionID=${regionID}`,{data: payload});
  }

  static companySnapshots(company_id, params = {}) {
    return this.get(`company/${company_id}/snapshots`, {
      sort: '-validFrom',
      ...params,
    });
  }

  static managingCompanyBySnapshotID(snapshot_id, params = {}) {
    return this.get(`managingCompanySnapshot/${snapshot_id}/company`, {
      ...params,
    });
  }

  static countries(params) {
    return this.get(`countries`, {
      'filter[remaxEuropeCountry][eq]': '1',
      sort: 'entryValue',
      ...params,
    });
  }

  static nextSubcountryIDs(params) {
    return this.get(`countries/nextSubcountryIDs`, {
      ...params,
    });
  }

  static phonecodes(params) {
    return this.get(`countries/phonecodes`, {
      sort: 'entryValue',
      ...params,
    });
  }

  static countryStates(countryISO, params) {
    return this.get(`country/${countryISO}/states`, {
      sort: 'entryValue',
      ...params,
    });
  }

  static emailCommunicationTags(params) {
    return this.get(`emailCommunicationTags`, {
      sort: 'entryValue',
      ...params,
    });
  }

  static currencyDropdown(params) {
    return this.get(`currency/dropdown`, {
      sort: 'entryValue',
      ...params,
    });
  }

  static franchiseAgreementSegmentTypes(params) {
    return this.get(`franchiseAgreementSegment/types`, {
      ...params,
    });
  }

  static notesCategories(params) {
    return this.get(`notes/categories`, {
      sort: 'category',
      ...params,
    });
  }

  static companyShareholderSnapshot(id, params) {
    return this.get(`companyShareholderSnapshot/${id}`, {
      ...params,
    });
  }

  static getCompanies(params) {
    return this.get(`companies`, {
      ...params,
    });
  }

  // static async getDevSnapShotList(id) {
  //   try {
  //     const response = await this.request().get(`${this.entity}/${id}/developmentSchedule/snapshots?limit=0%2C10&sort=-validFrom`);
  //     return new ResponseWrapper(response, response.data.data);
  //   } catch (error) {
  //     const message = error.response.data ? error.response.data.error : error.response.statusText;
  //     throw new ErrorWrapper(error, message);
  //   }
  // }
  static getDevSnapShotList(id) {
    return this.get(`region/${id}/developmentSchedule/snapshots?sort=-validFrom`);
  }

  static getPerformanceSnapShotList(id) {
    return this.get(`region/${id}/performanceSchedule/snapshots?sort=-validFrom`);
  }

  static getDevelopmentScheduleBySnapshot(id) {
    return this.get(`developmentScheduleSnapshot/${id}`, { sort: '-validFrom' });
  }

  static getPerformanceScheduleBySnapshot(id) {
    return this.get(`performanceScheduleSnapshot/${id}`, { sort: '-validFrom' });
  }

  static saveDevelopmentSchedules(id, devSchedule) {
    return this.post(`region/${id}/developmentScheduleSnapshot`, devSchedule);
  }

  static savePerformanceSchedules(id, performanceSchedule) {
    return this.post(`region/${id}/performanceScheduleSnapshot`, performanceSchedule);
  }
  static getRolesForRegionDropDown() {
    return this.get(`roles/region?sort=remaxTitle`);
  }
  static insertRegionalPersons(id, entity) {
    return this.post(`region/${id}/regionalPerson`, entity);
  }
  static updateRegionalPersons(id, entity) {
    return this.put(`regionalPerson/${id}`, entity);
  }
  static changeTitleInOffice(id, entity) {
    return this.put(`relation/${id}/changeTitleInOffice`, entity);
  }
  static endTitleInOffice(id, entity) {
    return this.put(`relation/${id}/endRelation`, entity);
  }
  static isPrimaryTitle(id) {
    return this.get(`relation/${id}/isPrimaryTitle`);
  }
  static getPrimaryTitleList(id, relationID) {
    return this.get(`relation/${id}/isNotPrimaryTitle/${relationID}`);
  }
  static CheckReportingPeriodStatus(id, relationID) {
    return this.get(`region/${id}/reportingStatusForPeriod?reportingPeriod=${relationID}`);
  }

  static overrideOfficeContracts(id) {
    return this.post(`region/${id}/franchiseAgreementOverride`);
  }

  static saveAnnualDuesForAgreement(params) {
    // console.log(params);
    return this.post(`region/${params.ID}/saveAnnualDuesForAgreement`, params);
  }
}
