import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const office_status_changes: any = {
  cubeParameters: {
    recurring: true,
    filterParameter: 'TitleLevelPerformance.date',
    timeParameter: 'TitleLevelPerformance.date',
  },

  ExcelSheetsTitles: ['Open Offices', 'Info Changes', 'Name Changes'],
  ExcelSheets: [
    [
      TC.region_name_recurring,
      TC.officeIDRecurring,
      TC.officeUniqueIDRecurring,
      TC.officeNameRecurring,
      TC.openingDateRecurring,
    ],
    [
      TC.region_name_recurring,
      TC.officeNumberRecurring,
      TC.infoChangesUnique,
      TC.legacyIDRecurring,
      TC.officeNameRecurring,
      TC.prev_address,
      TC.new_address,
      TC.prev_email,
      TC.new_email,
      TC.prev_phone,
      TC.new_phone,
      TC.prev_fax,
      TC.new_fax,
      TC.prev_number,
      TC.other_number,
      TC.prev_website,
      TC.new_website,
    ],
    [
      TC.region_name_recurring,
      TC.officeNumberRecurring,
      TC.uniqueIDOfficeStatusChanges,
      TC.legacyIDRecurring,
      TC.old_office_name,
      TC.new_office_name,
    ],
  ],
  settingsPanel: panel,
};
