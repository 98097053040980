import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class ExchangeRatesService extends BaseService {
  static get entity() {
    return '';
  }

  static async exchangeRatesOverview({ currentPage, rowsPerPage, ...query })  {
    try {
      currentPage = currentPage || 1;
      rowsPerPage = rowsPerPage || 35;
      let response = await this.request().get(
        `currencyExchangeRatesOverview?${queryString.stringify({
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          sort: '-year,-month',
          ...query, 
        })}`
      );
      return new ResponseWrapper(response, response.data.result);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getExchangeRateByDay(year, month, isocurrency, { currentPage, rowsPerPage, ...query }) {
    try {
      currentPage = currentPage || 1;
      rowsPerPage = rowsPerPage || 35;
      
      let response = await this.request().get(
        `currencyExchangeRatesDetailsByMonth/${year}/${month}/${isocurrency}?${queryString.stringify({
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          ...query, 

        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }



  static async getExchangeRateByMonth(year, month, { currentPage, rowsPerPage, ...query }) {
    try {
      currentPage = currentPage || 1;
      rowsPerPage = rowsPerPage || 35;
      let response = await this.request().get(
        `currencyExchangeRatesByMonth/${year}/${month}?${queryString.stringify({
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          ...query, 

        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }


  static async getReportingRegionMonthsList(regionID, params) {
    try {
      let response = await this.request().get(
        `reportingRegionMonthsList/${regionID}?${queryString.stringify({
          ...params,
        })}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }


  static async getInternationalDollarList({ currentPage, rowsPerPage, ...query })  {
    try {
      currentPage = currentPage || 1;
      rowsPerPage = rowsPerPage || 35;
      let response = await this.request().get(
        `currencyExchangeRateRxdCountries?${queryString.stringify({
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          sort: 'countryName',
          ...query, 
        })}`
      );
      return new ResponseWrapper(response, response.data.result);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getInternationalDollarForRegionByYear(countryISO, { currentPage, rowsPerPage, ...query })  {
    try {
      currentPage = currentPage || 1;
      rowsPerPage = rowsPerPage || 35;
      let response = await this.request().get(
        `currencyExchangeRateRxdCountry/${countryISO}?${queryString.stringify({
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          //sort: 'countryName',
          ...query, 
        })}`
      );
      return new ResponseWrapper(response, response.data.result);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getFollowingYear() {
    try {
      let response = await this.request().get(
        `currencyExchangeRateRxdYears/followingYear`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

}



