import { promiseAll } from '@/utils/promiseAll';
import { OfficeEditorService } from '../../../services/modules/officeEditor.service';
import { BaseService } from '../../../services/base.service';
// import store from '@/store';

function getPaginated(state, res) {
    const totalRows = Number(res?.data?.info?.numRows || '0');
    const rowsPerPage = state?.rowsPerPage || 35;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const currentPage = (state?.currentPage || 1) > totalPages ? totalPages : (state?.currentPage || 1);
    return  {
        list: res?.data?.result || [],
        totalRows,
        rowsPerPage,
        currentPage,
        totalPages,
    };
}

export async function init(store, officeID) {
    const { state, commit } = store;

    if (officeID) {
        await getOffice(store, officeID);
        await store.dispatch('office/getOfficeTeamsTable', { officeID, isActive: true });
    } 

    const [
        sessionPermissions,
        pagePermissions,
        specializations,
        officeLevels,
        officeRankingLabels,        
        countries,
        phonecodes,
        notesCategories,
        contactInformationEntryTypes,
        phoneCategories,
        emailCategories,
        states,
    ] = await promiseAll([
        OfficeEditorService.sessionPermissions(),
        OfficeEditorService.pagePermissions(),
        OfficeEditorService.specializations({ 'textFilter[showInOffice]': '1' }),
        OfficeEditorService.officeLevels(),
        OfficeEditorService.officeRankingLabels(),        
        OfficeEditorService.countries(),
        OfficeEditorService.phonecodes(),
        OfficeEditorService.notesCategories(),
        OfficeEditorService.contactInformationEntryTypes(),
        OfficeEditorService.contactInformationEntryCategories({ 'textFilter[isPhoneType]': '1', }),
        OfficeEditorService.contactInformationEntryCategories({ 'textFilter[isEmailType]': '1', }),
        !state.office?.countryISO ? null : OfficeEditorService.countryStates(state.office?.countryISO),
    ]);

    commit('setState', {
        sessionPermissions: { ...state.sessionPermissions, ...sessionPermissions?.data },
        pagePermissions: pagePermissions?.data?.result || [],
        specializations: specializations?.data?.result || [],
        officeLevels: officeLevels?.data?.result || [],
        officeRankingLabels: officeRankingLabels?.data?.result || [],        
        countries: countries?.data?.result || [],
        phonecodes: phonecodes?.data?.result || [],
        notesCategories: notesCategories?.data?.result || [],
        contactInformationEntryTypes: contactInformationEntryTypes?.data?.result || [],
        phoneCategories: phoneCategories?.data?.result || [],
        emailCategories: emailCategories?.data?.result || [],
        states: states?.data?.result || [],
    });
}

// async function getYDTGCI(officeID) {
//     return await store.dispatch('cube/getOfficeYTDGCI', {
//         officeID: officeID
//     });
// }

export async function getOffice(store, officeID) {
    const { commit, state, } = store;

    officeID = officeID || store.state.office.ID;

    commit('setState', { loadingOffice: true, });

    const [
		officeRes, 
		franchiseAgreementRes, 
		snapshotsRes, 
		firstAgreementFee,
	] = await promiseAll([
        OfficeEditorService.office(officeID),
        OfficeEditorService.franchiseAgreement(officeID),
        OfficeEditorService.snapshots(officeID),
		OfficeEditorService.firstFranchiseAgreementFeeAmount(officeID),
    ]);

    const office = officeRes?.data?.result || officeRes?.data;
    // if (office && !office.gciSumYear) {
    //     let ytd = await getYDTGCI(office.ID);
    //     if (ytd) {
    //         office.gciSumYear = Number(ytd['PerformanceCube.gciForPeriodEur']);
    //     }
    // }
    const franchiseAgreement = franchiseAgreementRes?.data?.result;
    const snapshots = snapshotsRes?.data?.result || [];

    if (office) {
        const [
            persons,
            statistics,
            specialization,
            contactInformationEntry,
            currencyPayment,
            officeShareSnapshot,
            isFranchiseAgreementCreate,
            mainOffices,
            regionFranchiseAgreement,
            developmentScheduleSnapshots,
            notes,
            // history,
			history,
            groups,
            getLatestOfficeShareSnapshot,
            currencyLfa,
            currencyReporting,
            businessDevelopers,
            regionalPersonForFranchiseSalesRep,
            franchiseHistory,
            franchiseAgreementSegments,
			officeInfoRes,		
			regionInfoRes,	
        ] = await promiseAll([   
            OfficeEditorService.persons(officeID),
            OfficeEditorService.statistics(officeID),
            OfficeEditorService.specialization(officeID),
            OfficeEditorService.contactInformationEntry(officeID),
            OfficeEditorService.currencyPayment(officeID),            
            !snapshots[0]?.ID ? null : OfficeEditorService.officeShareSnapshot(snapshots[0]?.ID),
            OfficeEditorService.isFranchiseAgreementCreate(officeID),
            OfficeEditorService.mainOffices(officeID),
            OfficeEditorService.regionFranchiseAgreement(officeID),
            OfficeEditorService.developmentScheduleSnapshots(officeID),
            OfficeEditorService.notes(officeID, {
                limit: `${((state.notes?.currentPage || 1) - 1) * (state.notes?.rowsPerPage || 35)},${(state.notes?.rowsPerPage || 35)}`,
            }),
            // OfficeEditorService.history(officeID, {
            //     limit: `${((state.history?.currentPage || 1) - 1) * (state.history?.rowsPerPage || 35)},${(state.history?.rowsPerPage || 35)}`,
            // }),
			BaseService.get('v1/office/getHistory', { officeID }),
            OfficeEditorService.groups(office?.remaxEntityID),
            OfficeEditorService.getLatestOfficeShareSnapshot({ officeID }),
            OfficeEditorService.officeRegionCurrencyLfa(office?.regionID),
            OfficeEditorService.officeRegionCurrencyReporting(office?.regionID),
            OfficeEditorService.regionBusinessDevelopers(office?.regionID),
            OfficeEditorService.regionalPersonForFranchiseSalesRep(office?.regionID),
            OfficeEditorService.franchiseHistory(officeID),
            OfficeEditorService.franchiseAgreementSegments(officeID),
			BaseService.get('v1/office/getInfo', { officeID }),
			BaseService.get('v1/region/getInfo', { regionID: office.regionID }),
            getFranchiseAgreementData(store, franchiseAgreement?.ID),
        ]);

		const officeInfo = officeInfoRes?.data?.data;
		const regionInfo = regionInfoRes?.data?.data;

		const officeInfo_franchiseAgreement = (officeInfo?.franchiseAgreements || []).filter((_, i, arr) => arr.length - 1 === i)[0];

        commit('setState', {
            office: { 
				...state.office, 
				...office, 
				...officeInfo?.address, 
				street: office?.street || officeInfo?.address?.street || state.office?.street || null,
				houseNumber: office?.houseNumber || officeInfo?.address?.houseNumber || state.office?.houseNumber || null,
				additionalAddressInfo: office?.additionalAddressInfo || officeInfo?.address?.additionalAddressInfo || state.office?.additionalAddressInfo || null,
				city: office?.city || officeInfo?.address?.city || state.office?.city || null,
				zipCode: office?.zipcode || office?.zipCode || officeInfo?.address?.zipCode || state.office?.zipCode || null,
				zipcode: office?.zipcode || office?.zipCode || officeInfo?.address?.zipCode || state.office?.zipCode || null,
				countryISO: office?.countryISO || officeInfo?.address?.countryISO || state.office?.countryISO || null,
				latitude: office?.latitude || officeInfo?.address?.latitude || state.office?.latitude || null,
				longitude: office?.longitude || officeInfo?.address?.longitude || state.office?.longitude || null,
				latlongProcessed: office?.latlongProcessed || officeInfo?.address?.latlongProcessed || state.office?.latlongProcessed || null,
			},
            franchiseAgreement: { 
				...state.franchiseAgreement, 
				...franchiseAgreement, 
				...officeInfo_franchiseAgreement,
				officeFranchiseAgreementID: officeInfo_franchiseAgreement?.id,
			},
            persons: { ...state.persons, list: persons?.data?.result || [], },
            statistics: { ...state.statistics, ...statistics?.data?.result },
            specialization: specialization?.data?.result || [],
            contactInformationEntry: contactInformationEntry?.data?.result || [],
            currencyPayment: { ...state.currencyPayment, ...currencyPayment?.data?.result?.paymentCurrency },
            snapshots,
            officeShareSnapshot: { 
                ...state.officeShareSnapshot, 
                ...officeShareSnapshot?.data?.result,
                snapshotID: snapshots[0]?.ID || null,
            },
            isFranchiseAgreementCreate: isFranchiseAgreementCreate?.data?.result?.isFranchiseAgreementCreate || false,
            mainOffices: mainOffices?.data?.result || [],
            regionFranchiseAgreement: regionFranchiseAgreement?.data?.result || [],
            developmentScheduleSnapshots: developmentScheduleSnapshots?.data?.result || [],
            ...(!notes?.data?.result ? {} : { notes: getPaginated(store.state.notes, notes) }),
            // ...(!history?.data?.result ? {} : { history: getPaginated(store.state.history, history) }),
			history: { list: history?.data?.data || [] },
            groups: groups?.data?.result || [],
            latestOfficeShareSnapshot: getLatestOfficeShareSnapshot?.data?.snapshotID,
            currencyLfa: { ...state.currencyLfa, ...currencyLfa?.data },
            currencyReporting: { ...state.currencyReporting, ...currencyReporting?.data },
            businessDevelopers: businessDevelopers?.data?.result || [],
            regionalPersonForFranchiseSalesRep: regionalPersonForFranchiseSalesRep?.data?.result || [],
            franchiseHistory: franchiseHistory?.data?.result || [],
            franchiseAgreementSegments: franchiseAgreementSegments?.data?.result || [],
			officeInfo,
			regionInfo,
			...firstAgreementFee?.data,
        });
    }

    commit('setState', { loadingOffice: false, });
}

export async function getFranchiseAgreementData(store, franchiseAgreementID) {
    const { state, commit } = store;
    franchiseAgreementID = franchiseAgreementID || store.state.franchiseAgreement.ID;
    if (franchiseAgreementID) {
        const [
            franchiseAgreement_devScheduleSnapshot,
            franchiseAgreement_segmentHistory,
        ] = await promiseAll([
            OfficeEditorService.officeFranchiseAgreement_devScheduleSnapshot(franchiseAgreementID),            
            OfficeEditorService.officeFranchiseAgreement_segmentHistory(franchiseAgreementID),
        ]);

        commit('setState', {
            franchiseAgreement_devScheduleSnapshot:{
                ...state.franchiseAgreement_devScheduleSnapshot,
                ...franchiseAgreement_devScheduleSnapshot?.data?.result,
            },
            franchiseAgreement_segmentHistory: franchiseAgreement_segmentHistory?.data?.result?.segments || [],
        });
    }
}
