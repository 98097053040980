import qs from 'qs';
import queryString from 'query-string';

import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './util';
import store from '../store';
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export class BaseService {
  static get entity() {
    throw new Error('entity getter not defined');
  }
  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request() {
    return new Http();
  }

  static responseWrapper(...rest) {
    return new ResponseWrapper(...rest);
  }

  static errorWrapper(...rest) {
    return new ErrorWrapper(...rest);
  }

  static querystring(obj) {
    return qs.stringify(obj, {
      encode: false,
    });
  }

  static async makeApiCall(req) {
    try {
      const res = await req;
      store.commit('auth/setShouldAuthenticated', false);
      return res;
    } catch (error) {
      const shouldAuthenticate = !!JSON.stringify(error.response || '{}').match('"code":4004');
      store.commit('auth/setShouldAuthenticated', shouldAuthenticate);
      if (error?.response?.data?.message) {
        error.message = error.response.data.message;
      }
      throw error;
    }
  }

  static async get(url, params = {}, config = {}) {
    const { cache } = config;
    if (queryString.stringify({ ...params }) === '') {
      url = `${url}`;
    } else {
      url = `${url}?${queryString.stringify({ ...params })}`;
    }
    const res = (cache ? store.state.cache[url] : null) || (await this.makeApiCall(this.request().get(url)));
    if (cache) store.commit('cache/setState', { [url]: res });
    return res;
  }

  static async post(url, data = {}) {
    return this.makeApiCall(this.request().post(`${url}`, data));
  }

  static async put(url, data) {
    return this.makeApiCall(this.request().put(`${url}`, data));
  }

  static async delete(url, data) {
    return this.makeApiCall(this.request().delete(`${url}`, data));
  }

  static async _get(url, params = {}) {
    try {
      let response = await this.request().get(`${url}?${queryString.stringify({ ...params })}`);
      if (response.data.data === undefined) {
        return new ResponseWrapper(response, response.data.result);
      } else {
        return new ResponseWrapper(response, response.data.data);
      }
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async _post(url, data) {
    try {
      const response = await this.request().post(`${url}`, data);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async _put(url, data) {
    try {
      const response = await this.request().put(`${url}`, data);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async _delete(url) {
    try {
      const response = await this.request().delete(`${url}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async sessionPermissions(params) {
    const res = await this.get('permission/session', params, { cache: true });
    store.commit('common/setState', { session: { ...store.state.common?.session, ...res?.data } });
    return res;
  }

  static pagePermissions(params) {
    return this.get(`permission/pagePermissions`, { sort: 'entryValue', ...params }, { cache: true });
  }

  static checkRegionPermission(regionIDToCheck) {
    let params = {
      relationID: userInfo.activeRelationID,
      regionID: regionIDToCheck,
    };
    return this.get(`v1/checkUserRegionPermissions`, { ...params });
  }

  static getRegionIDByRelationID(relationID) {
    let params = {
      relationID: relationID,
    };
    return this.get(`v1/getRegionIDByRelationID`, { ...params });
  }

  static getLoginIDByPersonID(ID) {
    return this.get(`getLoginIDByPersonID/${ID}`);
  }

  static countries(params) {
    const res = this.get('countries', { sort: 'entryValue', ...params }, { cache: true });
    res.then((data) => store.commit('cache/setState', { countriesList: data?.data?.result || [] }));
    return res;
  }

  static countryStates(countryISO, params) {
    return this.get(`country/${countryISO || ''}/states`, { sort: 'entryValue', ...params }, { cache: true });
  }

  static phonecodes(params) {
    const res = this.get('countries/phonecodes', { sort: 'entryValue', ...params }, { cache: true });
    res.then((data) => store.commit('cache/setState', { phonecodesList: data?.data?.result || [] }));
    return res;
  }

  static phonecodesWithoutISO(params) {
    const res = this.get('countries/phonecodesWithoutISO', { sort: 'entryValue', ...params }, { cache: true });
    res.then((data) => store.commit('cache/setState', { phonecodesWithoutISOList: data?.data?.result || [] }));
    return res;
  }

  static languages(params) {
    return this.get('languages', { sort: 'entryValue', ...params }, { cache: true });
  }

  static contactInformationEntryCategories(params) {
    return this.get('contactInformationEntryCategories', { sort: 'entryValue', ...params }, { cache: true });
  }

  static contactInformationEntryTypes(params) {
    return this.get('contactInformationEntryTypes', { sort: 'entryValue', ...params }, { cache: true });
  }

  static specializations(params) {
    return this.get('specializations', { sort: 'entryValue', ...params }, { cache: true });
  }

  static notesCategories(params) {
    return this.get('notes/categories', { sort: 'entryValue', ...params }, { cache: true });
  }

  static billingInformation(params) {
    return this.get(`billingInformation`, { sort: 'name', ...params }, { cache: true });
  }

  static designations(params) {
    return this.get(`designations`, { sort: 'entryValue', ...params }, { cache: true });
  }

  static emailCommunicationTags(params) {
    return this.get(`emailCommunicationTags`, { sort: 'entryValue', ...params }, { cache: true });
  }

  static regionsDropdown(params) {
    return this.get(`regions/dropdown`, params);
  }

  static newestCurrencyExchangeRates(params) {
    return this.get(`newestCurrencyExchangeRates`, params);
  }

  static async getListPublic(parameters = {}) {
    const params = { ...parameters };

    try {
      const response = await this.request().get(`${this.entity}`, { params });
      const data = {
        content: response.data.data,
        total: Number(response.headers['x-total-count']),
      };

      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getByIdPublic(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getById(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getCountryById(id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}/countryISO`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getAll() {
    try {
      if (this.entity == 'office') {
        const response = await this.request().get(
          `${this.entity}s?limit=100&sort=name&filter%5BofficeIsActive%5D%5Bin%5D=1%2C4`
        );
        return new ResponseWrapper(response, response.data.data);
      } else {
        const response = await this.request().get(`${this.entity}s?limit=100&sort=name`);
        return new ResponseWrapper(response, response.data.data);
      }
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async create(data = {}) {
    try {
      const response = await this.request({ auth: true }).post(`${this.entity}s`, data);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      store.commit(`${this.entity}/SET_ERRORS`, error.response.data.errorData);
      throw new ErrorWrapper(error);
    }
  }

  static async update(id, data = {}) {
    try {
      const response = await this.request({ auth: true }).put(`${this.entity}/${id}`, data);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async remove(id) {
    try {
      const response = await this.request({ auth: true }).delete(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
