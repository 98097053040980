export const getDefaultState = () => ({
	initParams: {},
	
	person: null,

	company: null,

	contact: null,

	phonecodesWithoutISO: [],

	phonecodes: [],

	phoneCategories: [],

	emailCategories: [],

	contactInformationEntryTypes: [],

	countries: [],

	history: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: '-changeDate',
		},
	},
});
