import queryString from 'query-string';
import { BaseService } from '../base.service';

export class SystemAccess extends BaseService {
  static get entity() {
    return '';
  }
  static async getSystemAccessRoles(params) {
    try {
      let response = await this.get(
        `systemAccessRoles?${queryString.stringify({
          ...params,
        })}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async getUserPermissions(params) {
    try {
      let response = await this.get(
        `systemAccesses?${queryString.stringify({
          ...params,
        })}`
      );
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getAdminSysAccessTitles(ID) {
    try {
      let response = await this.get(`permission/person/${ID}/admin/relations`);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getAdminSysAccessAvailableEntities(ID) {
    try {
      let response = await this.get(`permission/role/${ID}/admin/available/entities`);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getSystemAccessTiers(params) {
    try {
      let response = await this.get(
        `permission/tiers?${queryString.stringify({
          ...params,
        })}`
      );
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getSystemPackagesByTierID(ID, params) {
    try {
      let response = await this.get(
        `/systemAccessPackages?${queryString.stringify({
          ...params,
        })}&filter[systemAccessPackageGroupID][get]=${ID}`
      );
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getSystemPackagesByID(ID, params) {
    try {
      let response = await this.get(
        `/systemAccessRole/${ID}?${queryString.stringify({
          ...params,
        })}`
      );
      return response;
    } catch (error) {
		throw error;
    }
  }

  static async getSystemAccessByID(ID, params) {
    try {
      let response = await this.get(`/systemAccess/${ID}`);
      return response;
    } catch (error) {
		throw error;
    }
  }

  static async getRolesDropdown() {
    try {
      let response = await this.get(`/systemAccessRoles`);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getSystemAccessAdminRoles(ID) {
    try {
      let response = await this.get(`/permission/roles/${ID}/admin`);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getRolesDropdownByID(ID) {
    try {
      let response = await this.get(`/permission/relation/${ID}/roles?sort=name`);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getTitlesDropdown(ID) {
    try {
      let response = await this.get(`/permission/person/${ID}/relations`);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async saveSystemRole(payload) {
    try {
      let response = await this.post(`/systemAccessRoles`, payload);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async getExceptRegions(ID) {
    try {
      let response = await this.get(`/systemAccessRoles/${ID}/exceptionRegions`);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async saveSystemAccess(payload) {
    try {
      let response = await this.post(`/systemAccesses`, payload);
      return response;
    } catch (error) {
		throw error;
    }
  }

  static async saveAdminAccess(payload) {
    try {
      let response = await this.post(`/person/${payload.personID}/createAdminTitle`, payload);
      return response;
    } catch (error) {
		throw error;
    }
  }

  static async updateSystemRole(ID, payload) {
    try {
      let response = await this.put(`/systemAccessRole/${ID}`, payload);
      return response;
    } catch (error) {
		throw error;
    }
  }

  static async updateSystemAccess(ID, payload) {
    try {
      let response = await this.put(`/systemAccess/${ID}`, payload);
      return response;
    } catch (error) {
		throw error;
    }
  }

  static async deleteSystemRole(id) {
    try {
      let response = await this.delete(`/systemAccessRole/${id}`);
      return response;
    } catch (error) {
		throw error;
    }
  }
  static async deleteSystemPermission(id) {
    try {
      let response = await this.delete(`/systemAccess/${id}`);
      return response;
    } catch (error) {
		throw error;
    }
  }
}
