export const getDefaultState = () => ({
	feeReliefs: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			filterRegionIDs: '',
			'filter[isActive][in]': 1,
			sort: 'campaignName',
		},
	},
	regions: [],
	feeReliefTypes: [
		{ label: 'Deferment', value: '1', },
		{ label: 'Fee Reduction', value: '0', },
	],
	applyTypes: [
		{ label: 'All members', value: '1', },
		{ label: 'New members only', value: '0', },
	],
});
