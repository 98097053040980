const _systemPermissions = [];
for (let i = 0; i < 10; i++) {
    _systemPermissions[i] = {
        id: i + 1,
        name: 'Exchange Rate Daily Read',
        key: 'CREATE_REPORT',
        type: 'Func',
        description: 'Can create new Reports from Template',
        created_at: '13.12.2018',
    };
}

export const systemPermissions = _systemPermissions;

export const systemPermissionsFilters = [];

export const currentSystemPermission = {
    id: 1,
    person: 'Pasi Aalto, FI1-P103664, Regional Director, RE/MAX Finland (Head Office)',
    title: 'Regional Director',
    role: null,
};
