export const getDefaultState = () => ({
	saving: false, 
	
	feeRelief: null,

	entryExists: false,

	regions: [],

	history: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: '-changeDate',
		},
	},
});
