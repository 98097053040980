export function getLastDayOfMonth(yearMonthDay) {
  const [year, month, day] = yearMonthDay.split('-');

  // Check if day is provided, otherwise set it as the last day of the month
  const targetDay = day ? day : new Date(year, month, 0).getDate();

  // Construct the Date object with the target year, month, and day
  const fullDate = new Date(year, month - 1, targetDay);

  const dateString = fullDate.toISOString().slice(0, 10);
  return targetDay;
}

export function normalizeDateFrom(date) {
  if (!date) return null;
  if (typeof date === 'object') return date.year + '-' + (date.month + 1) + '-' + '01';
  if (hasDash(date)) {
    return date;
  } else {
    return date + '-' + '01' + '-' + '01';
  }
}

export function normalizeDateTo(date) {
  if (!date) return null;
  if (typeof date === 'object') {
    const dateString = date.year + '-' + (date.month + 1);
    const lastDayOfMonth = getLastDayOfMonth(dateString);
    return date.year + '-' + (date.month + 1) + '-' + lastDayOfMonth;
  }
  if (hasDash(date)) {
    let splitDate = date.split('-', 2).slice(0, 2);
    const lastDayOfMonth = getLastDayOfMonth(date);
    return splitDate[0] + '-' + splitDate[1] + '-' + lastDayOfMonth;
  } else {
    return date + '-' + 12 + '-' + 31;
  }
}

function hasDash(inputString) {
  return inputString.toString().includes('-');
}

export function normalizeAgentResponse(response) {
  const normalizedRes = {
    regionid: response.region_id,
    region: response.region_name,
    subregionname: response.subregion_name,
    idperson: response.person_id,
    lastname: response.lastname,
    firstname: response.firstname,
    uniquepersonid: response.unique_person_id,
    internalpersonid: response.internal_person_id,
    remaxpersonid: response.remax_person_id,
    idllcperson: response.person_constituent_id,
    externalpersonid: response.external_person_id,
    title: response.title,
    gender: response.gender,
    fulllocalname: response.full_local_name,
    marketingname: response.marketing_name,
    suffixname: response.suffix_name,
    birthdate: response.birthdate,
    socialsecuritynumber: response.social_security_number,
    remaxcommencedate: response.remax_commence_date,
    addate: response.annual_dues_anniversary,
    awardsrankingsexcluded: response.awards_rankings_excluded,
    display_title: response.display_title,
    isactive: response.person_is_active,
    reportingcurrencyiso: response.reporting_currency_iso,
    tenuredays: response.tenure_days,
    reportingcurrencyname: response.reporting_currency_name,
    totaladdress: response.total_address,
    phone: response.main_phone_number,
    email: response.main_email_address,
    teamname: response.team_name,
    CurrentTeamStatus: response.team_status,
    teamStatusDate: response.team_status_date,
    teamid: response.team_id,
    highestcareeraward: response.highest_career_award,
    highestclubaward: response.highest_club_award,
    gciForPeriod: response.ytd_commission,
    offices_owned: response.offices_owned,
    offices_owned_unique_ids: response.offices_owned_unique_ids,
    careerGCI: response.lifetime_commission,
    issatelliteoffice: response.has_satellite_office,
    primarytitle: response.primary_title,
    officeconstituentid: response.office_constituent_id,
    officelegacyid: response.office_legacy_id,
    office_external_id: response.office_external_id,
    primaryofficename: response.main_office_name,
    officename: response.main_office_name,
    officeid: response.main_office_id,
    officeUniqueid: response.unique_office_id,
    team_leader_name: response.team_leader_name,
    primarytitlevalidfrom: response.primary_title_valid_from,
    primarytitlevalidto: response.primary_title_valid_to,
    terminationreason: response.termination_reason,
    specializationname: response.specialization,
  };
  return normalizedRes;
}

export function getYearsBetweenDates(startDate, endDate) {
  let startYear;
  let endYear;
  if (startDate.year && endDate.year) {
    startYear = startDate.year;
    endYear = endDate.year;
  } else {
    startYear = startDate.split('-')[0];
    endYear = endDate.split('-')[0];
  }
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  return years;
}

export function getMonthsDifferenceWithNames(startDateObj, endDateObj) {
  const months = [];
  let currentDate;
  let endDate;
  if (startDateObj.year && endDateObj.year) {
    currentDate = new Date(startDateObj.year, startDateObj.month, 1);
    endDate = new Date(endDateObj.year, endDateObj.month, 1);
  } else {
    currentDate = new Date(startDateObj);
    endDate = new Date(endDateObj);
  }

  while (currentDate <= endDate) {
    const monthName = currentDate.toLocaleString('default', { month: 'short' });
    const year = currentDate.getFullYear();
    months.push(`${monthName} ${year}`);

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
}

export function AddMissingMonthsToPivotArray(dataArray, endMonth) {
  // Find the last object in the array
  const lastObject = dataArray[dataArray.length - 1];

  // Create a Date object for December 31, 2023
  const endDate = new Date(endMonth);

  // Create an array of missing months
  const missingMonths = [];

  for (let month = 1; ; month++) {
    // Clone the last object and set the month accordingly
    const newObj = { ...lastObject };
    const nextMonth = new Date(endDate);
    nextMonth.setMonth(endDate.getMonth() + month);
    newObj['transactiondate.month'] = nextMonth;

    // Check if the next month exceeds the end date
    if (nextMonth > endDate) {
      break; // Break the loop if we exceed the end date
    }

    // Push the new object to the missingMonths array
    missingMonths.push(newObj);
  }

  // Concatenate the original data with the missing months
  const resultArray = dataArray.concat(missingMonths);

  // Print the result
  return resultArray;
}
