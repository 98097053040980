export const phoneNumbersCategories = [
    { value: '1', label: 'Business', },
    { value: '2', label: 'Company', },
    { value: '3', label: 'Fax', },
    { value: '4', label: 'Home', },
    { value: '5', label: 'Mobile', },
    { value: '6', label: 'Other', },
];

export const emailsCategories = [
    { value: '1', label: 'Business', },
    { value: '2', label: 'Company', },
    { value: '4', label: 'Home', },
    { value: '6', label: 'Other', },
];

export const otherContactInformationEntriesCategories = [
    { value: 6, label: 'Facebook', },
    { value: 5, label: 'Linkedin', },
    { value: 3, label: 'Twitter', },
    { value: 4, label: 'Website', },
    { value: 7, label: 'Other', },
];

export const regionsSelect = [
   
];

export const specialisationsSelect = [
    { value: '7', label: '(COL) Collections', },
    { value: '1', label: '(COMM) Commercial', },
    { value: '6', label: '(LET) Lettings', },
    { value: '2', label: '(RES) Residential', },
];
