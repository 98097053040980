export const getDefaultState = () => ({
	initParams: {},

	region: null,

	monthlyAnnualDuesBulkOp: {
		type: null,
		comment: '',
		selected: {},
	},

	annualDuesFinances: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: "-paymentDate"
		},
	},
});
