import moment from 'moment';
export function getMonthsOfYear(month) {
  let monthName;

  switch (month) {
    case 1:
      monthName = 'Jan';
      break;
    case 2:
      monthName = 'Feb';
      break;
    case 3:
      monthName = 'Mar';
      break;
    case 4:
      monthName = 'Apr';
      break;
    case 5:
      monthName = 'May';
      break;
    case 6:
      monthName = 'Jun';
      break;
    case 7:
      monthName = 'Jul';
      break;
    case 8:
      monthName = 'Aug';
      break;
    case 9:
      monthName = 'Sep';
      break;
    case 10:
      monthName = 'Oct';
      break;
    case 11:
      monthName = 'Nov';
      break;
    case 12:
      monthName = 'Dec';
      break;
    default:
      monthName = 'Invalid month number';
      break;
  }

  return monthName;
}

export function separateByMonth(data) {
  const result = {
    timeDimensions: [],
  };
  let dateType = /(\d{4})([/-])(\d{1,2})\2(\d{1,2})/;
  // Loop through each key in the data object
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const [date, field] = key.split(',');
      if (dateType.test(key)) {
        const month = new Date(date).toLocaleString('default', { month: 'short' });

        // Create the nested object for the month if it doesn't exist
        if (!result.timeDimensions[month]) {
          result.timeDimensions[month] = {};
        }
        result.timeDimensions[month][field] = data[key];
      } else {
        result[key] = data[key];
      }

      // Assign the field value to the nested object
    }
  }

  return result;
}
