export const getDefaultState = () => ({
	savingGroup: false, 
	
	group: null,

	teamLeader: null,

	office: null,

	region: null,

	groups:{
		list: [],
		loading: false,
	},

	ungrouped: {
		list: [],
		loading: false,
	},
	
	entities: {
		list: [],
		loading: false,
		queryParams: {
			sort: 'name',
			only_active: '1',
		},
	},

	currentEntities: {
		list: [],
		loading: false,
		// queryParams: {
		// 	sort: 'name',
		// 	only_active: '1',
		// },
	},

	history: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: '-changeDate',
		},
	},

	offices: {
		list: [],
		loading: false,
		queryParams: {
			sort: 'ID',
		},
	},
});
