import { getDefaultState } from './constants';
import { BaseService } from '../../../services/base.service';
import { init, annualDuesFinances } from './init';

export default {
	namespaced: true,

	state: {
		...getDefaultState(),
		defaultState: getDefaultState(),
	},

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		init(store, params) { return init(store, params); },

		annualDuesFinances(store, params) { return annualDuesFinances(store, params); },
	},
};
  