import { BaseService } from '../base.service';

export class AffiliateDashboardService extends BaseService {
  static get entity() {
    return 'affiliateDashboard';
  }

  static async getAgentCountData(params) {
    try {
      const response = await this.get(`v1/affiliate/getAgentCount?regionId=${params.regionID}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAgentProductivityData(params) {
    try {
      const response = await this.get(`v1/affiliate/getAgentProductivity?regionId=${params.regionID}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAverageTransactionsPerYearData(params) {
    try {
      const response = await this.get(`v1/affiliate/getAgentAverageTransactionsPerYear?regionId=${params.regionID}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAgentAverageTransactionsPerTenureGroupData(params) {
    try {
      const response = await this.get(`v1/affiliate/getAgentAverageTransactionsPerTenureGroup?regionId=${params.regionID}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAgentCommissionInsightData(params) {
    try {
      // const response = await this.get(
      //   `/v1/affiliate/getAgentCommissionInsights?gender=${params.gender}&officeId=${params.officeID}&regionId=${params.regionID}&ageCategory=${params.ageCategory}`
      // );
      const response = await this.post(`/v1/affiliate/getAgentCommissionInsights`, params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getAgentOverviewData(params) {
    try {
      const url = `/v1/affiliate/getAgentOverview?regionId=${params.regionID}`;
      const response = await this.get(
        `${params.searchCategory ? url+'&searchCategory='+params.searchCategory : url}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getOfficeTreeStructure(params) {
    try {
      const response = await this.get(
        `/v1/affiliate/getOfficeTreeStructure?regionId=${params.regionID}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
