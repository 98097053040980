import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class SearchService extends BaseService {
  static get entity () {
    return 'search'
  }

  static async persons(params = {}) {
    try {
      let response = await this.request().get(`${this.entity}/persons?${queryString.stringify(params)}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async companies(params = {}) {
    try {
      let response = await this.request().get(`${this.entity}/companies?${queryString.stringify(params)}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async offices(params = {}) {
    try {
      let response = await this.request().get(`${this.entity}/offices?${queryString.stringify(params)}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async teams(params = {}) {
    try {
      let response = await this.request().get(`${this.entity}/teams?${queryString.stringify(params)}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async groups(params = {}) {
    try {
      let response = await this.request().get(`${this.entity}/groups?${queryString.stringify(params)}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async regions(params = {}) {
    try {
      let response = await this.request().get(`${this.entity}/regions?${queryString.stringify(params)}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async contacts(params = {}) {
    try {
      let response = await this.request().get(`${this.entity}/contacts?${queryString.stringify(params)}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
