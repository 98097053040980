import { promiseAll } from '@/utils/promiseAll';
import { getDefaultState } from './constants';
import { BaseService } from '../../../services/base.service';
import { init } from './init';

export default {
	namespaced: true,

	state: {
		...getDefaultState(),
		defaultState: getDefaultState(),
	},

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		init(store, params) { return init(store, params); },

		async save(store, { annualDuesFinance }) {
			try {
				const annualDuesFinanceID = store.state.annualDuesFinance?.ID;
				const promises = [];
				if (annualDuesFinanceID) {
					promises.push(BaseService.put(`annualDuesFinance/${annualDuesFinanceID}`, annualDuesFinance));
				} else {
					promises.push(BaseService.post('annualDuesFinances', annualDuesFinance));
				}
				
				store.commit('setState', { savingAnnualDuesFinance: true, });

				const [saveAnnualDuesFinanceResponse] = await promiseAll(promises);

				if (annualDuesFinanceID) await init(store, store.state.initParams);

				store.commit('setState', { savingAnnualDuesFinance: false, });

				return { saveAnnualDuesFinanceResponse };
			} catch(e) { 
				store.commit('setState', { savingAnnualDuesFinance: false, }); 
				throw e; 
			}
		}
	},
};
  