import { promiseAll } from '@/utils/promiseAll';
import { ExchangeRateEditorService } from '../../../services/modules/exchangeRatesEditor.service';

export async function init(store, countryISO) {
	countryISO = countryISO || store.state.exchangeRates.list[0].countryISO;
	try {
		await promiseAll([
			exchangeRates(store, countryISO),
			changeHistory(store, {}, countryISO),
		]);
	} catch(e) { console.log(e); throw e; }
}

export async function exchangeRates({ commit, state }, countryISO?: any, params = {}) {
	try {
		countryISO = state.exchangeRates?.ID || countryISO;
		if (countryISO!==undefined) {
			try {
				// const { queryParams, ..._params } = { ...params, } as any;
				// const qParams = { ...state.exchangeRates.queryParams, ...queryParams, };

				commit('setState', { exchangeRates: { ...state.exchangeRates, loading: true }, });

				const res = await ExchangeRateEditorService.exchangeRates(countryISO);
				commit('setState', {
					exchangeRates: {
						loading: false,
						list: res?.data?.currencyExchangeRateRxds || [],
					},
				});
			} catch(e) { 
				commit('setState', { exchangeRates: { ...state.exchangeRates, loading: false, }, });
				throw e; 
			}
		}
	} catch(e) { console.log(e); /**/ }
}

export async function changeHistory({ commit, state }, params = {}, countryISO?: any) {	
	try {
		countryISO = state.exchangeRates?.ID || countryISO;
		if (countryISO) {
			const { queryParams, ..._params } = { ...params, } as any;
			const currentPage = _params.currentPage || 1;
			const rowsPerPage = _params.rowsPerPage || 35;
			const qParams = { ...state.history.queryParams, ...queryParams, };

			commit('setState', { history: { ...state.history, loading: true, queryParams: qParams, }, });

			const res = await ExchangeRateEditorService.history(countryISO, {
				...qParams,
				//MAX-1129
				//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
			});
			const totalRows = res?.data?.info?.numRows || 0;
			commit('setState', {
				history: {
					..._params,
					currentPage,
					rowsPerPage,
					loading: false,
					totalRows,
					totalPages: Math.ceil(totalRows / rowsPerPage),
					list: res?.data?.result || [],
					queryParams: qParams,
				},
			});
		}
	} catch(e) { 
		console.log(e);
		commit('setState', { history: { ...state.history, loading: false, }, });
		throw e; 
	}
}
