import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class GlobalSearchService extends BaseService {
  static get entity() {
    return 'search';
  }

  static async search(params) {
    try {
      let response = await this.request().get(`/${this.entity}/?value=${params}`);
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async searchByType(params) {
    const currentPage = params.currentPage || 1;
    const rowsPerPage = 10;
    try {
      let response = await this.request().get(
        `${this.entity}/${params.params.type}/?value=${params.params.value}&${queryString.stringify({
          //MAX-1129
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        })}`
      );
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async searchByContactType(params) {
    const currentPage = params.currentPage || 1;
    const rowsPerPage = 10;
    try {
      console.log('service');
      let response = await this.request().get(
        `${this.entity}/${params.params.type}/?value=${params.params.value}&${queryString.stringify({
          //MAX-1129
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          type: params.params.contact,
        })}`
      );
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
