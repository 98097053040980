import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';

export async function init(store, params) {
	try {
		const res = await promiseAll([
			personAwards(store, {}),
			awardsHistory(store, {}),
			BaseService.get('regions', { sort: 'name', }),
			BaseService.get('awards/dropdown'),
			BaseService.get('awards', { sort: 'awardType,commission', }),
		]);
		store.commit('setState', {
			regions: res[2]?.data?.result || [],
			awardsDropdown: Object.values(res[3]?.data?.result || {}),
			awardTypes: res[4]?.data?.result || [],
		});
	} catch(e) { throw e; }
}

export async function personAwards({ commit, state }, params) {
	try {
		const { queryParams, ..._params } = { ...params, } as any;
		const currentPage = _params.currentPage || 1;
		const rowsPerPage = _params.rowsPerPage || 35;
		const qParams = { ...state.personAwards.queryParams, ...queryParams, };

		commit('setState', { personAwards: { ...state.personAwards, loading: true, queryParams: qParams, }, });

		const res = await BaseService.get('personAwards', {
			...qParams,
			//MAX-1129
			//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
		});
		const totalRows = res?.data?.info?.numRows || 0;
		commit('setState', {
			selected: [],
			personAwards: {
				..._params,
				currentPage,
				rowsPerPage,
				loading: false,
				totalRows,
				totalPages: Math.ceil(totalRows / rowsPerPage),
				list: res?.data?.result || [],
				queryParams: qParams,
			},
		});
	} catch(e) { 
		commit('setState', { personAwards: { ...state.personAwards, loading: false, }, });
		throw e; 
	}
}

export async function awardsHistory({ commit, state }, params) {
	try {
		commit('setState', { awardsHistory: { ...state.awardsHistory, loading: true, }, });
		const { queryParams, ..._params } = { ...params, } as any;
		const currentPage = _params.currentPage || 1;
		const rowsPerPage = _params.rowsPerPage || 35;
		const qParams = { ...state.awardsHistory.queryParams, ...queryParams, };
		const res = await BaseService.get('awards/history', {
			...qParams,
			//MAX-1129
			//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
		});
		const totalRows = res?.data?.info?.numRows || 0;
		commit('setState', {
			awardsHistory: {
				..._params,
				currentPage,
				rowsPerPage,
				loading: false,
				totalRows,
				totalPages: Math.ceil(totalRows / rowsPerPage),
				list: res?.data?.result || [],
				queryParams: qParams,
			},
		});
	} catch(e) { 
		commit('setState', { awardsHistory: { ...state.awardsHistory, loading: false, }, });
		throw e; 
	}
}
