export const getDefaultState = () => ({
	teams: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: 'name',
			'filter[countActiveTeamMembers][get]': '1',
		},
	},
});
