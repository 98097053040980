import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class AnnualDuesService extends BaseService {
  static get entity() {
    return 'annualDues';
  }

  static async getReports({ currentPage, rowsPerPage, ...query }) {
    currentPage = currentPage || 1;
    rowsPerPage = rowsPerPage || 35;
    try {
      let response = await this.request().get(`personAnnualDues?${queryString.stringify({ 
        sort: 'displayName',
        ...query, 
      })}`);
      return new ResponseWrapper(response, response.data.result);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getMonthlyReports({ currentPage, rowsPerPage, ...query }) {
    currentPage = currentPage || 1;
    rowsPerPage = rowsPerPage || 12;
    try {
      let region_id = query.regionID;
      const response = await this.request().get(`region/${region_id}/monthlyAnnualDuesOverview?${queryString.stringify({ 
        sort: '-reportDate',
        //MAX-1129
        //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        ...query,
      })}`);
      return new ResponseWrapper(response, response.data.result);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

}
