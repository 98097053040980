import { getDefaultState } from './constants';
import { BaseService } from '../../../services/base.service';
import { promiseAll } from '@/utils/promiseAll';

async function getList({ commit }, opts: any = {}) {
  const userID = JSON.parse(localStorage.getItem('userInfo') || '{}')?.ID;
  const currentPage = opts.page || 1;
  const rowsPerPage = opts.limit || 20;
  try {
    const savedForLater = await BaseService.get(`v1/contractManagement/getAllSavedFranchiseAgreementsNotSubmitted`, {
      userID,
      limit: rowsPerPage,
      offset: rowsPerPage * (currentPage - 1),
    });

    const parseItem = (item) => {
      try {
        const parsed = {
          ...item,
          application_data: JSON.parse(item.application_data || '{}'),
          // declined_reasons: JSON.parse(item.declined_reasons || '{}'),
        };
        return parsed;
      } catch (e) {
        console.log(`%c${e.message}`, 'color: red');
        console.log('%cERROR: Failed to parse item.application_data JSON', 'color: red', item);
        console.log('%c***********************************************************************', 'color:#ccc');
        return null;
      }
    };

    const data = (savedForLater?.data?.data?.resultsFound || []).map((item) => parseItem(item)).filter((item) => item);
    const paginationInfo = savedForLater?.data?.data?.paginationInfo;
    const pagination = {
      currentPage,
      rowsPerPage,
      totalPages: paginationInfo?.pageCount || 1,
      totalRows: paginationInfo?.totalResultsFound || 0,
    };

    commit('setState', {
      list: {
        ...getDefaultState().list,
        data,
        loading: false,
        filters: {},
        ...pagination,
      },
    });
  } catch (e) {
    /**/
  }
}

async function search({ commit }, opts: any = {}) {
  const currentPage = opts.page || 1;
  const rowsPerPage = opts.limit || 20;
  try {
    const searchRes = await BaseService.get(`v1/contractManagement/franchiseApplications/savedForLaterLiveSearch`, {
      phrase: opts.search,
      limit: rowsPerPage,
      offset: rowsPerPage * (currentPage - 1),
    });

    const parseItem = (item) => {
      try {
        const parsed = {
          ...item,
          application_data: JSON.parse(item.application_data || '{}'),
          // declined_reasons: JSON.parse(item.declined_reasons || '{}'),
        };
        return parsed;
      } catch (e) {
        console.log(`%c${e.message}`, 'color: red');
        console.log('%cERROR: Failed to parse item.application_data JSON', 'color: red', item);
        console.log('%c***********************************************************************', 'color:#ccc');
        return null;
      }
    };

    const data = (searchRes?.data?.data?.resultsFound || []).map((item) => parseItem(item)).filter((item) => item);
    const paginationInfo = searchRes?.data?.data?.paginationInfo;
    const pagination = {
      currentPage,
      rowsPerPage,
      totalPages: paginationInfo?.pageCount || 1,
      totalRows: paginationInfo?.totalResultsFound || 0,
    };

    commit('setState', {
      list: {
        ...getDefaultState().list,
        data,
        loading: false,
        filters: {},
        ...pagination,
      },
    });
  } catch (e) {
    /**/
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },

  actions: {
    async init(store, opts: any = {}) {
      const { commit } = store;
      try {
        const [specializationsRes] = await promiseAll([
          BaseService.specializations({ 'textFilter[showInOffice]': '1' }),
          getList(store, opts),
        ]);

        const specializations = specializationsRes?.data?.result || [];

        commit('setState', {
          specializations: [specializations[3], specializations[0], specializations[1]].filter((o) => o),
        });
      } catch (e) {
        /**/
      }
    },

    async getList(store, opts: any = {}) {
      if (opts.search) return await search(store, opts);
      return await getList(store, opts);
    },
  },
};
