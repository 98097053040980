import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const regional_person_office_count: any = {
  cubeParameters: {
    measures: ['AgentRegionCountCube.numberOfAgents'],
    dimensions: ['AgentRegionCountCube.regionid', 'RegionCube.region'],
    mainCubeParameter: 'AgentRegionCountCube',
    filterParameter: 'AgentRegionCountCube.agentcountdate',
    timeParameter: 'AgentRegionCountCube.agentcountdate',
    singleDate: true,
  },
  alternativeCubeParameters: {
    measures: ['OfficeCountCube.numberOfOffices'],
    dimensions: ['OfficeCountCube.regionid'],
    mainCubeParameter: 'OfficeCountCube',
    filterParameter: 'OfficeCountCube.officecountdate',
    timeParameter: 'OfficeCountCube.officecountdate',
    singleDate: true,
  },

  columns: [TC.region, TC.personGroup, TC.allOfficesGroup],
  settingsPanel: panel,
};
