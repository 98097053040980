import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const regional_performance_aggregated_optimized: any = {
  cubeParameters: {
    measures: [
      'PerformanceCube.gciForPeriod',
      'PerformanceCube.transactionForPeriod',
      'PerformanceCube.volumeForPeriod',
      'PerformanceCube.gciForPeriodRxd',
      'PerformanceCube.gciForPeriodEur',
      'PerformanceCube.volumeForPeriodRxd',
      'PerformanceCube.volumeForPeriodEur',
    ],
    dimensions: ['PerformanceCube.regionid', 'RegionCube.reportingcurrency', 'RegionCube.region'],
    mainCubeParameter: 'PerformanceCube',
    order: [['RegionCube.region', 'asc']],
    filterParameter: 'PerformanceCube.regionid',
    timeParameter: 'PerformanceCube.transactiondate',
  },
  alternativeCubeParameters: {
    measures: ['billableTitleChanges.newAgentCount', 'billableTitleChanges.terminationAgentCount'],
    dimensions: ['billableTitleChanges.regionid'],
    order: [['RegionCube.region', 'asc']],
    mainCubeParameter: 'billableTitleChanges',
    filterParameter: 'billableTitleChanges.regionid',
    timeParameter: 'billableTitleChanges.recruitingdate',
  },
  columns: [
    TC.region,
    TC.billableTitleChangesNewAgents,
    TC.TitleBillableChangesterminationsCount,
    TC.gciPeriodStandard,
    TC.transactionsPeriodStandard,
    TC.volumePeriodStandard,
  ],
  templateType: 'cube',
  settingsPanel: panel,
};
