import { BaseService } from '@/services/base.service';
import { promiseAll } from '@/utils/promiseAll';

export const getDefaultState = () => ({
  reportTemplateSections: [],
  reportTemplateTags: [],
  loadingReports: false,
  reports: {
    reportTemplateID: null,
    data: [],
  },
  unEditableTemplatesIDs: [25, 35, 45, 48, 49],
});

export async function getReports(store, opts: any = {}) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  store.commit('setState', { loadingReports: true, reports: getDefaultState().reports });

  const res = await BaseService.get('reports', {
    'textFilter[reportTemplateID]': opts.reportTemplateID,
    sort: '-createdAt', //'title',
    person_id: userInfo.personID,
    only_own: opts.only_own,
  });

  store.commit('setState', {
    loadingReports: false,
    reports: {
      reportTemplateID: opts.reportTemplateID,
      data: res.data.result.sort((a, b) => b.title - a.title) || [],
    },
  });
}

export async function init(store) {
  const [reportTemplateSectionsRes] = await promiseAll([BaseService.get('reportTemplateSections')]);

  const excludeSections = [2];
  const excludeTemplates = [
    'agents_recurring',
    'monthly_recruiting',
    'offices_recurring',
    'person_status_changes',
    'recruiting',
    // 'agent_performance_periodical_combined_optimized',
    // 'agent_performance_periodical_per_office_optimized',
    // 'office_performance_periodical_optimized',
    // 'regional_office_counts_periodical',
    // 'regional_performance_periodical_optimized',
  ];

  const fullReportTemplateSections = await promiseAll(
    (reportTemplateSectionsRes?.data?.result || []).map((s) => BaseService.get(`reportTemplateSection/${s.ID}`))
  );
  const reportTemplateSections = fullReportTemplateSections
    .map((res) => res?.data?.result)
    .filter((data) => data)
    .filter((s) => !excludeSections.includes(s.ID))
    .map((s) => ({
      ...s,
      reportTemplates: (s.reportTemplates || []).filter((t) => !excludeTemplates.includes(t.templateKey)),
    }));

  const reportTemplateTags = Object.keys(
    reportTemplateSections
      .reduce((acc, s) => [...acc, ...s.reportTemplates], [])
      .reduce((acc, t) => {
        const tags = `${t.tags || ''}`.split(',');
        tags.forEach((tag) => {
          acc[tag] = true;
        });
        return acc;
      }, {})
  );

  store.commit('setState', {
    reportTemplateSections,
    reportTemplateTags,
  });
}
