import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class SyncLogService extends BaseService {
  static get entity() {
    return 'contactManagementSyncLogs';
  }

  static async selectContactManagementLogList({ currentPage, rowsPerPage, ...query }) {
    currentPage = currentPage || 1;
    rowsPerPage = rowsPerPage || 35;
    try {
      let response = await this.request().get(
        `/${this.entity}?${queryString.stringify({
          //MAX-1129
          //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
          sort: '-createdAt',
          ...query,
        })}`
      );
      return new ResponseWrapper(response, response.data.result);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async selectContactManagementSyncLogsByTokenAndType(token, id, type) {
    try {
      let response = await this.request().get(`/${this.entity}/${token}/${type}/${id}`);
      // console.log(response);
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async contactSyncAccountMail(params) {
    try {
      let response = await this.request().get(`${this.entity}/contactSyncAccountMail?${params}`);
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.message.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
