import { getDefaultState } from './constants';
import { BaseService } from '../../../services/base.service';
import { promiseAll } from '@/utils/promiseAll';
import { getSystemAccess } from '@/utils/systemAccess';

async function getList({ commit }, opts: any = {}) {
  const userID = JSON.parse(localStorage.getItem('userInfo') || '{}')?.ID;
  const currentPage = opts.page || 1;
  const rowsPerPage = opts.limit || 20;
  const status = `${opts.status || -1}`;
  const reqParams = {
    userID,
    limit: rowsPerPage,
    offset: rowsPerPage * (currentPage - 1),
  };

  let res = null;

  if (status === '-1') res = await BaseService.get(`v1/contractManagement/feeApproval/getAllPendingRequests`, reqParams);
  if (status === '1') res = await BaseService.get(`v1/contractManagement/feeApproval/getAllApprovedRequests`, reqParams);
  if (status === '0') res = await BaseService.get(`v1/contractManagement/feeApproval/getAllRejectedRequests`, reqParams);

  const data = (res?.data?.data?.resultsFound || [])
    .map((item) => ({
      ...item,
      application_data: JSON.parse(item.application_data || '{}'),
      declined_reasons: JSON.parse(item.declined_reasons || '{}'),
    }))
    .filter((item) => item);
  const paginationInfo = res?.data?.data?.paginationInfo;
  const pagination = {
    currentPage,
    rowsPerPage,
    totalPages: paginationInfo?.pageCount || 1,
    totalRows: paginationInfo?.totalResultsFound || 0,
  };

  commit('setState', {
    list: {
      ...getDefaultState().list,
      data,
      loading: false,
      filters: {},
      ...pagination,
    },
  });
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },
  },

  actions: {
    async init(store, opts) {
      const { commit } = store;

      // const user = JSON.parse(localStorage.getItem('userInfo') || '{}');
      try {
        const [region, regionalPersons, regionInfo, systemAccess] = await promiseAll([
          BaseService.get(`region/${opts?.regionID}`),
          BaseService.get(`region/${opts?.regionID}/regionalPerson`, { only_active: '1' }),
          BaseService.get('v1/region/getInfo', { regionID: opts?.regionID }),
          getSystemAccess(),
          getList(store, opts),
          // BaseService.get(`systemAccessesByRelationID/${user?.activeRelationID}`),
        ]);

        commit('setState', {
          region: region?.data?.result || null,
          regionalPersons: regionalPersons?.data?.result,
          regionalFranchiseAgreement: regionInfo?.data?.data?.regionalFranchiseAgreement,
          systemAccess,
        });
      } catch (e) {
        /**/
      }
    },

    async getList(store, opts: any = {}) {
      return await getList(store, opts);
    },
  },
};
