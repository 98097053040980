import { promiseAll } from '@/utils/promiseAll';
import { CubeService } from '@/services/modules/cube.service';
import { BaseService } from '@/services/base.service';
import { personToSelectOption } from '@/utils/personToSelectOption';
import { companyToSelectOption } from '@/utils/companyToSelectOption';
import { exchangeFeeSegmentFees } from '@/utils/cm';
import { exchangeCurrency } from '@/utils/currency';

export const getDefaultState = () => ({
  contractLog: null,

  savedForLater: null,

  feeApproval: null,

  documentCategories: [],

  paymentCurrency: {
    iso: 'EUR',
    name: 'Euro',
  },

  myRegion: null,

  region: null,

  currentRegionID: null,

  officeDetails: {},

  loadingOfficeDetails: false,

  loadingRegion: false,

  regions: [],

  specializations: [],

  countries: [],

  languages: [],

  states: {},

  applicationChangeHistory: [],

  applicationTypes: [
    {
      label: 'Start up Sales',
      value: 'startUpSales',
      description: 'New sale to a prospect (incl. any & RE/MAX agents) who is not operating a real estate office.',
    },
    {
      label: 'Expansion Sale',
      value: 'expansionSale',
      description: 'Existing RE/MAX Franchisee opening another office.',
    },
    {
      label: 'Satellite',
      value: 'satellite',
      description:
        'The office may not function on its own and shall be connected and report through a main office of the same Franchisee.\n*Satellite structure is subject to RE/MAX Europe approval',
    },
    {
      label: 'Conversion',
      value: 'conversion',
      // description: 'Existing real estate brokerage converted to RE/MAX',
      description: 'Operating real estate office converted to RE/MAX with at least 1 person and existing commissions.',
    },
  ],

  franchiseeTypes: [
    { label: 'Franchisee is an individual', value: '1' },
    { label: 'Franchisee is a legal entity', value: '2' },
  ],

  shareholderTypes: [
    { label: 'Active Partner', value: '1' },
    { label: 'Silent Partner', value: '2' },
  ],

  history: {
    list: [],
    loading: false,
    totalRows: 0,
    currentPage: 1,
    totalPages: 1,
    rowsPerPage: 35,
    queryParams: {
      sort: '-changeDate',
    },
  },

  systemAccess: {
    ID: null,
    createdAt: null,
    modifiedAt: null,
    personFullDisplayName: null,
    personID: null,
    personName: null,
    relationID: null,
    relationRegionID: null,
    remaxTitle: null,
    roleSystemAccessTierID: null,
    systemAccessPackages: [],
    systemAccessRoleID: null,
    systemAccessRoleName: null,
    systemAccessTierID: null,
    uniquePersonID: null,
  },

  exchangeRates: [],
});

async function systemAccess({ commit }) {
  try {
    const user = JSON.parse(localStorage.userInfo);
    const systemAccessesRes = await BaseService.get('systemAccesses');
    const systemAccessesItem = (systemAccessesRes?.data?.result || []).filter((a) => a.personID === user?.personID)[0];
    const systemAccessRes = await BaseService.get(`systemAccess/${systemAccessesItem?.ID}`);
    const systemAccess = systemAccessRes?.data?.result || getDefaultState().systemAccess;
    commit('setState', { systemAccess });
    return systemAccess;
  } catch (e) {
    // alert(e.message);
  }
}

export async function init(store, { contract_id, contractLogID, authenticatedUserRegionID }) {
  const { commit } = store;
  try {
    const exchangeRatesRes = await BaseService.get('newestCurrencyExchangeRates');
    const exchangeRates = exchangeRatesRes?.data?.result || [];
    commit('setState', { exchangeRates });

    const res = await promiseAll([
      BaseService.get('regions', { sort: 'name' }),
      BaseService.specializations({ 'textFilter[showInOffice]': '1' }),
      // BaseService.countries(),
      BaseService.get('v1/getAllEuropeanCountries'),
      BaseService.languages(),
      BaseService.get('v1/contractManagement/constants/contractDocumentCategories'),
      BaseService.phonecodes(),
      !contract_id ? null : BaseService.get('v1/contractManagement/getFranchiseApplication', { applicationID: contract_id }),
      !contractLogID ? null : contractLog(store, { contractLogID }),
      systemAccess(store),
      // authenticated user's region is the default region
      !authenticatedUserRegionID ? null : region(store, { regionID: authenticatedUserRegionID, subRegionID: null }),
    ]);

    const _savedForLater = res[6]?.data?.data;
    let savedForLater = !_savedForLater
      ? null
      : {
          ..._savedForLater,
          application_data: JSON.parse(_savedForLater.applicationData),
          declined_reasons: JSON.parse(_savedForLater.applicationData),
        };

    // get the application's region and if it doesn't exist, we use the authenticated user's region
    let regionData = res[9];
    if (savedForLater) {
      if (savedForLater.application_data?.regionID) {
        try {
          regionData = await region(store, {
            regionID: savedForLater?.application_data?.regionID,
            subRegionID: savedForLater?.application_data?.subRegionID,
          });
        } catch (e) {
          /**/
        }
      }
    }

    const feeApprovalRequestID = savedForLater?.application_data?.franchiseAgreement?.feeApprovalRequestID;
    let feeApproval = null;

    if (feeApprovalRequestID) {
      try {
        const feeApprovalRes = await BaseService.get('v1/contractManagement/feeApproval/getRequest', {
          feeApprovalRequestID,
        });
        feeApproval = feeApprovalRes?.data?.data;
      } catch (e) {
        /**/
      }
    }
    const specializations = res[1]?.data?.result || [];

    if (savedForLater?.application_data?.regionID) {
      await region(store, {
        regionID: savedForLater?.application_data?.regionID,
        subRegionID: savedForLater?.application_data?.subRegionID,
      });
    }

    if (savedForLater?.application_data?.office.officeID)
      await officeDetails(store, savedForLater?.application_data?.office.officeID);

    const countries = (res[2]?.data?.data || []).map((c) => ({
      ...c,
      entryKey: c.iso,
      entryValue: c.name,
      value: c.iso,
      label: c.name,
    }));
    const phonecodes = (res[5]?.data?.result || []).filter((c) => countries.map((c) => c.entryKey).includes(c.entryKey));

    commit('setState', {
      regions: res[0]?.data?.result || [],
      // specializations: [specializations[3], specializations[0], specializations[1]].filter(o => o),
      specializations: [
        {
          entryKey: 'Res',
          entryValue: '(RES) Residential',
          id: 2,
        },
        {
          entryKey: 'Col',
          entryValue: '(COL) Collections',
          id: 7,
        },
        {
          entryKey: 'Comm',
          entryValue: '(COMM) Commercial',
          id: 1,
        },
      ],
      countries,
      languages: res[3]?.data?.result || [],
      documentCategories: res[4]?.data?.data || [],
      phonecodes: phonecodes.map((c) => {
        const country = countries.filter((cn) => cn.entryKey === c.entryKey)[0];
        return {
          value: c.entryKey,
          label: `(${c.entryKey}) ${country?.entryValue || c.entryValue}`,
        };
      }),
      feeApproval,
      savedForLater,
      exchangeRates,
      applicationChangeHistory: (res[6]?.data?.data?.changeHistoryEntries || []).map((e) => {
        const metaData: any = [];
        const setMetaValue = () => {
          const metaValue = { key: e.field, old: e.oldValue, new: e.newValue };
          try {
            const old = JSON.parse(e.oldValue);
            const newV = JSON.parse(e.newValue);
            if (newV?.forEach) {
              newV.forEach((v) => {
                Object.keys(v).forEach((key, i) => {
                  if (key === 'person') {
                    if (v[key]) v[key] = personToSelectOption(v[key])?.labelNoHTML;
                    if (old[i] && old[i][key]) old[i][key] = personToSelectOption(old[i][key])?.labelNoHTML;
                  }
                  if (key === 'company') {
                    if (v[key]) v[key] = companyToSelectOption(v[key])?.labelNoHTML;
                    if (old[i] && old[i][key]) old[i][key] = companyToSelectOption(old[i][key])?.labelNoHTML;
                  }
                  const ignore = v[key] && typeof v[key] === 'object';
                  if (!ignore && v[key] !== (old[i] && old[i][key])) {
                    metaData.push({ key, new: v[key], old: old[i] ? old[i][key] : null });
                  }
                });
              });
            } else {
              metaData.push(metaValue);
            }
          } catch (e) {
            metaData.push(metaValue);
          }
        };
        if (e.oldValue || e.newValue) setMetaValue();
        return {
          ...e,
          changeDate: e.createdAt,
          humanChangeDate: e.createdAt,
          metaData: metaData.length ? JSON.stringify(metaData) : null,
        };
      }),
    });
    return JSON.parse(JSON.stringify(store.state));
  } catch (e) {
    throw e;
  }
}

export async function contractLog(store, opts: any = {}) {
  const { commit, state } = store;
  try {
    const contractLogID = opts?.contractLogID || state.contractLog?.id;
    const res = await promiseAll([
      !contractLogID ? null : BaseService.get('v1/contractManagement/contractLog/getInfo', { id: contractLogID }),
    ]);

    commit('setState', {
      contractLog: res[0]?.data?.data,
    });
  } catch (e) {
    throw e;
  }
}

export async function region({ state, commit }, { regionID, subRegionID }) {
  try {
    // if (!regionID || state.loadingRegion) {
    if (!regionID) {
      // do nothing
      return null;
    } else {
      commit('setState', { loadingRegion: true });
      const subregionDropdown = await BaseService.get(`region/${regionID}/subregionDropdown`, { sort: 'entryValue' });
      const _subRegionID = subRegionID || (subregionDropdown?.data?.result || [])[0]?.entryKey || null;
      const [
        region,
        subRegions,
        regionalPersons,
        regionPreApprovedOfficeNames,
        regionCompanies,
        offices,
        franchiseAgreement,
        regionInfo,
        subRegionInfo,
        corporateNamesInRegion,
        listOfActiveMainOfficesInRegion,
        persons,
      ] = await promiseAll([
        BaseService.get(`region/${regionID}`, { subregionTagID: _subRegionID }),
        BaseService.get(`region/${regionID}/subregionDropdown`, { sort: 'entryValue' }),
        BaseService.get(`region/${regionID}/regionalPerson`, { only_active: '1' }),
        BaseService.get('v1/contractManagement/getAllPreApprovedNames', { regionID }),
        BaseService.get('companies', { 'filter[regionID][in]': regionID, sort: 'companyIsActive,name' }),
        BaseService.get('offices', {
          'filter[regionID][in]': regionID,
          'filter[officeIsActive][in]': '1,4',
          sort: 'name',
        }),
        BaseService.get(`region/${regionID}/franchiseAgreement`, { subregionTagID: _subRegionID }),
        BaseService.get('v1/region/getInfo', { regionID, subregionTagID: _subRegionID }),
        _subRegionID ? BaseService.get('v1/subRegion/getInfo', { regionID, subRegionID: _subRegionID }) : null,
        BaseService.get('v1/entity/getAllCorporateNamesInRegion', { regionID, subregionTagID: _subRegionID }),
        BaseService.get('v1/office/getListOfActiveMainOfficesInRegion', { regionID }),
        // BaseService.get('persons', { sort: '-personIsActive,lastName', 'filter[regionID][in]': regionID, }),
      ]);

      const regionalFranchiseAgreement =
        subRegionInfo?.data?.data?.franchiseAgreement || regionInfo?.data?.data?.regionalFranchiseAgreement;
      if (regionalFranchiseAgreement) {
        regionalFranchiseAgreement.franchiseFeeStructures = regionalFranchiseAgreement.franchiseFeeStructures.map(
          (segment) => {
            return exchangeFeeSegmentFees(segment, {
              fromCurrency: regionalFranchiseAgreement.paymentCurrencyISO,
              toCurrency: regionalFranchiseAgreement.localFranchiseCurrencyISO,
              rates: state.exchangeRates,
            });
          }
        );
        if (regionalFranchiseAgreement.officeTransferFee) {
          regionalFranchiseAgreement.officeTransferFeeLocalCurrency = exchangeCurrency({
            amount: regionalFranchiseAgreement.officeTransferFee,
            fromCurrency: regionalFranchiseAgreement.paymentCurrencyISO,
            toCurrency: regionalFranchiseAgreement.localFranchiseCurrencyISO,
            rates: state.exchangeRates,
          }).amount;
        }
        if (regionalFranchiseAgreement.personAnnualDues) {
          regionalFranchiseAgreement.personAnnualDuesLocalCurrency = exchangeCurrency({
            amount: regionalFranchiseAgreement.personAnnualDues,
            fromCurrency: regionalFranchiseAgreement.paymentCurrencyISO,
            toCurrency: regionalFranchiseAgreement.localFranchiseCurrencyISO,
            rates: state.exchangeRates,
          }).amount;
        }
      }

      const regionState = {
        uniqueKey: Math.random().toString(36).substring(2),
        region: region?.data?.result || null,
        subRegionID: _subRegionID,
        subRegionInfo,
        regionInfo,
        subRegions: subRegions?.data?.result || [],
        regionalPersons: regionalPersons?.data?.result || [],
        franchiseAgreement: franchiseAgreement?.data?.result || null,
        preApprovedOfficeNames: regionPreApprovedOfficeNames?.data?.data || [],
        companies: regionCompanies?.data?.result || [],
        offices: (offices?.data?.result || []).sort(
          (a, b) => (b.officeIsActive || b.status) - (a.officeIsActive || a.status)
        ),
        persons: persons?.data?.result || [],
        regionalFranchiseAgreement,
        corporateNamesInRegion: (corporateNamesInRegion?.data?.data || []).filter((n) => n),
        listOfActiveMainOfficesInRegion: listOfActiveMainOfficesInRegion?.data?.data || [],
      };
      commit('setState', {
        loadingRegion: false,
        currentRegionID: regionID,
        region: regionState,
        paymentCurrency: {
          iso: regionalFranchiseAgreement.paymentCurrencyISO,
          name: regionalFranchiseAgreement.paymentCurrencyISO,
        },
      });
      return regionState;
    }
  } catch (e) {
    commit('setState', { loadingRegion: false });
    throw e;
  }
}

export async function officeDetails({ state, commit }, officeID) {
  try {
    let officeDetails = state.officeDetails[officeID];
    if (officeID && !state.loadingOfficeDetails && !officeDetails) {
      commit('setState', { loadingOfficeDetails: true });
      const [snaps, devScheduleSnapshotsRes, gciTrendByOffice, getNumberOfSalesAssociates, office] = await Promise.all([
        BaseService.get(`office/${officeID}/snapshots`, { sort: '-validFrom' }),
        BaseService.get(`office/${officeID}/developmentScheduleSnapshots`, { sort: '-valid_from' }),
        CubeService.getOfficeGciByYear(officeID, new Date().getFullYear() - 1),
        CubeService.getNumberOfSalesAssociates(officeID),
        BaseService.get('v1/office/getInfo', { officeID }),
      ]);

      const snap = (snaps?.data?.result || [])[0];
      let officeShareSnapshot = null;
      if (snap) {
        try {
          const officeShareSnap = await BaseService.get(`officeShareSnapshot/${snap.ID}`);
          officeShareSnapshot = officeShareSnap?.data?.result;
        } catch (e) {
          /**/
        }
      }

      const latestDevScheduleSnapshot = (devScheduleSnapshotsRes?.data?.result || [])[0];
      let devScheduleSnapshot = { officeDevelopmentSchedules: [] };
      if (latestDevScheduleSnapshot) {
        try {
          const devScheduleRes = await BaseService.get(
            `officeFranchiseAgreement/${latestDevScheduleSnapshot.ID}/developmentScheduleSnapshot`
          );
          devScheduleSnapshot = devScheduleRes?.data?.result;
        } catch (e) {
          /**/
        }
      }

	  const regionalFranchiseAgreement = state.region.regionalFranchiseAgreement;

      let previousYearGCILocalCurrency = ((gciTrendByOffice || [])[0] || {})['OfficePerformanceCube.gciForPeriod'];
      previousYearGCILocalCurrency = previousYearGCILocalCurrency ? Number(previousYearGCILocalCurrency) : null;

	  let previousYearGciEUR = ((gciTrendByOffice || [])[0] || {})['OfficePerformanceCube.gciForPeriodEur'];
      previousYearGciEUR = previousYearGciEUR ? Number(previousYearGciEUR) : null;

	  let previousYearGCI = previousYearGciEUR;
	  if (`${regionalFranchiseAgreement.paymentCurrencyISO}`.toUpperCase() !== 'EUR') {
		previousYearGCI = !previousYearGCI ? null : exchangeCurrency({
			amount: previousYearGciEUR,
			toCurrency: regionalFranchiseAgreement.paymentCurrencyISO,
			fromCurrency: 'EUR',
			rates: state.exchangeRates,
		}).amount;
	  }

      const numberOfSalesAssociates = Math.ceil(
        (getNumberOfSalesAssociates || []).reduce((acc, n) => {
          acc += Number(n['AgentCountCube.numberOfAgents'] || '0');
          return acc;
        }, 0) / (getNumberOfSalesAssociates || []).length
      );

      officeDetails = {
        ...state.officeDetails[officeID],
        transferEffectiveDate: snap?.validFrom || null,
        officeShares: officeShareSnapshot?.officeShares || [],
        devScheduleSnapshot,
        previousYearGCI,
        previousYearGCILocalCurrency,
        numberOfSalesAssociates: numberOfSalesAssociates ? Number(numberOfSalesAssociates) : null,
        office: office?.data?.data,
      };

      commit('setState', {
        loadingOfficeDetails: false,
        officeDetails: {
          ...state.officeDetails,
          [officeID]: officeDetails,
        },
      });
    }
    return officeDetails;
  } catch (e) {
    console.log(e);
    commit('setState', { loadingOfficeDetails: false });
  }
}

export async function regionCompanies({ state, commit }, regionID) {
  try {
    if (regionID && !state.loadingRegionCompanies) {
      commit('setState', { loadingRegionCompanies: true });
      const [regionCompanies] = await promiseAll([
        BaseService.get('companies', { 'filter[regionID][in]': regionID, sort: 'companyIsActive,name' }),
      ]);
      commit('setState', {
        loadingRegionCompanies: false,
        region: {
          ...state.region,
          companies: regionCompanies?.data?.result || [],
        },
      });
    }
  } catch (e) {
    commit('setState', { loadingRegionCompanies: false });
  }
}

export async function preApprovedOfficeNames({ state, commit }, regionID) {
  try {
    if (regionID && !state.loadingPreApprovedOfficeNames) {
      commit('setState', { loadingPreApprovedOfficeNames: true });
      const [preApprovedOfficeNames] = await promiseAll([
        BaseService.get('v1/contractManagement/getAllPreApprovedNames', { regionID }),
      ]);
      commit('setState', {
        loadingPreApprovedOfficeNames: false,
        region: {
          ...state.region,
          preApprovedOfficeNames: preApprovedOfficeNames?.data?.data || [],
        },
      });
    }
  } catch (e) {
    commit('setState', { loadingPreApprovedOfficeNames: false });
  }
}
