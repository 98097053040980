import { promiseAll } from '@/utils/promiseAll';
import { BaseService } from '../../../services/base.service';

export async function init(store, feeReliefID) {
	feeReliefID = feeReliefID || store.state.team?.ID;
	try {
		const res = await promiseAll([
			feeRelief(store, feeReliefID),
			changeHistory(store, {}, feeReliefID),
			BaseService.get('regions', { sort: 'name', }),
		]);
		store.commit('setState', {
			regions: res[2]?.data?.result || [],
		});
	} catch(e) { throw e; }
}

export async function feeRelief(store, feeReliefID, params = {}) {
	try {
		if (feeReliefID) {
			const [
				feeRelief,
				existingEntries,
			] = await promiseAll([
				BaseService.get(`regionAnnualDuesFeeRelief/${feeReliefID}`),
				BaseService.get(`regionAnnualDuesFeeRelief/${feeReliefID}/existingEntries`),
			]);
	
			store.commit('setState', {
				feeRelief: feeRelief?.data?.result,
				entryExists: existingEntries?.data?.result?.entryExists || false,
			});
		}
	} catch(e) { throw e; }
}

export async function changeHistory({ commit, state }, params = {}, feeReliefID?: any) {	
	try {
		feeReliefID = state.team?.ID || feeReliefID;
		if (feeReliefID) {
			const { queryParams, ..._params } = { ...params, } as any;
			const currentPage = _params.currentPage || 1;
			const rowsPerPage = _params.rowsPerPage || 35;
			const qParams = { ...state.history.queryParams, ...queryParams, };

			commit('setState', { history: { ...state.history, loading: true, queryParams: qParams, }, });

			const res = await BaseService.get(`regionAnnualDuesFeeRelief/${feeReliefID}/history`, {
				...qParams,
				//MAX-1129
				//limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
			});
			const totalRows = res?.data?.info?.numRows || 0;
			commit('setState', {
				history: {
					..._params,
					currentPage,
					rowsPerPage,
					loading: false,
					totalRows,
					totalPages: Math.ceil(totalRows / rowsPerPage),
					list: res?.data?.result || [],
					queryParams: qParams,
				},
			});
		}
	} catch(e) { 
		commit('setState', { history: { ...state.history, loading: false, }, });
		throw e; 
	}
}
