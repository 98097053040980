import { ReportingService } from '../../services/modules/monthlyReporting.service';

const regionsReport = {
  list: [],
  totalRows: 0,
  totalPages: 0,
  currentPage: 1,
  rowsPerPage: 46,
};

export default {
  namespaced: true,
  state: {
    regionsReport: regionsReport,
    newestCurrencyExchangeRates: [],
    changeHistory: [],
    reportingRegionMonthsList: [],
    updateMonthlyReportingPerformanceTables: [],
    reportingListingTransactionsPayload: [],
    importStats: [
      {
        importTotal: 0,
        importFileName: '',
        cellsUpdated: 0,
        uniquePersonIDsNotFound: 0,
        errors: 0,
      },
    ],
    displayImportStats: false,
    exportFileName: [],
    exportSheetName: [],
  },
  mutations: {
    setUpdateMonthlyReportingPerformanceTables(state, data) {
      state.updateMonthlyReportingPerformanceTables = data;
    },
    setRegionsReport(state, data) {
      state.regionsReport = { ...state.regionsReport, ...data };
    },

    resetRegionsReport(state) {
      state.regionsReport = regionsReport;
    },

    setNewestCurrencyExchangeRates(state, data) {
      state.newestCurrencyExchangeRates = data;
    },
    setChangeHistory(state, data) {
      state.changeHistory = data;
    },
    setReportingRegionMonthsList(state, data) {
      state.reportingRegionMonthsList = data;
    },
    setReportingListingTransactionsPayload(state, data) {
      if (state.reportingListingTransactionsPayload.regionID == state.reportingListingTransactionsPayload.reportingPeriod) {
        state.reportingListingTransactionsPayload.regionID = data.regionID;
        state.reportingListingTransactionsPayload.reportingPeriod = data.reportingPeriod;
      } else {
        state.reportingListingTransactionsPayload.reportingListingTransactions = data;
      }
    },
    setImportStats(state, data) {
      state.importStats.importTotal = data.importTotal;
      state.importStats.importFileName = data.importFileName;
      state.importStats.cellsUpdated = data.cellsUpdated;
      state.importStats.uniquePersonIDsNotFound = data.uniquePersonIDsNotFound;
      state.importStats.errors = data?.errors;
    },
    setDisplayImportStats(state, data){
      state.displayImportStats = data;
    },
    setExportFileName(state, data){
      state.exportFileName = data.exportFileName;
    },
    setExportSheetName(state, data){
      state.exportSheetName = data.exportSheetName;
    }
  },

  actions: {
    async getUpdateMonthlyReportingPerformanceTables({ commit }, { params }) {
      try {
        const res = await ReportingService.getUpdateMonthlyReportingPerformanceTables(params);
        const data = res;
        commit('setUpdateMonthlyReportingPerformanceTables', data);
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getRegionsReport({ commit }, params) {
      try {
        const rowsPerPage = params.rowsPerPage || regionsReport.rowsPerPage;
        let currentPage = params.currentPage;
        if (!regionsReport.totalPages && currentPage < 1) currentPage = 1;

        const res = await ReportingService.getRegionsReport({
          ...params,
          currentPage,
          rowsPerPage,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setRegionsReport', {
          list: res.data.result,
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getNewestCurrencyExchangeRates({ commit }, params) {
      try {
        const res = await ReportingService.newestCurrencyExchangeRates(params);
        const data = res?.data?.result || [];
        commit('setNewestCurrencyExchangeRates', data);
      } catch (e) {
        throw e;
      }
    },

    async getCurrentMonthReportForOffice(_, { officeID, params }) {
      try {
        const res = await ReportingService.getCurrentMonthReportForOffice(officeID, params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getRegionOverviewByMonth(_, { regionID, params }) {
      try {
        const res = await ReportingService.getRegionOverviewByMonth(regionID, params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getReportingRegionOverview(_, { regionID, params }) {
      try {
        const res = await ReportingService.getReportingRegionOverview(regionID, params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async saveReport(_, { officeID, payload }) {
      try {
        const res = await ReportingService.saveReport(officeID, payload);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getChangeHistory({ commit }, { officeID, params }) {
      try {
        const res = await ReportingService.getChangeHistory(officeID, params);
        const data = res?.data?.result || [];
        commit('setChangeHistory', data);
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getRegionalYearsInformationForReporting(_, regionID) {
      try {
        const res = await ReportingService.getRegionalYearsInformationForReporting(regionID);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
    async getReportingRegionMonthsList({ commit }, { regionID, params }) {
      try {
        const res = await ReportingService.getReportingRegionMonthsList(regionID, params);
        const data = res?.data?.result || [];
        commit('setReportingRegionMonthsList', data);
        return data;
      } catch (e) {
        throw e;
      }
    },

    async closeReportingPeriod(_, { regionID, params }) {
      try {
        const res = await ReportingService.closeReportingPeriod(regionID, params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async uploadFiles(_, { id, date, formData }) {
      try {
        const res = await ReportingService.uploadFiles(id, date, formData);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },

    async getAllOfficeReporting(_, { regionID, params }) {
      try {
        const res = await ReportingService.getAllOfficeReporting(regionID, params);
        const data = res?.data?.result || [];
        return data;
      } catch (e) {
        throw e;
      }
    },
  },
};
