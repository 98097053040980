import { BaseService } from '../base.service';

export class AnnualDuesPaymentEditorService extends BaseService {
  static get entity() { return 'payment'; }

  //from datahub/routes/annual_dues_finance.ts
  static async payment(paymentID, params) { return await this.get(`annualDuesFinance/${paymentID}`, params); }
  
  //from datahub/routes/region.ts
  static async insertPayment(payment, regionID) { 
    console.log('hit insertPayment in service:');
    console.log(payment);
    console.log(regionID);

    
    //region/:ID/annualDuesFinance, 
    return await this.post(`region/${regionID}/annualDuesFinance`, payment); 
  }

  static async updatePaymentComment(paymentID, payload) { return await this.put(`annualDuesFinance/${paymentID}`, payload); }

  //from datahub/routes/region.ts
  static async getPaymentList(regionID) { return await this.get(`region/${regionID}/annualDuesFinances`);}

}
