export const personSuffixes = [
    {
        "label": "Jr",
        value: 148,
    },
    {
        "label": "Sr",
        value: 150,
    },
    {
        "label": "II",
        value: 152,
    },
    {
        "label": "IV",
        value: 156,
    },
    {
        "label": "I",
        value: 157,
    },
    {
        "label": "III",
        value: 158,
    },
    {
        "label": "V",
        value: 164,
    },
    {
        "label": "VI",
        value: 173,
    },
];

export const emailTags = [
    {
        "label": "Billing",
        "value": 2
    },
    {
        "label": "Business Development",
        "value": 5
    },
    {
        "label": "Franchise Sales",
        "value": 6
    },
    {
        "label": "IT",
        "value": 3
    },
    {
        "label": "Legal",
        "value": 1
    },
    {
        "label": "Marketing",
        "value": 4
    },
    {
        "label": "Quality",
        "value": 7
    },
    {
        "label": "Training",
        "value": 8
    },
];

export const specialisationsSelect = [
    {
        "label": "(COMM) Commercial",
        value: 1,
    },
    {
        "label": "(COMRES) Commercial with Residential",
        value: 3,
    },
    {
        "label": "(RES) Residential",
        value: 2,
    },
    {
        "label": "(RESCOM) Residential with Commercial",
        value: 4,
    },
];

export const designationsDropdown = [
    { label: 'Collection', value: '1', },
    { label: 'Commercial', value: '2', },
];
