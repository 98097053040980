import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const monthly_agent_production: any = {
  cubeParameters: {
    filterParameter: 'TitleLevelPerformance.date',
    timeParameter: 'TitleLevelPerformance.date',
    singleDate: true,
  },

  columns: [
    TC.region_name_recurring,
    TC.officeMasterId,
    TC.officeUniqueIDMonthlyAgents,
    TC.office_name_recurring,
    TC.masterPersonIDRecurring,
    TC.unique_person_id_monthly_agents,
    TC.associateName,
    TC.statisticDate,
    TC.statisticType,
    TC.statisticScope,
    TC.commissionAmount,
    TC.listing,
    TC.listingAmount,
    TC.sale,
    TC.saleAmount,
    TC.teamIdMonthly,
    TC.teamNameMonthly,
  ],
  nonCube: true,
  settingsPanel: panel,
};
