import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class NotesService extends BaseService {
  static async getNotes(entity, params = [], query = {}) {
    const url = `${[entity, ...params].filter(s => s).join('/')}/note?${queryString.stringify(query)}`;
    try {
      const response = await this.request().get(url);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
