import { agents } from './agents/Report_template';
import { agent_performance_periodical_per_office_optimized } from './agentPerformancePeriodicalPerOffice/Report_template';
import { agent_performance_aggregated_per_office_optimized } from './agentPerformanceAggregatedPerOffice/Report_template';
import { office_performance_aggregated_optimized } from './officePerformanceAggregated/Report_template';
import { agent_performance_aggregated_per_team } from './agentPerformanceAggregatedPerTeam/Report_template';
import { agent_performance_aggregated_combined_optimized } from './agentPerformanceAggregatedCombined/Report_template';
import { offices } from './offices/Report_template';
import { regional_person_office_count } from './regionalAssociateAndOfficeCount/Report_template';
import { regional_office_counts_aggregated } from './regionalOfficeCountsAggregated/Report_template';
import { regional_office_counts_periodical } from './regionalOfficeCountsPeriodical/Report_template';
import { regional_performance_aggregated_optimized } from './regionalPerformanceAggregated/Report_template';
import { daily_new_members } from './dailyNewMembers/Report_template';
import { office_status_changes } from './officeStatusChanges/Report_template';
import { person_other_changes } from './personOtherChanges/Report_template';
import { team_performance_aggregated } from './teamPerformanceAggregated/Report_template';
import { regional_performance_periodical_optimized } from './regionalPerformancePeriodical/Report_template';
import { agent_performance_periodical_combined_optimized } from './agentPerformancePeriodicalCombined/Report_template';
import { monthly_agent_production } from './monthlyAgentProduction/Report_template';
import { office_performance_periodical_optimized } from './officePerformancePeriodical/Report_template';

export {
  agents,
  offices,
  agent_performance_periodical_per_office_optimized,
  agent_performance_aggregated_per_office_optimized,
  agent_performance_aggregated_per_team,
  office_performance_aggregated_optimized,
  agent_performance_aggregated_combined_optimized,
  regional_person_office_count,
  regional_office_counts_aggregated,
  regional_performance_aggregated_optimized,
  daily_new_members,
  office_status_changes,
  person_other_changes,
  team_performance_aggregated,
  regional_performance_periodical_optimized,
  agent_performance_periodical_combined_optimized,
  monthly_agent_production,
  office_performance_periodical_optimized,
  regional_office_counts_periodical
};
