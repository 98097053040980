import { promiseAll } from '@/utils/promiseAll';
import { getDefaultState } from './constants';
import { init, changeHistory, exchangeRates, } from './init';
import { ExchangeRateEditorService } from '../../../services/modules/exchangeRatesEditor.service';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
		setRowData(state, data) {
			state.rowData = data;
		  },
	},

	actions: {
		init(store, countryISO) { return init(store, countryISO); },

		exchangeRates(store, _params) { 
			const { countryISO, ...params } = { ..._params } as any;
			return exchangeRates(store, countryISO || store.state.exchangeRates?.ID, params); 
		},

		history(store, params) { return changeHistory(store, params); },

		async currencyExchangeRateRxdYear(store, year) {
			const res = await ExchangeRateEditorService.get(`currencyExchangeRateRxdYear/${year}`);
			store.commit('setState', { currencyExchangeRateRxdYear: {
				list: res.data.result
			} 
			});
		},

		async selectCurrencyExchangeRatesRemaxDollarPerYear(store, year) {
			const res = await ExchangeRateEditorService.get(`countries/exchangeRatesList?year=${year}`);
			store.commit('setState', { selectCurrencyExchangeRatesRemaxDollarPerYear: {
				list: res.data.result
			}
			});
		},

		async getAddedCountryCurrencyISO(store, params) {
			const res = await ExchangeRateEditorService.get(`/country/${params.countryISO}/reportingCurrencyISO?year=${params.year}`);
			store.commit('setState', { addedCountryCurrencyISO: res.data.result});
			return res;
		},

		async save(store, { ID, params }) {
			try {
				let saveExchangeRateResponse = null;
				if (params) {
					saveExchangeRateResponse = await ExchangeRateEditorService.put(`currencyExchangeRateRxdCountry/${ID}`, params)
				} 
				store.commit('setState', { savingExchangeRate: true, });

				if (params) await init(store, params.ID);

				store.commit('setState', { savingExchangeRate: false, });
				store.commit('setState', { error: null });

				return { saveExchangeRateResponse };
			} catch(e) { 
				console.log(e);
				store.commit('setState', { savingExchangeRate: false, }); 
				store.commit('setState', { error: e.response.data.errorData[0], });
				return e; 
			}
		},

		async bulkInsertCurrencyExchangeRateRemaxDollar(store, params) {
			try {
				const res = await ExchangeRateEditorService.post(`currencyExchangeRateRxdYears`, params);
				return res;
			} catch(e) {
				console.log(e);
				return e;
			}
		},
	},
};
  