export const getDefaultState = () => ({
	exclusionFilter: [],
	officesFilter: [],
	applicableFeeReliefFilter: null,

	initParams: {},

	region: null,

	currencyPayment: null,
	
	annualDuesReportGetOpenInfo: null,

	annualDuesComments: [],

	annualDuesApplicableFeeReliefs: [],

	offices: [],

	monthlyAnnualDuesBalance: null,

	canBeReopend: false,

	annualDuesReportStatus: 0,

	isAllowedToEdit: 0,

	reopenCurrentlyProcessing: false,

	annualDuesPeriodAccessible: null,

	showCommentsMonthlyAnnualDues: true,

	isAnnualDuesRequestUpdate: true,

	monthlyAnnualDuesBulkOp: {
		type: null,
		comment: '',
		selected: {},
	},

	monthlyAnnualDues: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 50,
		queryParams: {
			sort: "officeName,personName"
		},
	},

	changeHistory: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: "-changeDate"
		},
	},
});
