import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class ExchangeRateEditorService extends BaseService {
	static get entity() { return 'currencyExchangeRateRxdCountry'; }

	static exchangeRates(countryISO, params) { return this.get(`currencyExchangeRateRxdCountry/${countryISO}`, params); }

	static currencyExchangeRateRxdYear(countryISO, year) { return this.get(`currencyExchangeRateRxdCountry/${year}`); }

	static history(countryISO, params) { return this.get(`currencyExchangeRateRxdCountry/${countryISO}/history`, params); }

	static selectCurrencyExchangeRatesRemaxDollarPerYear(year, params) {
		return this.get(`countries/exchangeRatesList?year=${year}`, params);
	  }
	  
}
