import { getDefaultState } from './constants';
import { init, teams, } from './init';

export default {
	namespaced: true,

	state: getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },

		resetTeamsFilters(state) {
			const list = state.teams.list;
			state.teams = {
				...getDefaultState().teams,
				list,
			};
		},
	},

	actions: {
		init(store, params) { return init(store, params); },

		teams(store, params) { return teams(store, params); },

		resetTeamsFilters(store) { 
			store.commit('resetTeamsFilters');
			return teams(store, getDefaultState().teams); 
		},
	},
};
  