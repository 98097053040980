import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const office_performance_aggregated_optimized: any = {
  cubeParameters: {
    measures: [
      'OfficePerformanceCube.agntreactivationcntForPeriod',
      'OfficePerformanceCube.agntnewcntForPeriod',
      'OfficePerformanceCube.agnttrfincntForPeriod',
      'OfficePerformanceCube.agnttrfoutcntForPeriod',
      'OfficePerformanceCube.agntterminationcntForPeriod',
      'OfficePerformanceCube.gciForPeriod',
      'OfficePerformanceCube.transactionForPeriod',
      'OfficePerformanceCube.volumeForPeriod',
      'OfficePerformanceCube.gciForPeriodRxd',
      'OfficePerformanceCube.gciForPeriodEur',
      'OfficePerformanceCube.volumeForPeriodRxd',
      'OfficePerformanceCube.volumeForPeriodEur',
    ],
    dimensions: [
      'OfficePerformanceCube.officeid',
      'OfficePerformanceCube.regionid',
      'RegionCube.reportingcurrency',
      'OfficeCube.officename',
      'OfficeCube.regionname',
      'OfficeCube.subregionname',
      'OfficeCube.uniqueofficeid',
      'OfficeCube.datahubofficeid',
      'OfficeCube.datesigned',
      'OfficeCube.statusoffice',
    ],
    filters: [
      {
        member: 'OfficePerformanceCube.regionid',
        operator: 'notEquals',
        values: ['0'],
      },
    ],
    mainCubeParameter: 'OfficePerformanceCube',
  },

  columns: [
    TC.officeRegionName,
    TC.subRegion,
    TC.officeName,
    TC.officeUniqueID,
    // TC.officeConstituentID,
    TC.licenseDate,
    TC.statusOfficeAggregated,
    TC.newAgents,
    TC.reactivatedAgents,
    TC.transferedAgentsIn,
    TC.transferedAgentsOut,
    TC.terminationsCount,
    TC.gciPeriodStandard,
    TC.transactionsPeriodStandard,
    TC.volumePeriodStandard,
  ],
  settingsPanel: panel,
};
