import * as utils from './utils';

export default {
	namespaced: true,

	state: utils.getDefaultState(),

	mutations: {
		setDefaultState(state) {
            const _defaultState = utils.getDefaultState();
            Object.keys(_defaultState).forEach((key) => { state[key] = _defaultState[key]; });
        },

        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },
	},

	actions: {
		async init(store, opts = {}) {
			return utils.init(store, opts);
		},

		async reportData(store, query) {
			return utils.reportData(store, query);
		},
	},
};
  