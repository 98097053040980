export const getDefaultState = () => ({
	savingTeam: false, 
	
	team: null,

	teamLeader: null,

	office: null,
	
	persons: {
		list: [],
		loading: false,
		queryParams: {
			sort: 'name',
			only_active: '1',
		},
	},

	history: {
		list: [],
		loading: false,
		totalRows: 0,
		currentPage: 1,
		totalPages: 1,
		rowsPerPage: 35,
		queryParams: {
			sort: '-changeDate',
		},
	},
});
