import * as mockData from '../mock-data/common';
import { CommonService } from '@/services/modules/common.service';
export default {
  namespaced: true,
  state: {
    phoneNumbersCategories: [],
    otherContactInformationEntriesCategories: [],
    emailsCategories: [],
    regionsSelect: [],
    specialisationsSelect: [],
    loadingPhoneNumbersCategories: false,
    loadingOtherContactInformationEntriesCategories: false,
    loadingEmailsCategories: false,
    session: {
      permissions: {},
      personID: null,
      relationID: null,
      rightGroup: null,
      username: null,
    },
    exchangeRate: 1,
    exchangeRateArray: [],
    BaseToEuroRate: 1,
  },
  mutations: {
    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },

    setEmailsCategories(state, data) {
      state.emailsCategories = data;
    },
    setPhoneNumbersCategories(state, data) {
      state.phoneNumbersCategories = data;
    },
    setOtherContactInformationEntriesCategories(state, data) {
      state.otherContactInformationEntriesCategories = data;
    },
    setLoadingEmailsCategories(state, loading) {
      state.loadingEmailsCategories = loading;
    },
    setLoadingPhoneNumbersCategories(state, loading) {
      state.loadingPhoneNumbersCategories = loading;
    },
    setLoadingOtherContactInformationEntriesCategories(state, loading) {
      state.loadingOtherContactInformationEntriesCategories = loading;
    },
    setRegionsSelect(state, data) {
      state.regionsSelect = data;
    },
    setSpecialisationsSelect(state, data) {
      state.specialisationsSelect = data;
    },
    setCurrency(state, data) {
      state.exchangeRate = data;
    },
    BaseToEuroRate(state, data) {
      state.BaseToEuroRate = data;
    },
    setExchangeRateArray(state, data) {
      state.exchangeRateArray = data;
    },
  },
  actions: {
    getEmailsCategories({ commit }, params: { office_id: string | number }) {
      commit('setLoadingEmailsCategories', true);
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = mockData.emailsCategories;
          commit('setEmailsCategories', data);
          commit('setLoadingEmailsCategories', false);
          resolve(data);
        }, 1500)
      );
    },

    getPhoneNumbersCategories({ commit }, params: { office_id: string | number }) {
      commit('setLoadingPhoneNumbersCategories', true);
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = mockData.phoneNumbersCategories;
          commit('setPhoneNumbersCategories', data);
          commit('setLoadingPhoneNumbersCategories', false);
          resolve(data);
        }, 1500)
      );
    },

    getOtherContactInformationEntriesCategories({ commit }, params: { office_id: string | number }) {
      commit('setLoadingOtherContactInformationEntriesCategories', true);
      return new Promise((resolve) =>
        setTimeout(() => {
          const data = mockData.otherContactInformationEntriesCategories;
          commit('setOtherContactInformationEntriesCategories', data);
          commit('setLoadingOtherContactInformationEntriesCategories', false);
          resolve(data);
        }, 1500)
      );
    },

    async getRegionsSelect({ commit }) {
      const data = mockData.regionsSelect;
      commit('setRegionsSelect', data);
      return data;
    },

    async getSpecialisationsSelect({ commit }) {
      const data = mockData.specialisationsSelect;
      commit('setSpecialisationsSelect', data);
      return data;
    },

    async getExchangeRate({ commit }) {
      const res = await CommonService.getExchangeRate();
      return res;
    },
  },
};
