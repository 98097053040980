import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class ReportsService extends BaseService {
  static async getReportList(query = {}) {
    const url = `reports?${queryString.stringify(query)}`;
    try {
      const response = await this.request().get(url);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getReportListTemplates(id) {
    const url = `reportTemplateSection/${id}`;
    try {
      const response = await this.request().get(url);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getAgentReportDataForAllRegions(pageNumber, pageSize, regionId, status, search) {
    const url = `/v1/reports/adhoc/agents?regionId=${regionId}${status ? '&status=' + status : '' }&pageNumber=${pageNumber}&rows=${pageSize}${search ? '&search=' + search : '' }`;
    try {
      const response = await this.request().get(url);
      return new ResponseWrapper(response, response.data.rows);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getRecurring(id, template) {
    let url = '';
    if (template === 'personOtherChanges') {
      url = `v1/reports/recurring/${template}?reportId=${id}&timeZoneOffset=1`;
    } else {
      url = `v1/reports/recurring/${template}?reportId=${id}`;
    }
    try {
      const response = await this.request().get(url);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  static async getMonthlyAgentProduction(regiondId, endDate) {
    let url = '';
    url = `/v1/reports/adhoc/monthlyAgentProduction?endDate=${endDate}&regionId=${regiondId}`;
    try {
      const response = await this.request().get(url);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async selectReport(id) {
    const url = `report/${id}`;
    try {
      const response = await this.request().get(url);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async selectReportTemplate(id) {
    const url = `reportTemplate/${id}`;
    try {
      const response = await this.request().get(url);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteReport(id) {
    const url = `report/${id}`;
    try {
      const response = await this.request().delete(url);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async saveReport(saveModel) {
    const url = `report`;
    try {
      const response = await this.request().post(url, saveModel);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateReport(id, payload) {
    const url = `report/${id}`;
    try {
      const response = await this.request().put(url, payload);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
