import TC from '../template_utilities/templateColumns';
import panel from './settingspanel.vue';

export const daily_new_members: any = {
  cubeParameters: {
    recurring: true,
    filterParameter: 'TitleLevelPerformance.date',
    timeParameter: 'TitleLevelPerformance.date',
  },

  columns: [
    TC.firstNameDailyNewMembers,
    TC.lastNameDailyNewMembers,
    TC.gender,
    TC.birthdate,
    TC.office_name_recurring,
    TC.officeConstituentIDRecurring,
    TC.internalOfficeIDRecurring,
    TC.officeUniqueIDRecurring,
    TC.PrimaryTitleRecurring,
    TC.commencementDateRecurring,
    TC.teamStatusRecurring,
    TC.mobile_phone,
    TC.phoneRecurring,
    TC.email_address,
    TC.specializationRecurring,
    TC.languages,
    TC.cityRecurring,
    TC.teamLeaderInternalMemberIDRecurring,
    TC.teamLeaderName,
    TC.team_name,
    TC.teamID,
    TC.dailyNewMembersInternal,
    TC.region_name_recurring,
    TC.unique_person_id_recurring,
    TC.statusRecurring,
    TC.addRecurring,
    TC.primary_valid_fromRecurring,
    TC.statusFirstOfficeRecurring,
    TC.actual_opening_date,
    TC.is_satellite_office,
    TC.entered_by,
    TC.entered_on,
  ],
  settingsPanel: panel,
};
