import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export default class CountryService extends BaseService {
  static get entity() {
    return 'country';
  }
  static async getCountryByKey(key) {
    try {
      const response = await this.get(`region/${key}/countryISO`);
      return new ResponseWrapper(response);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      //return console.log(error, message);
    }
  }

  static async getAllCountries(params) {
    try {
      const response = await this.request().get(
        `countries?filter%5BremaxEuropeCountry%5D%5Beq%5D=${params.isEurope}&sort=${params.sortBy}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getCountryStates(country_id) {
    try {
      const response = await this.request().get(`/country/${country_id}/states`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getPhoneCodesList() {
    try {
      const response = await this.request().get(`/countries/phonecodes`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getLanguages() {
    try {
      const response = await this.request().get(`/languages`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
