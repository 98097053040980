import * as mockData from '@/store/mock-data';
import * as types from '@/types';

export default {
    namespaced: true,

    state: {
        loadingData: false,
        data: [],
        filters: [],
        loadingFilters: false,
        currentUserPermission: null,
        loadingCurrentUserPermission: false,
    },

    actions: {
        async getCurrentUserPermission({ commit }) {
            commit('setCurrentUserPermissionLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setCurrentUserPermissionData', mockData.currentUserPermission);
                commit('setCurrentUserPermissionLoading', false);
                resolve(mockData.currentUserPermission);
                }, 1500);
            });
            return res;
        },

        async getUserPermissions({ commit }) {
            commit('setUserPermissionsLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setUserPermissionsData', mockData.userPermissions);
                commit('setUserPermissionsLoading', false);
                resolve(mockData.userPermissions);
                }, 1500);
            });
            return res;
        },

        async getUserPermissionsFilters({ commit }) {
            commit('setUserPermissionsFiltersLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setUserPermissionsFiltersData', mockData.userPermissionsFilters);
                commit('setUserPermissionsFiltersLoading', false);
                resolve(mockData.userPermissionsFilters);
            }, 1500);
            });
            return res;
        },
    },

    mutations: {
        setCurrentUserPermissionLoading(state, loadingState) {
            state.loadingCurrentUserPermission = loadingState;
        },
        
        setCurrentUserPermissionData(state, data: types.CurrentUserPermission) {
            state.currentUserPermission = data;
        },

        setUserPermissionsLoading(state, loadingState) {
            state.loadingData = loadingState;
        },

        setUserPermissionsData(state, data: types.UserPermission[] = []) {
            state.data = data;
        },

        setUserPermissionsFiltersLoading(state, loadingState) {
            state.loadingFilters = loadingState;
        },

        setUserPermissionsFiltersData(state, data: types.TableFilter[] = []) {
            state.filters = data;
        },
    },
};
