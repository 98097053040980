import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/styles/index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSearch,
  faCircleExclamation,
  faCircleInfo,
  faWarning,
  faCircleCheck,
  faCircleXmark,
  faCubes,
  faList,
  faChartLine,
  faMoneyBillAlt,
  faSort,
  faSortUp,
  faSortDown,
  faCoins,
  faShare,
  faArrowsUpDown,
  faSortAsc,
  faSortNumericUp,
  faSortNumericDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import FormComponent from '@/components/FormComponent.vue';
import textValue from '@/components/FormComponent.vue';
import { createThemePlugin } from '@formkit/themes';
import '@formkit/themes/genesis';
import { plugin, defaultConfig } from '@formkit/vue';
import { addAsteriskPlugin, editDatePicker } from './addAsteriskPlugin';
import JsonCSV from 'vue-json-csv';
import { createIntl } from 'vue-intl';
import { dragscrollNext } from 'vue-dragscroll';
import VueExcelXlsx from 'vue-excel-xlsx';
import VueApexCharts from 'vue3-apexcharts';
library.add(
  faSearch,
  faCircleExclamation,
  faCircleInfo,
  faWarning,
  faCircleCheck,
  faCircleXmark,
  faCubes,
  faSort,
  faSortUp,
  faSortDown,
  faList,
  faChartLine,
  faMoneyBillAlt,
  faCoins,
  faShare,
  faArrowsUpDown,
  faSortAsc,
  faSortNumericUp,
  faSortNumericDown
);

//License Key Managed by REMAX
import { LicenseManager } from  'ag-grid-enterprise'
LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_API_KEY)

// prettier-ignore
const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('text-value', textValue)
  .component('remax-form', FormComponent)
  .component('downloadCsv', JsonCSV)
  .use(store)
  .use(PerfectScrollbar)
  .use(router)
  .use(VueExcelXlsx)
  .use(
    plugin,
    defaultConfig({
      plugins: [addAsteriskPlugin, editDatePicker],
    })
  )
  .use(
    createIntl({
      locale: 'en',
      defaultLocale: 'en',
      messages: {
        foo: 'bar',
      },
    })
  )
  .use(VueApexCharts)

app.directive('dragscroll', dragscrollNext);
app.mount('#app');
