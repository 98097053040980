import * as mockData from '@/store/mock-data';
import * as types from '@/types';

export default {
    namespaced: true,

    state: {
        peopleLoading: false,
        people: [],
        filters: [],
        loadingFilters: false,
    },

    actions: {
        async getPeople({ commit }) {
            commit('setPeopleLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setPeopleData', mockData.people);
                commit('setPeopleLoading', false);
                resolve(mockData.people);
                }, 1500);
            });
            return res;
        },

        async getPeopleFilters({ commit }) {
            commit('setPeopleFiltersLoading', true);
            // make api call...
            const res = await new Promise(resolve => {
            setTimeout(() => {
                commit('setPeopleFiltersData', mockData.peopleFilters);
                commit('setPeopleFiltersLoading', false);
                resolve(mockData.peopleFilters);
            }, 1500);
            });
            return res;
        },
    },

    mutations: {
        setPeopleLoading(state, loadingState) {
            state.peopleLoading = loadingState;
        },

        setPeopleData(state, data: types.Person[] = []) {
            state.people = data;
        },

        setPeopleFiltersLoading(state, loadingState) {
            state.loadingFilters = loadingState;
        },

        setPeopleFiltersData(state, data: types.TableFilter[] = []) {
            state.filters = data;
        },
    },
};
