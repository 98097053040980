import * as reportTemplateList from '../templates';
import store from '@/store';

export async function initializeReportTemplateForCreateNew(TemplateId, preSelectedRegionID = null) {
  try {
    let res;
    let responseObject: any = {};
    res = await store.dispatch('reports/selectReportTemplate', TemplateId);
    const templateResult = res.data.result;

    responseObject.settingsPanel = reportTemplateList[res.data.result.templateKey].settingsPanel;
    responseObject.columnDefinitions = reportTemplateList[templateResult.templateKey].columns;
    if (isPeriodicalReport(templateResult.ID)) {
      const yearPattern = /\b\d{4}\b/;
      let nestedColumns = reportTemplateList[templateResult.templateKey].nestedColumns.map((col) => col.name);
      let outerColumns = responseObject.columnDefinitions.map((col) => col.name).filter((item) => !yearPattern.test(item));
      responseObject.selectedColumns = [...nestedColumns, ...outerColumns];
    } else {
      responseObject.selectedColumns = responseObject.columnDefinitions.map((col) => {
        return col.name;
      });
    }
    responseObject.columnOptions = [...reportTemplateList[templateResult.templateKey].columns];

    responseObject.templateData = {
      regionID: preSelectedRegionID ? preSelectedRegionID : -2,
      title: '',
      templateTitle: templateResult.title,
      isPublic: 0,
      status: 0,
      templateKey: templateResult.templateKey,
      templateID: templateResult.ID,
      currency: null,
      singleDate: reportTemplateList[res.data.result.templateKey].cubeParameters.singleDate ? true : false,
      periodFlag: 1,
    };

    return responseObject;
  } catch (e) {
    /**/
  }
}

function isPeriodicalReport(reportTemplateID) {
  if (
    reportTemplateID === 16 ||
    reportTemplateID === 20 ||
    reportTemplateID === 11 ||
    reportTemplateID === 18 ||
    reportTemplateID === 24 
  ) {
    return true;
  } else {
    return false;
  }
}
