import { BaseService } from '@/services/base.service';
import { displayBigNumber } from '@/utils/displayBigNumber';

const getPersonalCurrency = () => JSON.parse(localStorage.userInfo || '{}')?.personalCurrency;

export const getDefaultState = () => ({
  personalCurrency: getPersonalCurrency(),
  usePersonalCurrency: false,
  rates: [],
  ratesInitialised: false,
  loadingRates: false,
});

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },

    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },
  },

  actions: {
    async rates({ commit, state }, opts: { preferCache?: boolean } = {}) {
      const shouldFetch = opts.preferCache ? !state.ratesInitialised : true;
      if (shouldFetch && !state.loadingRates) {
        try {
          commit('setState', { loadingRates: true });
          const res = await BaseService.get('newestCurrencyExchangeRates');
          // const res2 = await BaseService.get('v1/currencyConverter/getAllCurrencies');
          commit('setState', {
            rates: (res?.data?.result || []).map((c) => ({
              ...c,
              // ...(res2?.data?.data || []).filter(c2 => c2.currencyISO === c.currencyISO)[0]
            })),
            // rates: res?.data?.result || [],
            loadingRates: false,
            ratesInitialised: true,
          });
        } catch (e) {
          commit('setState', { loadingRates: false });
        }
      }
    },

    async formatCurrency({ state }, { amount, currency }: { amount: number; currency: string }) {
      amount = amount ? Number(amount) : null;

      const rates = state.rates || [];

      const fromCurrency = currency;
      const toCurrency = (state.usePersonalCurrency ? getPersonalCurrency() : currency) || currency;
      const fromRate = rates.filter((r) => r.currencyISO === fromCurrency)[0];
      const toRate = rates.filter((r) => r.currencyISO === toCurrency)[0];

      let exchangedAmount = amount;

      if (!(amount && toRate && fromRate) || isNaN(amount) || fromCurrency === toCurrency) {
        // do nothing
      } else {
        exchangedAmount = (toRate.rate / fromRate.rate) * amount;
      }
      return {
        amount: exchangedAmount,
        formatedAmount: displayBigNumber(exchangedAmount),
        currencyISO: toRate?.currencyISO || fromCurrency,
        currencyName: toRate?.currencyName || null,
      };
    },

    async getCurrencyList(_) {
      try {
        const res = await BaseService.get('currency/dropdown');
        return res;
      } catch (e) {
        /**/
      }
    },
  },
};
