import queryString from 'query-string';
import { BaseService } from '../base.service';

export class OfficeGroupEditorService extends BaseService {
	static get entity() { return 'group'; }

	static group(groupID, params) { return this.get(`group/${groupID}`, params); }

	static async groups(regionID){
	// { return this.get(`groups/${officeID}`, params); }
	try {
		let response = await this.get(`groups?regionID=${regionID}`);
		return response;
	  } catch (error) {
		
		throw error;
	  }
	}

	static entities(groupID, params) { return this.get(`group/${groupID}/entities`, params); }

	static ungrouped(regionID) { return this.get(`group/ungroupedOffices?regionID=${regionID}`); }

	static history(groupID, params) {return this.get(`group/${groupID}/history`, params);}

	static office(officeID, params) { return this.get(`office/${officeID}`, params); }

	static async offices(officeID, params){
		// { return this.get(`groups/${officeID}`, params); }
		try {
			let response = await this.get(`${officeID}`, params);
			return response;
		  } catch (error) {
			
			throw error;
		  }
		}

	static async getEntities(url, params){
		try {
			if(url!='group//entities'){
				let response = await this.get(`${url}`, params);
				return response;
			}else{
				url = 'group/0/entities';
			let response = await this.get(`${url}`, params);
			return response;
			}
		  } catch (error) {
			
			throw error;
			
		  }
		}	

}
